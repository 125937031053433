.m-heading {
  $root: &;

  color: $COLOR-BLACK;

  &_text {
    margin: 0;
    @include u-fw-r;

    #{$root}-typeh1 & {
      font-size: 28px;
      line-height: 34px;

      @include pc {
        font-size: rem2(42);
        line-height: rem2(54);
      }
    }

    #{$root}-typeh2 & {
      font-size: 24px;
      line-height: 28px;

      @include pc {
        font-size: rem2(28);
        line-height: rem2(33);
      }
    }

    #{$root}-typeh3 & {
      font-size: 20px;
      line-height: rem2(25); // TODO: The line-height of sp-version is not specified.

      @include pc {
        font-size: rem2(21);
      }
    }

    #{$root}-typeh4 & {
      // TODO: The size of sp-version is not specified.
      font-size: rem2(18);
      line-height: rem2(21);
    }

    #{$root}-center & {
      text-align: center;
    }

    #{$root}-white & {
      color: $COLOR-WHITE;
    }

    #{$root}-fwmedium & {
      @include u-fw-m;
    }

    #{$root}-fwbold & {
      @include u-fw-b;
    }

    #{$root}-cerulean & {
      color: $COLOR-CERULEAN;
    }

    #{$root}-greyishbrown & {
      color: $COLOR-GREYISH-BROWN;
    }

    #{$root}-dimgray & {
      color: $COLOR-BROWNISH-GREY;
    }

    #{$root}-halfline & {
      position: relative;

      &::after {
        position: absolute;
        bottom: -#{rem(2.5)};
        left: 0;
        width: 50%;
        height: 2px;
        content: '';
        background-color: $COLOR-BLACK;

        @include sp {
          bottom: -#{rem(1.5)};
        }
      }
    }

    #{$root}-textshadow & {
      text-shadow: 0px 0px 2px $COLOR-BLACK;
    }

    span {
      line-height: 0;
    }
  }

  &_body {
    margin-bottom: rem(1);

    #{$root}-smallmarginbottom & {
      margin-bottom: rem(0.625);
    }

    #{$root}-mediummarginbottom & {
      margin-bottom: rem(1);
    }

    #{$root}-nonmargintop & {
      margin-top: 0;
    }

    #{$root}-nonmarginbottom & {
      margin-bottom: 0;
    }

    #{$root}-withstep & {
      display: flex;
      align-items: center;
    }

    #{$root}-center#{$root}-withstep & {
      justify-content: center;
    }

    #{$root}-subordered & {
      position: relative;
      padding-left: calc(#{rem(1.25)} + 4px);
      font-size: rem(1.25);

      &::before {
        position: absolute;
        top: 5px;
        left: 0;
        content: '';
        border: 6px solid $COLOR-CERULEAN;
        border-right-color: $COLOR-UTILITY-TRANSPARENT;
        border-bottom-color: $COLOR-UTILITY-TRANSPARENT;
      }
    }

    .a-tag+& {
      margin-top: rem(1);
    }

    #{$root}-halfline & {
      margin-bottom: rem(5);

      @include sp {
        margin-bottom: rem(3);
      }
    }

    #{$root}_category+& {
      #{$root}-typeh1 & {
        margin-top: rem(1);
      }
    }
  }

  &_stepnumber {
    position: relative;
    margin-right: rem(0.875);
    color: $COLOR-WHITE;
    text-align: center;
    background-color: $COLOR-CERULEAN;
    @include u-fw-b;

    &::before,
    &::after {
      position: absolute;
      content: '';
      border: 0 solid transparent;
      opacity: 0.8;
    }

    &::before {
      top: 0;
      left: 0;
      border-top: 0 solid $COLOR-LIGHT-BLUE;
      border-left: 0 solid $COLOR-LIGHT-BLUE;
    }

    &::after {
      right: 0;
      bottom: 0;
      border-right: 0 solid $COLOR-LIGHT-BLUE;
      border-bottom: 0 solid $COLOR-LIGHT-BLUE;
    }

    #{$root}-typeh1 & {
      width: 34px;
      height: 34px;
      font-size: 28px;
      line-height: 34px;

      @include pc {
        width: rem(3.8);
        height: rem(3.8);
        font-size: rem2(42);
        line-height: rem2(54);
      }

      &::before,
      &::after {
        border-width: calc(34px / 4);

        @include pc {
          border-width: rem(3.8 / 4);
        }
      }
    }

    #{$root}-typeh2 & {
      width: 28px;
      height: 28px;
      font-size: 24px;
      line-height: 28px;

      @include pc {
        width: rem(2.5);
        height: rem(2.5);
        font-size: rem2(28);
        line-height: rem2(33);
      }

      &::before,
      &::after {
        border-width: 6px;

        @include pc {
          border-width: rem(2.5 / 4);
        }
      }
    }

    #{$root}-typeh3 & {
      // TODO: Replace with proper-size after defined
      width: rem(2);
      height: rem(2);
      font-size: 20px;
      line-height: rem2(25);

      @include pc {
        font-size: rem2(21);
      }

      &::before,
      &::after {
        border-width: rem(2 / 4);
      }
    }

    #{$root}-typeh4 & {
      // TODO: The size of sp-version is not specified.
      width: rem(1.7);
      height: rem(1.7);
      font-size: rem2(18);
      line-height: rem2(21);

      &::before,
      &::after {
        border-width: rem(1.7 / 4);
      }
    }
  }

  &_category {

    // TODO: SP Version has not been determined
    .a-text {
      #{$root}-typeh1 & {
        font-size: rem2(18);
        line-height: rem2(21);
      }
    }
  }

  &-line {
    border-bottom: 1px solid $COLOR-GREYISH;
  }

  &-boxshadow {
    text-align: center;

    #{$root}_text {
      position: relative;
      width: max-content;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: z('heading', 'boxshadowbefore');
        content: '';
        background-color: $COLOR-CERULEAN;
      }
    }

    &#{$root}-center #{$root}_text {
      display: inline;
    }

    &#{$root}-typeh1 #{$root}_text {
      padding: 14px 0 0 14px;

      &::before {
        width: 28px;
        height: 28px;
      }

      @include pc {
        padding: rem(1.5) 0 0 rem(1.5);

        &::before {
          width: rem(3);
          height: rem(3);
        }
      }
    }

    &#{$root}-typeh2 #{$root}_text {
      padding: 12px 0 0 12px;

      &::before {
        width: 24px;
        height: 24px;
      }

      @include pc {
        padding: rem(1) 0 0 rem(1);

        &::before {
          width: rem(2);
          height: rem(2);
        }
      }
    }

    &#{$root}-typeh3 #{$root}_text {
      padding: rem(0.6666666667) 0 0 rem(0.6666666667);

      &::before {
        width: rem(1.3333333333);
        height: rem(1.3333333333);
      }
    }

    &#{$root}-typeh4 #{$root}_text {
      padding: rem(0.4444444445) 0 0 rem(0.4444444445);

      &::before {
        width: rem(0.8888888889);
        height: rem(0.8888888889);
      }
    }
  }
}