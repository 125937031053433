.m-contactbanner {
  $root: &;
  position: relative;
  background-color: $COLOR-CERULEAN;

  &_bg {
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: (296 / 960) * 100%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;

    @include pc {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      padding-bottom: 0;
    }

    #{$root}-showBackgroundOnlyOnPC & {
      @include sp {
        display: none;
      }
    }
  }

  &_content {
    padding: rem(2.5) rem(1) rem(2.5) 0;

    @include pc {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: rem(5.625) rem(1) rem(5.625) 0;
    }

    #{$root}-pdleftcontent & {
      padding-left: rem(1);

      @include pc {
        padding-left: rem(2.5);
      }
    }

    .a-button {
      width: max-content;
      min-width: rem(13.5);

      #{$root}-showBackgroundOnlyOnPC & {
        padding-right: rem(2.375);
        padding-left: rem(2.375);
      }

      >span {
        #{$root}-showBackgroundOnlyOnPC & {
          font-size: 24px;
          line-height: 28px;

          @include pc {
            font-size: rem(2);
            line-height: rem(2.375);
          }
        }
      }
    }

    .m-heading {
      &_body {
        @include pc {
          margin-bottom: rem(2);
        }
      }

      &_text {
        #{$root}-showBackgroundOnlyOnPC & {
          @include u-fw-m;
        }
      }
    }
  }
}