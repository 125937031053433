/* #region BASE COLOR */
$COLOR-AZURE: rgb(0, 161, 228);
$COLOR-ALICE-BLUE: rgb(240, 251, 255);
$COLOR-BLACK: rgb(0, 0, 0);
$COLOR-BLACK-TWO: rgb(51, 51, 51);
$COLOR-BRIGHT-CERULEAN: rgb(40, 171, 232);
$COLOR-BRIGHT-CERULEAN2: rgb(40, 179, 235);
$COLOR-CERULEAN: rgb(0, 151, 224);
$COLOR-GRAY: rgb(191, 191, 191);
$COLOR-GREYISH-BROWN: rgb(85, 85, 85);
$COLOR-GREYISH: rgb(179, 179, 179);
$COLOR-GREYISH-TWO: rgb(171, 171, 171);
$COLOR-KELLEY-GREEN: rgb(0, 134, 52);
$COLOR-LIGHT-BLUE: rgb(84, 195, 241);
$COLOR-LIGHT-BLUE-TWO: rgb(220, 242, 255);
$COLOR-LIGHT-GOLD: rgb(251, 204, 74);
$COLOR-MARINE-BLUE: rgb(0, 40, 112);
$COLOR-SLATE-BLUE: rgb(27, 56, 107);
$COLOR-SPIRO-DISCO-BALL: rgb(42, 203, 245);
$COLOR-PALE-GREY: rgb(240, 244, 247);
$COLOR-PUMPKIN: rgb(240, 131, 0);
$COLOR-TEAL: rgb(0, 143, 126);
$COLOR-WARM-GREY-THREE: rgb(127, 127, 127);
$COLOR-WARM-GREY-TWO: rgb(112, 112, 112);
$COLOR-WARM-GREY: rgb(128, 128, 128);
$COLOR-WHITE-SEVEN: rgb(245, 245, 245);
$COLOR-WHITE-SIX: rgb(247, 247, 247);
$COLOR-WHITE-FIVE: rgb(242, 242, 242);
$COLOR-WHITE-FOUR: rgb(250, 250, 250);
$COLOR-WHITE-THREE: rgb(229, 229, 229);
$COLOR-WHITE-TWO: rgb(231, 231, 231);
$COLOR-WHITE: rgb(255, 255, 255);
$COLOR-YELLOW-ORANGE: rgb(251, 186, 0);
$COLOR-YALE-BLUE: rgb(23, 83, 137);
$COLOR-BROWNISH-GREY: rgb(102, 102, 102);
$COLOR-LICORICE: rgb(17, 17, 17);
$COLOR-VERY-LIGHT-PINK: rgb(213, 213, 213);
$COLOR-CHERRY: rgb(216, 12, 24);
$COLOR-VIVID-RED: rgb(240, 12, 24);
$COLOR-DARK-SLATE-BLUE: rgb(27, 56, 107);
$COLOR-PICTON-BLUE: rgb(50, 171, 230);
$COLOR-PICTON-BLUE2: rgb(68, 200, 245);
$COLOR-STAR-COMMAND-BLUE: rgb(0, 121, 179);
$COLOR-CAROLINA-BLUE: rgb(91, 155, 213);
$COLOR-DARK-LIVER: rgb(77, 77, 77);
$COLOR-RAISIN-BLACK: rgb(36, 30, 30);

/* #endregion */

/* #region UTILITY COLOR */
$COLOR-UTILITY-BADGE-SHADOW: rgba($COLOR-BLACK, 0.2);
$COLOR-UTILITY-CARD-SHADOW: rgba($COLOR-BLACK, 0.09);
$COLOR-UTILITY-BG-LOCATION-HOVER: rgba($COLOR-CERULEAN, 0.1);
$COLOR-UTILITY-BG-LOCATION-ACTIVE: rgba($COLOR-CERULEAN, 0.2);
$COLOR-UTILITY-TXT-BASE: $COLOR-BLACK;
$COLOR-UTILITY-TXT-LINK: $COLOR-CERULEAN;
$COLOR-UTILITY-TXT-SLIDE-INACTIVE: rgba($COLOR-BLACK, 0.16);
$COLOR-UTILITY-TRANSPARENT: transparent;
$COLOR-UTILITY-BG-SLIDE-WHITE: rgba($COLOR-WHITE, 0.6);
$COLOR-UTILITY-BG-CARDTAG: rgba($COLOR-WHITE, 0.16);
$COLOR-UTILITY-BG-CARDFEATURE-WHITE: rgba($COLOR-WHITE, 0.8);
$COLOR-UTILITY-BG-CARDFEATURE-CERULEAN: rgba($COLOR-CERULEAN, 0.8);
$COLOR-UTILITY-BG-CATALOGUE-BLACK: rgba($COLOR-BLACK, 0.7);
$COLOR-UTILITY-TAB-SHADOW: rgba($COLOR-BLACK, 0.1);
$COLOR-UTILITY-BD-SUBMENU: rgb(231, 231, 231);
$COLOR-UTILITY-BD-SUBMENU-SP: rgba(121, 121, 121, 1);
$COLOR-UTILITY-LINE: rgb(230, 230, 230);
$COLOR-UTILITY-TOOLTIP: rgb(17, 17, 17);
$COLOR-UTILITY-CLEAR: rgb(222, 53, 11);
$COLOR-UTILITY-BG-CLEAR: rgb(255, 189, 173);
$COLOR-UTILITY-BG-IMG-LEADCARDLINK: rgb(34, 30, 30);
$COLOR-UTILITY-BTN-SUCCESS-HOVER: rgb(50, 158, 92);
$COLOR-UTILITY-BTN-SUCCESS-ACTIVE: rgb(0, 107, 43);
$COLOR-UTILITY-BTN-SUCCESS-OUTLINE-HOVER: rgba($COLOR-KELLEY-GREEN, 0.1);
$COLOR-UTILITY-BTN-SUCCESS-OUTLINE-ACTIVE: rgba($COLOR-KELLEY-GREEN, 0.2);
$COLOR-UTILITY-BTN-BLACK-HOVER: rgba($COLOR-BROWNISH-GREY, 0.05);
$COLOR-UTILITY-BTN-BLACK-ACTIVE: rgba($COLOR-BROWNISH-GREY, 0.1);
$COLOR-UTILITY-BTN-BLACK-OP-05: rgba($COLOR-BROWNISH-GREY, 0.5);
$COLOR-UTILITY-BTN-WHITE-HOVER: rgb(204, 234, 248);
$COLOR-UTILITY-BD-MODAL: rgb(204, 204, 204);
$COLOR-UTILITY-COMPARISONBAR: rgba($COLOR-WARM-GREY-TWO, 0.6);
$COLOR-UTILITY-BD-NEW: rgba($COLOR-BLACK, 0.16);
$COLOR-UTILITY-BG-CARDFEATURE-DARK: rgba($COLOR-DARK-LIVER, 0.8);

/* #region GRADIENT COLOR */

$COLOR-UTILITY-GRD-BTT-ZTDN: linear-gradient(rgba($COLOR-BLACK, 0),
    rgba($COLOR-BLACK, 0.9)); // Bottom to top and zero to dot nine

$COLOR-UTILITY-GRD-BTT-ZTN: linear-gradient(rgba($COLOR-BLACK, 0), $COLOR-BLACK); // Bottom to top and zero to none
$COLOR-UTILITY-GRD-LTR-ZTDF: linear-gradient(to left,
    rgba($COLOR-BLACK, 0),
    rgba($COLOR-BLACK, 0.4)); // Left to right and zero to dot four
$COLOR-UTILITY-GRD-LTR-ZTDFI: linear-gradient(to left,
    rgba($COLOR-BLACK, 0),
    rgba($COLOR-BLACK, 0.5)); // Left to right and zero to dot five
$COLOR-UTILITY-GRD-LTR-ZTDS: linear-gradient(to left,
    rgba($COLOR-BLACK, 0),
    rgba($COLOR-BLACK, 0.6)); // Left to right and zero to dot six
$COLOR-UTILITY-GRD-ERRORCODE: linear-gradient(0deg, rgba($COLOR-CERULEAN, 0.7), rgba($COLOR-CERULEAN, 0.7));

$COLOR-UTILITY-GRD-CERULEAN-SECTION: linear-gradient(0deg, rgba($COLOR-CERULEAN, 0.6), rgba($COLOR-CERULEAN, 0.6));
$COLOR-UTILITY-GRD-PALE-GREY-SECTION: linear-gradient(0deg, rgba($COLOR-PALE-GREY, 0.6), rgba($COLOR-PALE-GREY, 0.6));
$COLOR-UTILITY-GRD-WHITE-FOUR-SECTION: linear-gradient(0deg,
    rgba($COLOR-WHITE-FOUR, 0.6),
    rgba($COLOR-WHITE-FOUR, 0.6));
$COLOR-UTILITY-GRD-WHITE-FIVE-SECTION: linear-gradient(0deg,
    rgba($COLOR-WHITE-FIVE, 0.6),
    rgba($COLOR-WHITE-FIVE, 0.6));
$COLOR-UTILITY-GRD-WHITE-SIX-SECTION: linear-gradient(0deg, rgba($COLOR-WHITE-SIX, 0.6), rgba($COLOR-WHITE-SIX, 0.6));
/* #endregion */

/* #endregion */

/* #region FONT-FAMILY */
$FONTFAMILY-BASIC: 'Roboto', 'Arial', 'Helvetica', sans-serif;
// $FONTFAMILY-RALEWAY: 'Raleway', 'Arial', 'Helvetica', sans-serif;
/* #endregion */

/* #region FONT-SIZE html */
$FONT-SIZE-HTML: rem(1);
/* #endregion */

/* #region SIZE-SCREEN */
$WIDTH-PC-CONTENTS-CAROUSEL: 1260px;
$WIDTH-PC-LARGE: 1920px;
$WIDTH-PC: 1200px;
$WIDTH-TAB: 992px;
$WIDTH-MD: 768px;
$WIDTH-XS: 576px;
$WIDTH-CONTAINER-PX: 1140px;
$WIDTH-CONTAINER-UNIT: 1140;
/* #endregion */