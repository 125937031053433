.m-checkboxgroup {
  &_title {
    margin-bottom: rem(1.5);

    .a-text {
      margin-left: rem(2.1875);
      color: $COLOR-BLACK;
    }
  }

  &_checkboxes {
    .a-checkbox + .a-checkbox {
      margin-top: rem(1.5);
    }
  }
}
