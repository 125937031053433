.m-cardproductinfo {
  padding: 18px 12px;
  border-radius: 3px;

  @include pc {
    padding: rem(2) rem(1.5);
  }

  @include sp {
    background-color: $COLOR-WHITE;
  }

  &_title {
    .a-image {
      max-width: rem(19.375);
      margin-bottom: rem(1.475);
    }

    .m-heading_body {
      margin: 0 0 rem(1) 0;
    }
  }

  &_wrapimage {
    display: flex;
    align-items: center;
    margin-bottom: rem(1.5);

    .a-image_img {
      max-height: rem(2);
    }

    .a-image + .a-image {
      margin-left: 12px;

      @include pc {
        margin-left: rem(1.5);
      }
    }
  }

  &_wrapbutton {
    margin-bottom: rem(1.625);

    @include pc {
      display: flex;
    }

    @include tab {
      justify-content: center;
    }

    .a-button {
      width: 50%;
      max-width: rem(14.5);

      @include sp {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .a-button + .a-button {
      margin-top: 12px;

      @include pc {
        margin-top: 0;
        margin-left: rem(1.5);
      }
    }
  }

  &_wraptermlink {
    .a-termlink + .a-termlink {
      margin-top: rem(0.625);
    }
  }

  .m-textlist {
    margin-bottom: rem(1.875);
  }
  &_SKU {
    .a-text {
      display: inline-block;
      margin-bottom: rem(5/14);
    }
  }
}
