.m-collapse {
  $root: &;

  &_title {
    position: relative;
    cursor: pointer;
    @include u-fw-m;

    #{$root}-panel > & {
      display: flex;
      justify-content: space-between;
      padding: rem(1.5) rem(1);
      color: $COLOR-CERULEAN;
      background-color: $COLOR-WHITE-FOUR;
      border-radius: 4px;
    }

    #{$root}-panel#{$root}-open > &,
    #{$root}-panel:hover > & {
      background-color: $COLOR-CERULEAN;
    }

    #{$root}-box > & {
      display: inline-block;
      color: $COLOR-BLACK;
    }

    &::before {
      #{$root}-panel > & {
        position: absolute;
        top: calc(#{rem(2)} - 2px);
        left: rem(1);
        content: '';
        border: 6px solid transparent;
        border-top-color: $COLOR-CERULEAN;
        border-left-color: $COLOR-CERULEAN;
      }

      #{$root}-panel#{$root}-open > &,
      #{$root}-panel:hover > & {
        border-top-color: $COLOR-WHITE;
        border-left-color: $COLOR-WHITE;
      }
    }

    & > .a-text {
      #{$root}-panel > & {
        padding-right: rem(1);
        margin: 0 0 0 rem(2);
        font-size: rem(1.125);
      }

      #{$root}-panel#{$root}-open > &,
      #{$root}-panel:hover > & {
        color: $COLOR-WHITE;
      }

      #{$root}-box > & {
        padding-right: rem(1);
        margin-bottom: 0;
      }

      #{$root}-box#{$root}-open > &,
      #{$root}-box:hover > & {
        color: $COLOR-CERULEAN;
        text-decoration: underline;
      }

      & > #{$root}_boxarrow {
        position: relative;
        top: calc(#{rem(1)} / 2);
        margin-left: rem(0.5);
        & > span {
          #{$root}-box > & {
            &:nth-of-type(1),
            &:nth-of-type(2) {
              position: absolute;
              top: 0;
              width: 8px;
              height: 2px;
              background-color: $COLOR-BLACK;
              border-radius: 2px;
              transition: transform 0.2s ease-in;
            }

            &:nth-of-type(1) {
              transform: rotate(45deg);
            }

            &:nth-of-type(2) {
              left: 5px;
              transform: rotate(-45deg);
            }
          }

          #{$root}-box#{$root}-open > &,
          #{$root}-box:hover > & {
            background-color: $COLOR-CERULEAN;
          }

          #{$root}-box#{$root}-open > & {
            &:nth-of-type(1) {
              transform: rotate(-45deg);
            }

            &:nth-of-type(2) {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  &_content {
    font-size: rem(1);

    ul {
      list-style: none;
    }

    #{$root}-panel > & {
      height: 0;
      overflow: hidden;
    }

    #{$root}-open#{$root}-panel > & {
      height: auto;
      margin-top: rem(2);
    }

    #{$root}-box > & {
      display: none;
      padding: rem(1.5);
      background-color: $COLOR-WHITE-FOUR;
      border-left: 4px solid $COLOR-CERULEAN;
    }

    #{$root}-open#{$root}-box > & {
      display: block;
      margin-top: rem(1.5);
    }
  }

  &_toggle {
    #{$root}_title > & {
      #{$root}-panel > & {
        position: relative;
        right: 8px;
        flex: 0 0 auto;
      }

      #{$root}-panel#{$root}-open > &,
      #{$root}-panel:hover > & {
        color: $COLOR-WHITE;
      }
    }

    #{$root}_title > &::before,
    #{$root}_title > &::after {
      #{$root}-panel > & {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        background-color: $COLOR-CERULEAN;
        transition: transform 0.2s ease-in;
        transform: translate(-50%, -50%);
      }

      #{$root}-panel#{$root}-open > &,
      #{$root}-panel:hover > & {
        background-color: $COLOR-WHITE;
      }
    }

    #{$root}_title > &::before {
      #{$root}-panel > & {
        width: 16px;
        height: 2px;
      }
    }

    #{$root}_title > &::after {
      #{$root}-panel > & {
        width: 2px;
        height: 16px;
      }

      #{$root}-panel#{$root}-open > & {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &-panel + &-panel {
    margin-top: rem(1);
  }

  &-box + &-box {
    margin-top: rem(1.5);
  }
}
