.o-floatingContact {
  $root: &;

  position: fixed;
  bottom: 0;
  height: auto;
  width: 100%;
  padding: 0;
  z-index: z('floatingContact', 'fixed');
  height: 0;
  background-color: white;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px -4px 8px 0px rgba(148, 148, 148, 0.3);

  @include sp() {
    display: none;
  }

  &_mobile {
    display: block;
    position: fixed;
    right: rem(1);
    bottom: rem(1);
    z-index: 12;

    @include pc() {
      display: none;
    }

    &_button {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &_item {
      width: rem(3);
      height: rem(3);
      border-radius: 50%;
      background-color: #0097e0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .a-image {
        width: 80%;
      }

      &-mail {
        background-color: #F6F6F6;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &-phone {
        position: absolute;
        bottom: rem(4);
        right: 0;
      }
    }
  }

  &_body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sp() {
      flex-direction: column;
      display: none;
    }

    &_messageIcon {
      width: 44px;

      @include sp() {
        margin-bottom: 12px;
      }
    }

    &_contactUsText {
      margin-left: 20px;
      margin-right: 150px;

      @include spMenu {
        margin-right: 30px;
      }

      p {
        margin: 0;
      }
    }

    &_button {
      margin-left: 8px;
      margin-right: 8px;
      border-radius: 6px;
      overflow: hidden;

      @include sp() {
        margin-top: 20px;
      }

      &-phone {
        #{$root}_body_item {
          background-image: linear-gradient(to right, #00A1E4, #4bc3f6, #26b6f3, #4bc3f6);
          background-size: 300% 100%;
          transition: all .4s ease-in-out;

          &:hover {
            background-position: 100% 0;
            transition: all .4s ease-in-out;
          }
        }

        span {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
        }
      }

      &-advice {
        #{$root}_body_item {
          background-image: linear-gradient(to right, #f2efef, #F6F6F6, #4bc3f645, #F6F6F6);
          background-size: 300% 100%;
          transition: all .4s ease-in-out;

          &:hover {
            background-position: 100% 0;
            transition: all .4s ease-in-out;
          }
        }

        span {
          font-size: 14px;
          font-weight: 500;
          color: #00A1E4;
        }
      }
    }

    &_buttons {
      display: flex;
    }

    &_item {
      padding: 12px 50px 12px 50px;
      display: flex;
      align-items: center;

      @include sp() {
        padding: 12px;
      }

      .a-image {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  &_collapseArrow {
    position: absolute;
    top: -24px;
    z-index: 99;
    right: rem(2.7);
    width: 48px;
    height: 24px;
    cursor: pointer;
    border-radius: 4px 4px 0px 0px;
    background: rgb(255, 255, 255);
    text-align: center;
    box-shadow: 0px -4px 8px 0px rgba(148, 148, 148, 0.3);

    @include sp() {
      right: 50px;
    }

    .a-icon {
      animation: MoveUpDown 1.2s linear infinite;
      position: absolute;
      left: 16px;
      bottom: 0;
      transform: rotate(-180deg);
    }
  }

  &-open {
    padding: 1rem 0;
    height: 80px;

    @include sp() {
      height: 200px;
    }

    #{$root}_collapseArrow {
      .a-icon {
        transform: rotate(0deg);
        animation: MoveUpDown 1.2s linear infinite;
      }
    }
  }
}

@keyframes MoveUpDown {

  0%,
  100% {
    bottom: -1px;
  }

  50% {
    bottom: 5px;
  }
}