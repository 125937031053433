// color
$color-text: #333333;
$color-text2: #666666;
$color-footer: #30353A;
$color-blue: #0097E0;
$color-blue2: #54C3F1;
$color-darkblue: #1E496B;
$color-gray: #666666;
$color-gray2: #B5B5B6;

// media queries
@mixin sp {
  @media screen and (max-width: 599px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (max-width: 1024px) and (min-width: 600px) {
    @content;
  }
}

@mixin tablet-min {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin pc-big {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

// hover
@mixin hover {
  @media (hover: hover) {
    &:where(:any-link, :enabled, summary):hover {
      @content;
    }
  }
}