.m-cardfeature {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  position: relative;
  height: 100%;
  overflow: hidden;
  color: $COLOR-BLACK;
  cursor: pointer;

  &_footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $COLOR-UTILITY-BG-CARDFEATURE-DARK;
    transition: background-color 0.8s $animation;

    #{$root}-active &,
    #{$root}:hover & {
      background-color: $COLOR-UTILITY-BG-CARDFEATURE-CERULEAN;
    }
  }

  &_wrap {
    padding: 0 rem(1.5) rem(1) rem(1.5);
  }

  &_body {
    .a-image img {
      transition: transform 2.4s $animation;

      #{$root}-active &,
      #{$root}:hover & {
        transform: scale(1.05);
      }
    }
  }

  &_imgthumb {
    width: 100%;

    .a-image {
      max-width: 130px;
      margin-top: -50px;
      margin-left: auto;
    }
  }

  &_title {
    min-height: rem(3);

    .a-text {
      color: $COLOR-WHITE;
      transition: color 0.8s $animation;
      @include text-overflow(2);
    }
  }
  &_SKU {
    display: inline-block;
    .a-text {
      @include text-overflow(1);
      min-height: rem(14/14);
      margin-top: rem(1/14);
      margin-bottom: rem(5/14);
    }
  }
}
