.a-language {
  $root: &;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  display: inline-block;

  @include spMenu {
    position: relative;
  }

  &_langactived {
    display: none;

    @include spMenu {
      position: relative;
      display: inline-block;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      @include u-fw-m;
    }

    &:after {
      @include spMenu {
        position: absolute;
        top: 7px;
        right: -15px;
        content: '';
        border: 5px solid $COLOR-UTILITY-TRANSPARENT;
        border-top-color: $COLOR-BLACK;
        border-radius: 3px;
      }
    }
  }

  &_list {
    #{$root}-withdropdown & {
      @include spMenu {
        position: absolute;
        top: 30px;
        right: 0;
        display: none;
        width: 150px;
        padding: 7.5px 0;
        background-color: $COLOR-WHITE;
        border: 1px solid $COLOR-UTILITY-BD-SUBMENU;
        border-radius: 2px;
        // TODO: replace with color variable
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
      }

      &:before {
        // NOTE: This is only used for hover behavior
        @include spMenu {
          position: absolute;
          top: -15px;
          right: 0;
          bottom: 0;
          left: 0;
          height: 15px;
          content: '';
        }
      }
    }
  }

  &_item {
    font-size: rem(1);
    color: $COLOR-WARM-GREY;
    cursor: pointer;
    transition: color 0.3s $animation;

    #{$root}-withdropdown & {
      @include spMenu {
        display: block;
        padding: 7.5px 15px;
      }

      &:hover {
        @include spMenu {
          color: $COLOR-CERULEAN;
        }
      }
    }

    &:not(:last-child):after {
      padding-right: 3px;
      color: $COLOR-WARM-GREY;
      content: ' /';

      #{$root}-withdropdown & {
        @include spMenu {
          content: none;
        }
      }
    }

    &:not(&-active):hover {
      &::after {
        color: $COLOR-WARM-GREY;
      }
    }

    &-active {
      position: none;
      color: $COLOR-BLACK;
      cursor: no-drop;
      @include u-fw-b;

      #{$root}-withdropdown & {
        @include spMenu {
          color: $COLOR-CERULEAN;
          // TODO: Replace with color variable
          background-color: rgb(230, 247, 255);
          border-right: 3px solid $COLOR-CERULEAN;
        }
      }
    }
  }

  &:hover &_list {
    @include spMenu {
      display: block;
    }
  }
}
