.o-banner {
  $root: &;

  display: block;
  width: 100%;
  overflow: hidden;
  @include aspectRatio(3, 2);

  &-withslider {
    padding-bottom: 100px;
  }

  @include pc {
    @include aspectRatio(1920, 400);
  }

  // &:after {
  //   position: absolute;
  //   top: 0;
  //   right: 50%;
  //   bottom: 0;
  //   left: 0;i
  //   content: '';
  //   background-image: $COLOR-UTILITY-GRD-LTR-ZTDFI;

  //   @include pc {
  //     background-image: $COLOR-UTILITY-GRD-LTR-ZTDF;
  //   }
  // }

  &_picture {
    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      max-width: 100%;
      height: 100%;
      margin: auto;
      object-fit: cover;
    }

    #{$root}-usevideo & {
      @include aspectRatio(375, 556);

      @include tabFixedContent {
        @include aspectRatio(1920, 650);
      }
    }
  }

  &_content {
    position: absolute;
    top: 50%;
    z-index: z('banner', 'content');
    width: 100%;
    transform: translateY(-50%);

    .m-heading_body {
      margin-bottom: rem(1);
    }

    .m-heading_text {
      @include spMenu {
        font-size: 28px;
        line-height: 34px;
      }
    }

    #{$root}-withservicepage & {
      z-index: z('banner', 'servicecontent');
    }
  }

  &_wraptitle {
    #{$root}-withrightsection & {
      //TODO: It has been corrupted on Tablet-Size
      @include pc {
        width: 50%;
        max-width: 432px;
        padding-right: 10px;
      }
    }

    #{$root}:not(#{$root}-withrightsection) & {
      @include tabFixedContent {
        width: 66%;
      }

      @include pcFixedContent {
        width: 50%;
      }
    }

    #{$root}-withleadbanner & {
      @include pc {
        width: 60%;
        max-width: 60%;
      }

      @include sp {
        order: 2;
        width: 100%;
        padding-top: 16px;
        text-align: center;
      }
    }
  }

  &_category {
    font-size: 42px;
    color: $COLOR-WHITE;
    @include u-fw-b;

    @include pc {
      font-size: rem(4);
      line-height: rem(4.6875);
    }

    > small {
      font-size: 16px;
      @include u-fw-r;

      @include pc {
        font-size: rem(1.5);
      }
    }
  }

  &_rightsection {
    padding-top: 30px; // TODO: Replace with proper-size

    @include pc {
      width: 50%;
      max-width: 536px;
      padding-top: 0;
      padding-left: 10px;
    }

    #{$root}-withleadbanner & {
      @include pc {
        width: 30%;
        max-width: 30%;
      }

      @include sp {
        order: 1;
        width: 100%;
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        padding: 0;
        margin: auto;
      }
    }

    #{$root}-withairpurifier & {
      max-width: unset;
      padding-top: 0;
      padding-right: rem(5.86);
      padding-left: 0;
      margin-left: auto;

      @include tab {
        padding-right: rem(2.86);
      }

      @include sp {
        padding-right: 0;
      }
    }

    .a-image {
      img {
        object-fit: contain;
        height: auto;
      }
    }
  }

  &_container {
    width: auto !important;

    #{$root}-withrightsection & {
      @include pc {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    #{$root}-withleadbanner & {
      @include sp {
        display: flex;
        flex-wrap: wrap;
      }
    }

    #{$root}-fullcontainer & {
      max-width: 100%;
    }
  }

  &_description {
    color: $COLOR-WHITE;

    &-isHome {
      color: $COLOR-BLACK;
    }
    #{$root}:not(#{$root}-withrightsection) & {
      @include tabFixedContent {
        width: 80%;
      }
    }

    #{$root}-withsolutiondetail & {
      @include pc {
        width: 50%;
      }

      @include tab {
        width: 75%;
      }
    }

    #{$root}-hiddendescriptiononspmenu & {
      @include spMenu {
        display: none;
      }
    }

    #{$root}-descriptionshadow & {
      text-shadow: 0px 0px 2px $COLOR-BLACK;
    }
  }

  &_button {
    margin-top: rem(2);

    .a-button {
      display: inline-flex;
    }
  }

  &-withhomepage {
    @include pc {
      @include aspectRatio(1920, 650);
    }
  }

  &-withproductdetail {
    @include pc {
      @include aspectRatio(1920, 700);
    }
  }

  &-noshadow {
    &::after {
      background-image: none;
    }
  }

  &-withleadbanner {
    @include pc {
      @include aspectRatio(1920, 560);
    }

    @include sp {
      @include aspectRatio(357, 562);
    }

    &::after {
      right: 0;
      background-image: linear-gradient(to left, rgba($COLOR-WHITE, 0.7), rgba($COLOR-WHITE, 0.7));
    }
  }

  &-withservicepage {
    overflow: visible;

    @include sp {
      @include aspectRatio(1, 1.48);
    }

    &::after {
      right: 0;
    }
  }

  &-withimgtab {
    @include tab {
      @include aspectRatio(812, 275);
    }
  }

  &-withsolutiondetail {
    @include tab {
      @include aspectRatio(5, 2);
    }

    @include sp {
      @include aspectRatio(1, 1.48);
    }
  }

  &-withairpurifier {
    @include aspectRatio(375, 555);

    @include pc {
      @include aspectRatio(1920, 650);
    }
  }

  &-usevideo {
    &:before {
      display: none;
    }
  }

  &_videosection {
    @include tabFixedContent {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: z('banner', 'content');
      width: calc(851 / 1920 * 100%);
      max-width: rem(53.188);
      padding-right: rem(5.86);
      transform: translateY(-50%);
    }

    .m-video {
      @include tabFixedContent {
        margin-top: calc(80 / 1920 * 100%);
        margin-bottom: calc(80 / 1920 * 100%);
      }

      @include spMenu {
        border-radius: 0;
      }

      &:not(&-played)::after {
        @include spMenu {
          backdrop-filter: blur(1.2px);
        }
      }

      &_playbutton {
        width: calc(128 / 851 * 100%);
        height: calc(79 / 851 * 100%);

        @include tabFixedContent {
          min-width: rem(4);
          min-height: rem(2.438);
        }

        .a-icon {
          @include tab {
            width: 100%;
            height: 100%;
          }

          @include sp {
            width: rem(3.563);
            height: rem(1.5);
          }
        }
      }
    }
  }
}
