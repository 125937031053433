.a-image {
  $root: &;
  position: relative;

  &_img {
    max-width: 100%;
    #{$root}-fixed &,
    #{$root}-oversize &,
    #{$root}-circled & {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      margin: auto;
    }
    #{$root}-fixed & {
      object-fit: contain;
    }
    #{$root}-oversize & {
      object-fit: cover;
    }
    #{$root}-circled & {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &-center {
    text-align: center;
  }

  &-fixed {
    background-color: $COLOR-WHITE-FOUR;
  }

  &-transparent {
    background-color: $COLOR-UTILITY-TRANSPARENT;
  }

  &-16x9 {
    @include aspectRatio(16, 9);
  }

  &-1x1 {
    @include aspectRatio(1, 1);
  }

  &-2x1 {
    @include aspectRatio(2, 1);
  }

  &-3x2 {
    @include aspectRatio(3, 2);
  }

  &-3x4 {
    @include aspectRatio(3, 4);
  }

  &-4x3 {
    @include aspectRatio(4, 3);
  }

  &-carddetail {
    // 0.75
    @include aspectRatio(312, 234);
  }

  &-carddetailnews {
    @include aspectRatio(1706, 1128);
  }

  &-carddetailnewsrelative {
    @include aspectRatio(420, 272);
  }

  &-cardsupport {
    // 1.2
    @include aspectRatio(160, 192);
  }

  &-carddiscover {
    // 1.3493589744
    @include aspectRatio(312, 421);
  }

  &-cardcasestudy {
    // 0.5660377358
    @include aspectRatio(424, 240);
  }

  &-cardtaglarge {
    // 0.6516516517
    @include aspectRatio(666, 434);
  }

  &-cardtagmedium {
    // 1.3818181818
    @include aspectRatio(330, 456);
  }

  &-cardtagsmall {
    // 0.6606060606
    @include aspectRatio(330, 218);
  }

  &-carddownload {
    // 1.3316831683
    @include aspectRatio(202, 269);
  }

  &-cardcomparehorizontal {
    // 0.6181818182
    @include aspectRatio(660, 408);
  }

  &-cardcomparevertical {
    // 0.5471698113
    @include aspectRatio(424, 232);
  }

  &-cardsystem {
    // 0.5283018868
    @include aspectRatio(424, 224);
  }

  &-cardproducttype {
    // 0.6153846154
    @include aspectRatio(312, 192);
  }

  &-cardpartner {
    // 1.5070754717
    @include aspectRatio(424, 639);
  }

  &-cardpichonkun {
    // 0.745283018867925
    @include aspectRatio(424, 316);
  }

  &-cardcontact {
    // 0.679245283018868
    @include aspectRatio(424, 288);
  }

  &-cardbusiness {
    // // 0.679245283018868
    @include aspectRatio(424, 288);
  }

  // NOTE: Only use on the Product page
  &-productresidential {
    // 1.066666666666667
    @include aspectRatio(555, 583);
  }

  // NOTE: Only use on the Product page
  &-productindustrial {
    // 1.066666666666667
    @include aspectRatio(555, 583);
  }

  // NOTE: Only use on the Product page
  &-productcomercial {
    // 1.120720720720721
    @include aspectRatio(555, 618);
  }

  &-cardthumbfeature {
    //  0.769230769230769
    @include aspectRatio(130, 100);
  }

  &-cardconstruct {
    // 0.5467625899
    @include aspectRatio(695, 380);
  }

  &-cardaward {
    // 1.305
    @include aspectRatio(200, 261);
  }

  &-cardaboutdaikin {
    // 1.5047169811
    @include aspectRatio(424, 638);
  }

  &-cardfactoryab {
    // 0.666666666666667
    @include aspectRatio(780, 520);
  }

  &-cardfactoryc {
    // 0.603395061728395
    @include aspectRatio(648, 391);
  }

  &-cardfactoryg {
    // 0.752358490566038
    @include aspectRatio(424, 319);
  }

  &-cardtag {
    // 1.3435114504
    @include aspectRatio(262, 352);
  }

  &-cardsolution {
    //  0.528301886792453
    @include aspectRatio(424, 224);
  }

  &-cardthumbsolution {
    //  0.6627218935
    @include aspectRatio(169, 112);
  }

  &-imagesolution {
    //  0.833720930232558
    @include aspectRatio(560, 467);
  }

  &-imagesolutionproducttype {
    //  0,5613636364
    @include aspectRatio(1320, 741);
  }

  &-cardcompareoption {
    // 0.641025641025641
    @include aspectRatio(312, 200);
  }

  &-imageselector {
    // 1.14622641509434
    @include aspectRatio(424, 486);
  }

  &-comparisonbar {
    // 0.602094240837696
    @include aspectRatio(191, 115);
  }

  &-circled {
    @include aspectRatio(1, 1);
  }

  &-productsolutions {
    @include aspectRatio(312, 170);
  }

  &-airpurifierpartner {
    // 0.6127
    @include aspectRatio(424, 692);
  }

  &-technologystreamer {
    // 1.2757
    @include aspectRatio(620, 486);
  }

  &-airpurifiermart {
    @include aspectRatio(224, 78);
  }

  &-modalbackgroundimage {
    @include aspectRatio(1096, 680);
  }
}
