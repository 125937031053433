.p-notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  margin: 32px 0;

  @include pc {
    margin: rem(4) 0 rem(5.0625) 0;
  }
}
