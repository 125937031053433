html {
  overflow-x: hidden;
  font-size: $FONT-SIZE-HTML;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: $FONTFAMILY-BASIC;
}

img {
  max-width: 100%;
}

figure {
  margin-block-end: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  margin-block-start: 0;
}

// Default a-text style for p tags
p {
  margin-top: 0;
  margin-bottom: rem(1.5);
  font-size: rem(1);

  &:last-child {
    margin-bottom: 0;
  }
}

// Default a-textlink style for a tags
a {
  color: $COLOR-CERULEAN;
  text-decoration: none;
}

// Display none at PC
.u-dn-pc {
  @include pc {
    display: none;
  }
}

.u-d-tabfixedcontent {
  display: none;

  @include tabFixedContent {
    display: block;
  }
}

.u-dn-sp {
  @include sp {
    display: none;
  }
}

.u-dn-spmenu {
  @include spMenu {
    display: none;
  }
}

.u-dn-tab {
  @include tab {
    display: none;
  }
}

.u-dn-tabfixedcontent {
  @include tabFixedContent {
    display: none;
  }
}

.u-dn-pcfixedcontent {
  @include pcFixedContent {
    display: none;
  }
}

.u-dn {
  display: none;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-text-underline {
  text-decoration: underline;
}

.u-iframebox {
  margin: rem(1) 0;
  @include aspectRatio(16, 9);

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 100%;
  }
}

.u-negative-margintop-tab120 {
  @include tabFixedContent {
    margin-top: -#{rem(7.5)};
  }
}

.u-mt-30 {
  margin-top: 30px;
}

.u-mt-15 {
  margin-top: 15px;
}

.u-mb-15 {
  margin-bottom: 15px;
}

.u-mb-30 {
  margin-bottom: 30px;

  @include spMedium {
    margin-bottom: 15px;
  }
}

.u-mt-sp-15 {
  @include sp {
    margin-top: 15px;
  }
}

.u-t-lg-30 {
  @media (min-width: 992px) {
    top: 30px;
  }
}

.u-mt-lg-50 {
  @media (min-width: 992px) {
    margin-top: 50px;
  }
}

.u-mt-lg-60 {
  @media (min-width: 992px) {
    margin-top: 50px;
  }
}

.u-mt-lg-100 {
  @media (min-width: 992px) {
    margin-top: 100px;
  }
}

.u-negative-ml-xl-36 {
  @include pcFixedContent {
    margin-left: -36px !important;
  }
}

.u-negative-mr-xl-36 {
  @include pcFixedContent {
    margin-right: -36px !important;
  }
}

.u-negative-mt-lg-200 {
  @media (min-width: 992px) {
    margin-top: -200px;
  }
}

.u-pt-15 {
  padding-top: 15px;
}

.u-pb-15 {
  padding-bottom: 15px;
}

.u-ml-0 {
  margin-left: 0;
}

.u-pl-xl-36 {
  @include pcFixedContent {
    padding-left: 36px !important;
  }
}

.u-pr-xl-36 {
  @include pcFixedContent {
    padding-right: 36px !important;
  }
}

.u-pr-sp-0 {
  @include sp {
    padding-right: 0 !important;
  }
}

.u-pl-sp-0 {
  @include sp {
    padding-left: 0 !important;
  }
}

.u-fake-section {
  margin-top: 40px;

  @include pc {
    margin-top: rem(3.75);
  }
}

// Use original browser style list
.u-original-browser-style-lists {
  ul,
  ol {
    padding: revert;
    margin: revert;
  }
}

// NOTE: About the css of ipadmini, it's only used on the Product Split Detail page
.u-mt-half-ipadmini {
  @media (max-width: 768px) {
    margin-top: 50%;
  }
}

.u-ml-center-ipadmini {
  @media (width: 768px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: auto !important;
  }
}

input,
button,
select {
  font-family: inherit;
}

[role='button'],
button {
  cursor: pointer;
}

input {
  // override sanitize.css definition to avoid weird behavior on Safari.
  word-break: normal;
}

ul,
ol {
  padding: 0;
  margin: 0;
  font-size: rem(1);
}

b,
strong {
  @include u-fw-b;
}

dl > dd {
  margin-left: 16px;
}

// Override padding container of react-bootstrap (should have width 1140 when screen-size > 1200)
.container {
  @include pcFixedContent {
    padding: 0 !important;
  }
}

#story-root {
  margin: 10px;
}

.lazyload-wrapper {
  padding: 10px 0;

  @include pc {
    padding: 20px 0;
  }
}

h1 {
  font-size: 28px;
  line-height: 34px;

  @include pc {
    font-size: rem2(42);
    line-height: rem2(54);
  }
}

h2 {
  font-size: 24px;
  line-height: 28px;

  @include pc {
    font-size: rem2(28);
    line-height: rem2(33);
  }
}

h3 {
  font-size: 20px;
  line-height: rem2(25); // TODO: The line-height of sp-version is not specified.

  @include pc {
    font-size: rem2(21);
  }
}

h4 {
  // TODO: The size of sp-version is not specified.
  font-size: rem2(18);
  line-height: rem2(21);
}

.t-layout-hidden {
  overflow: hidden;
  // height: 100vh;
  // overflow: hidden;
  /* when modal active */
  touch-action: none;
  -webkit-overflow-scrolling: none;
  /* Other browsers */
  overscroll-behavior: none;
}

.overflowXHidden {
  overflow-x: unset;
}

.width100 {
  width: 100%;
}

.p-training {
  &_programming {
    .a-image {
      height: 100%;
    }
  }
}
