.m-locationbanner {
  $root: &;

  width: 100%;
  background-color: $COLOR-CERULEAN;
  box-shadow: 0 3px 6px 0 $COLOR-UTILITY-TXT-SLIDE-INACTIVE;

  @include tabFixedContent {
    display: flex;

    &:before {
      display: block;
      padding-bottom: (358 / 1320) * 100%;
      content: '';
    }
  }

  &_content {
    width: 100%;
    padding: 20px;

    @include tabFixedContent {
      align-self: center;
      width: 50%;
      padding: rem(3.75);
    }

    .m-heading_body {
      margin-bottom: rem(0.75);
    }
  }

  &_description {
    margin-bottom: rem(1);

    .a-text {
      font-size: rem(1.3125);
    }
  }

  &_map {
    background: $COLOR-CERULEAN url('~assets/images/common/locationbanner.png') center center/cover no-repeat;

    &:before {
      display: block;
      padding-bottom: (358 / 660) * 100%;
      content: '';
    }

    @include tabFixedContent {
      width: 50%;
    }
  }

  .a-button {
    width: 100%;
    max-width: rem(15.5);
    margin: auto;
  }
}
