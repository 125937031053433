.a-rate {
  &-sizenormal > .rc-rate {
    font-size: rem(1.25);
  }

  &-sizesmall > .rc-rate {
    font-size: rem(1);
  }

  &-cerulean {
    .rc-rate-star-half .rc-rate-star-first,
    .rc-rate-star-full .rc-rate-star-second,
    .rc-rate-star-half:hover .rc-rate-star-first,
    .rc-rate-star-full:hover .rc-rate-star-second {
      color: $COLOR-CERULEAN;
    }
  }

  &-black {
    .rc-rate-star-half .rc-rate-star-first,
    .rc-rate-star-full .rc-rate-star-second,
    .rc-rate-star-half:hover .rc-rate-star-first,
    .rc-rate-star-full:hover .rc-rate-star-second {
      color: $COLOR-BLACK;
    }
  }

  .rc-rate-star {
    margin-right: 0;

    &:not(:last-child) {
      margin-right: 4px;
    }

    > div {
      outline: none;
    }
  }
}
