.m-cardaward {
  $root: &;

  display: flex;
  height: 100%;
  background-color: $COLOR-WHITE-FOUR;

  &_thumb {
    width: 150px;

    @include pcFixedContent {
      width: 200px;
    }
  }

  &_content {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    width: calc(100% - 150px);
    padding: 16px;

    @include pcFixedContent {
      width: calc(100% - 200px);
    }

    #{$root}-verticalonsp & {
      @include sp {
        width: 100%;
      }
    }
  }

  &_title {
    margin-bottom: rem(1);
  }

  &_description {
    margin-bottom: rem(1);
    font-size: rem(1);
  }

  &-verticalonsp {
    @include sp {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
