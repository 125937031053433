.p-aboutdaikin {
  display: flex;

  @include spMenu {
    flex-wrap: wrap;
  }

  &_content {
    width: 100%;
    padding-left: rem(1.5);
    margin: rem(4) 0;

    @include spMenu {
      padding-left: 0;
      margin: 40px 0;
    }

    @include sp {
      margin-top: 80px;
    }
  }

  &_technology {
    font-size: rem(1);
    img {
      min-height: 150px;
      object-fit: scale-down;
    }
  }

  .o-sidebar {
    flex: 0 0 auto;
  }
}
