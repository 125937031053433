@keyframes balloonAnim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

figure,
nav {
  display: block;
}

.kanki {
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  list-style: none;
}

.kanki .svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.kanki {
  position: relative;
  z-index: 1;
  padding: 660px 0 0;
  font-family: Poppins, sans-serif;
}

@media screen and (max-width: 767px) {
  .kanki {
    min-width: 320px;
    overflow: hidden;
  }
}

.kanki__body {
  position: relative;
  z-index: 10;
  background: rgb(255, 255, 255);
}

.kanki img {
  vertical-align: top;
}

.kanki svg {
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .kanki .c-pc {
    display: none;
  }
}

.kanki .c-sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .kanki .c-sp {
    display: block;
  }
}

.header {
  box-sizing: border-box;
  height: 80px;
  padding: 22px 22px 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .header {
    height: 50px;
    padding: 15px 15px 0;
  }
}

.header h1 {
  margin: 0;
  font-size: 0;
}

.header a {
  display: block;
  width: 172px;
}

@media screen and (max-width: 767px) {
  .header a {
    width: 100px;
  }
}

.header img {
  width: 100%;
}

.footer {
  box-sizing: border-box;
  height: 60px;
  padding: 0 30px;
  background: rgb(0, 0, 0);
}

@media screen and (max-width: 767px) {
  .footer {
    height: auto;
    padding: 21px 15px 17px;
  }
}

.footer div {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  color: rgb(255, 255, 255);
  -ms-flex-align: center;
  -ms-flex-pack: end;
}

.footer small {
  display: block;
  font-family: Poppins, sans-serif;
  font-size: 15px;
}

@media screen and (max-width: 767px) {
  .footer small {
    font-size: 11px;
  }
}

.kanki .mainvisual {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 160px);
  max-height: 660px;
  overflow: hidden;
  font-family: Poppins, sans-serif;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual {
    width: 100%;
    height: 500px;
    margin: 0;
  }
}

.kanki .mainvisual[data-status='fixed'] {
  position: fixed;
}

.kanki .mainvisual__bg01,
.kanki .mainvisual__bg02,
.kanki .mainvisual__bgwhite {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.kanki .mainvisual__bg01 {
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__bg01 {
    background: url('~assets/images/airPurifier/mv/fresh_sp.jpg') no-repeat 50% 40%;
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  .kanki .mainvisual__bg01 {
    background: url('~assets/images/airPurifier/mv/fresh.jpg') no-repeat 50% 40%;
    background-size: cover;
  }
}

.kanki .mainvisual__bg02 {
  z-index: 3;
  pointer-events: none;
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__bg02 {
    background: url('~assets/images/airPurifier/mv/pure_sp.jpg') no-repeat 65% 50%;
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  .kanki .mainvisual__bg02 {
    background: url('~assets/images/airPurifier/mv/pure.jpg') no-repeat 50% 40%;
    background-size: cover;
  }
}

.kanki .mainvisual__bgwhite {
  z-index: 4;
  pointer-events: none;
  background: rgb(255, 255, 255);
  opacity: 0;
}

.kanki .mainvisual__bg03 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  -ms-flex-align: center;
  -ms-flex-pack: center;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .mainvisual__bg03::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: url('~assets/images/airPurifier/mv/bg.jpg') no-repeat center;
    background-size: cover;
    transform: scale(1.25);
  }
}

.kanki .mainvisual__bg03 > div {
  position: relative;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .mainvisual__bg03 > div {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__bg03 > div {
    min-width: 909px;
  }
}

.kanki .mainvisual__bg03 > div svg {
  width: 100%;
  min-height: 660px;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__bg03 > div svg {
    height: 500px;
    min-height: 0;
  }
}

.kanki .mainvisual__bg03 > div svg .bgimg {
  transform: scale(1.25);
  transform-origin: center;
}

.kanki .mainvisual__bg03 > div > div {
  position: absolute;
  top: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(70.625% + 1px);
  min-width: 848px;
  height: 100%;
  overflow: hidden;
  -ms-flex-align: center;
  -ms-flex-pack: start;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__bg03 > div > div {
    min-width: 643px;
  }
}

.kanki .mainvisual__fresh {
  left: 0;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__fresh--pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .kanki .mainvisual__fresh--sp {
    display: none !important;
  }
}

.kanki .mainvisual__pure {
  right: 0;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__pure--pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .kanki .mainvisual__pure--sp {
    display: none !important;
  }
}

.kanki .mainvisual__title {
  position: absolute;
  top: 120px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 216px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__title {
    top: 56px;
    height: 94px;
  }
}

.kanki .mainvisual__title h1 {
  font-size: 90px;
  line-height: 94px;
  color: rgb(255, 255, 255);
  text-align: center;
  white-space: nowrap;
  opacity: 0;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .mainvisual__title h1 {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  }
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__title h1 {
    font-size: 45px;
    line-height: 47px;
  }
}

@media screen and (max-width: 374px) {
  .kanki .mainvisual__title h1 {
    font-size: 36px;
  }
}

.kanki .mainvisual__title h1 span {
  display: inline-block;
  font-weight: 300;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .mainvisual__title h1 span {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  }
}

.kanki .mainvisual__title h1 span.bold {
  font-size: 108px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__title h1 span.bold {
    font-size: 60px;
  }
}

@media screen and (max-width: 374px) {
  .kanki .mainvisual__title h1 span.bold {
    font-size: 48px;
  }
}

.kanki .mainvisual__title h1 span.ex {
  font-size: 108px;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__title h1 span.ex {
    font-size: 60px;
  }
}

@media screen and (max-width: 374px) {
  .kanki .mainvisual__title h1 span.ex {
    font-size: 48px;
  }
}

.kanki .mainvisual__text01,
.kanki .mainvisual__text02 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 120px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__text01,
  .kanki .mainvisual__text02 {
    height: 60px;
  }
}

.kanki .mainvisual__text01 p,
.kanki .mainvisual__text02 p {
  font-size: 108px;
  line-height: 120px;
  color: rgb(255, 255, 255);
  text-align: center;
  white-space: nowrap;
  opacity: 0;
}

@media screen and (max-width: 960px) {
  .kanki .mainvisual__text01 p,
  .kanki .mainvisual__text02 p {
    font-size: 90px;
  }
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__text01 p,
  .kanki .mainvisual__text02 p {
    font-size: 45px;
    line-height: 60px;
  }
}

@media screen and (max-width: 374px) {
  .kanki .mainvisual__text01 p,
  .kanki .mainvisual__text02 p {
    font-size: 36px;
  }
}

.kanki .mainvisual__text01 p span,
.kanki .mainvisual__text02 p span {
  display: inline-block;
  font-weight: 300;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .mainvisual__text01 p span,
  .kanki .mainvisual__text02 p span {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  }
}

.kanki .mainvisual__text01 p span.bold,
.kanki .mainvisual__text02 p span.bold {
  font-weight: 600;
}

.kanki .mainvisual__text03,
.kanki .mainvisual__text04 {
  position: absolute;
  z-index: 35;
}

.kanki .mainvisual__text03 p,
.kanki .mainvisual__text04 p {
  font-size: 60px;
  line-height: 70px;
  color: rgb(255, 255, 255);
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__text03 p,
  .kanki .mainvisual__text04 p {
    font-size: 36px;
    line-height: 40px;
  }
}

.kanki .mainvisual__text03 p strong,
.kanki .mainvisual__text04 p strong {
  font-weight: 600;
}

.kanki .mainvisual__text03 {
  top: 35px;
  left: 40px;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__text03 {
    top: 17px;
    left: 20px;
  }
}

.kanki .mainvisual__text03 p {
  transform: translateX(-20px);
}

.kanki .mainvisual__text04 {
  right: 40px;
  bottom: 35px;
}

@media screen and (max-width: 767px) {
  .kanki .mainvisual__text04 {
    right: 20px;
    bottom: 17px;
  }
}

.kanki .mainvisual__text04 p {
  transform: translateX(20px);
}

.kanki .mainvisual__bg01,
.kanki .mainvisual__bg02,
.kanki .mainvisual__bg03,
.kanki .mainvisual__bgwhite {
  opacity: 0;
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bg01,
.kanki .mainvisual[data-scene='start'] .mainvisual__bg02 {
  transform: scale(1.2);
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bg01,
.kanki .mainvisual[data-scene='start'] .mainvisual__bg02,
.kanki .mainvisual[data-scene='start'] .mainvisual__bg03,
.kanki .mainvisual[data-scene='start'] .mainvisual__bgwhite {
  opacity: 1;
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bg01 {
  transition: transform 6s linear 0.65s, opacity 2s linear 0.65s;
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bg02 {
  transition: transform 6s linear 5.9s, opacity 0.75s linear 5.9s;
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bgwhite {
  transition: opacity 1.4s ease 10.5s;
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bg03 {
  transition: opacity 2s ease 11.9s;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .mainvisual[data-scene='start'] .mainvisual__bg03::before {
    transition: transform 12s ease 11.9s;
    transform: scale(1);
  }
}

.kanki .mainvisual[data-scene='start'] .mainvisual__bg03 > div svg .bgimg {
  transition: transform 12s ease 11.9s;
  transform: scale(1);
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p {
  opacity: 1;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span {
  opacity: 0;
  animation: letter 1.3s ease 0s 1 forwards;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(1) {
  animation-delay: 1.7s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(2) {
  animation-delay: 1.75s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(3) {
  animation-delay: 1.8s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(4) {
  animation-delay: 1.85s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(5) {
  animation-delay: 1.9s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(6) {
  animation-delay: 1.95s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(7) {
  animation-delay: 2s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(8) {
  animation-delay: 2.05s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(9) {
  animation-delay: 2.1s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(10) {
  animation-delay: 2.15s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(11) {
  animation-delay: 2.2s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(12) {
  animation-delay: 2.25s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(13) {
  animation-delay: 2.3s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(14) {
  animation-delay: 2.35s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(15) {
  animation-delay: 2.4s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(16) {
  animation-delay: 2.45s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(17) {
  animation-delay: 2.5s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(18) {
  animation-delay: 2.55s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(19) {
  animation-delay: 2.6s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text01 p span:nth-of-type(20) {
  animation-delay: 2.65s;
}

.kanki .mainvisual[data-title='1'] .mainvisual__text02 p {
  opacity: 0;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text01 p {
  opacity: 0;
  transition: opacity 0.3s ease 0s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text01 p span {
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p {
  opacity: 1;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span {
  opacity: 0;
  animation: letter 1.3s ease 0s 1 forwards;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(1) {
  animation-delay: 0.8s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(2) {
  animation-delay: 0.85s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(3) {
  animation-delay: 0.9s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(4) {
  animation-delay: 0.95s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(5) {
  animation-delay: 1s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(6) {
  animation-delay: 1.05s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(7) {
  animation-delay: 1.1s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(8) {
  animation-delay: 1.15s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(9) {
  animation-delay: 1.2s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(10) {
  animation-delay: 1.25s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(11) {
  animation-delay: 1.3s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(12) {
  animation-delay: 1.35s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(13) {
  animation-delay: 1.4s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(14) {
  animation-delay: 1.45s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(15) {
  animation-delay: 1.5s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(16) {
  animation-delay: 1.55s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(17) {
  animation-delay: 1.6s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(18) {
  animation-delay: 1.65s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(19) {
  animation-delay: 1.7s;
}

.kanki .mainvisual[data-title='2'] .mainvisual__text02 p span:nth-of-type(20) {
  animation-delay: 1.75s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 {
  opacity: 1;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span {
  opacity: 0;
  animation: letter 1.3s ease 0s 1 forwards;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(1) {
  animation-delay: 1.45s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(2) {
  animation-delay: 1.5s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(3) {
  animation-delay: 1.55s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(4) {
  animation-delay: 1.6s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(5) {
  animation-delay: 1.65s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(6) {
  animation-delay: 1.7s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(7) {
  animation-delay: 1.75s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(8) {
  animation-delay: 1.8s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(9) {
  animation-delay: 1.85s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(10) {
  animation-delay: 1.9s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(11) {
  animation-delay: 1.95s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(12) {
  animation-delay: 2s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(13) {
  animation-delay: 2.05s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(14) {
  animation-delay: 2.1s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(15) {
  animation-delay: 2.15s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(16) {
  animation-delay: 2.2s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(17) {
  animation-delay: 2.25s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(18) {
  animation-delay: 2.3s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(19) {
  animation-delay: 2.35s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__title h1 span:nth-of-type(20) {
  animation-delay: 2.4s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__bg01,
.kanki .mainvisual[data-title='3'] .mainvisual__text01 {
  visibility: hidden !important;
}

.kanki .mainvisual[data-title='3'] .mainvisual__text02 p {
  opacity: 0;
  transition: opacity 0.3s ease 0.1s;
}

.kanki .mainvisual[data-title='3'] .mainvisual__text02 p span {
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.kanki .mainvisual[data-title='3'] .mainvisual__text03 p,
.kanki .mainvisual[data-title='3'] .mainvisual__text04 p {
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  opacity: 1;
  transition: opacity 750ms ease 1.4s, transform 750ms ease 1.4s, text-shadow 0.5s ease 2s;
  transform: translateX(0);
}

@keyframes letter {
  0% {
    opacity: 0;
    transform: translateY(10px) translateZ(0);
  }

  45% {
    text-shadow: 0 0 0 transparent;
    opacity: 1;
    transform: translateY(0) translateZ(1px);
  }

  80% {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

  100% {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

@keyframes shadow {
  45% {
    text-shadow: 0 0 0 transparent;
  }

  80% {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }

  100% {
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  }
}

.kanki .scroll {
  position: fixed;
  right: 0;
  bottom: 20px;
  left: 0;
  z-index: 100;
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 100%;
  transition: all 250ms ease;
}

.kanki .scroll a {
  box-sizing: border-box;
  display: block;
  height: 100%;
  padding: 12px 0 0;
}

.kanki .scroll[data-status='hide'] {
  pointer-events: none;
  opacity: 0;
}

.kanki .scroll img {
  width: 30px;
}

.kanki .scroll span {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 33px;
  margin: 0 auto;
  background: rgb(255, 255, 255);
  animation: scroll 3s cubic-bezier(1, 0, 0, 1) 0s infinite;
}

.kanki .scroll span::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  border-style: solid;
  border-width: 15px 5px 0 0;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(33px);
  }

  50.1% {
    transform: translateY(-60px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.kanki .lead {
  padding: 100px 20px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .kanki .lead {
    padding: 60px 15px;
  }
}

.kanki .lead p {
  max-width: 960px;
  margin: 0 auto 54px;
  font-family: 300;
  font-size: 21px;
  line-height: 54px;
  letter-spacing: 0;
  opacity: 0;
  transform: translateY(20px);
}

.kanki .lead p[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .lead p {
    margin: 0 0 2em;
    font-size: 18px;
    line-height: 2em;
  }
}

.kanki .lead p span {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
}

.kanki .lead p strong {
  font-size: 66px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .kanki .lead p strong {
    display: inline-block;
    margin: 35px 0 0;
    font-size: 48px;
    line-height: 60px;
  }
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(1) {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(2) {
  opacity: 1;
  transition: opacity 1s ease 0.2s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(3) {
  opacity: 1;
  transition: opacity 1s ease 0.4s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(4) {
  opacity: 1;
  transition: opacity 1s ease 0.6s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.6s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(5) {
  opacity: 1;
  transition: opacity 1s ease 0.8s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.8s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(6) {
  opacity: 1;
  transition: opacity 1s ease 1s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(7) {
  opacity: 1;
  transition: opacity 1s ease 1.2s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.2s;
  transform: translateY(0);
}

.kanki .lead p[data-scrollanm='show'] span:nth-of-type(8) {
  opacity: 1;
  transition: opacity 1s ease 1.4s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 1.4s;
  transform: translateY(0);
}

.kanki .lead p:last-child {
  margin: -10px auto 0;
  font-size: 27px;
  line-height: 80px;
}

@media screen and (max-width: 767px) {
  .kanki .lead p:last-child {
    padding: 5px 0 0;
    margin: 0 0 0;
    font-size: 27px;
    line-height: 39px;
  }
}

.kanki .lead p:last-child span {
  font-weight: 600;
}

.kanki .tabs {
  position: relative;
  z-index: 100;
  height: 80px;
  font-family: Poppins, sans-serif;
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .kanki .tabs {
    height: 75px;
  }
}

.kanki .tabs.tablet {
  height: 60px;
}

.kanki .tabs.tablet .tabs__body > li > a {
  height: 60px;
}

.kanki .tabs.tablet .tabs__body > li > a::after {
  top: 60px;
}

.kanki .tabs.tablet .tabs__body > li::after {
  top: 60px;
}

.kanki .tabs[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease;
}

.kanki .tabs[data-scrollanm='hide_after'] {
  opacity: 1;
  transform: none;
}

.kanki .tabs[data-fixed='fixed'] .tabs__body {
  position: fixed;
  top: 107px;
}

.kanki .tabs[data-status='fresh'] .tabs__fresh > a::after {
  border-width: 20px 20px 0 20px;
}

@media screen and (max-width: 767px) {
  .kanki .tabs[data-fixed='fixed'] .tabs__body {
    top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .kanki .tabs[data-status='fresh'] .tabs__fresh > a::after {
    border-width: 15px 15px 0;
  }
}

.kanki .tabs[data-status='fresh'] .tabs__fresh::after {
  border-width: 20px 20px 0 20px;
}

@media screen and (max-width: 767px) {
  .kanki .tabs[data-status='fresh'] .tabs__fresh::after {
    border-width: 15px 15px 0;
  }
}

.kanki .tabs[data-status='pure'] .tabs__pure > a::after {
  border-width: 20px 20px 0 20px;
}

@media screen and (max-width: 767px) {
  .kanki .tabs[data-status='pure'] .tabs__pure > a::after {
    border-width: 15px 15px 0;
  }
}

.kanki .tabs[data-status='pure'] .tabs__pure::after {
  border-width: 20px 20px 0 20px;
}

@media screen and (max-width: 767px) {
  .kanki .tabs[data-status='pure'] .tabs__pure::after {
    border-width: 15px 15px 0;
  }
}

.kanki .tabs__body {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background: rgb(255, 255, 255);
}

.kanki .tabs__body > li {
  position: relative;
  z-index: 1;
  width: 50%;
}

.kanki .tabs__body > li:hover .tabs02 {
  height: 51px;
  pointer-events: auto;
}

.kanki .tabs__body > li:hover .tabs02[data-fixed='fixed'] {
  height: 66px;
}

.kanki .tabs__body > li > a {
  position: relative;
  z-index: 10;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-size: 30px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-decoration: none;
  transition: all 250ms ease;
  -ms-flex-align: center;
  -ms-flex-pack: center;
}

@media screen and (max-width: 767px) {
  .kanki .tabs__body > li > a {
    height: 75px;
    font-size: 21px;
  }
}

.kanki .tabs__body > li > a .hover,
.kanki .tabs__body > li > a:hover {
  opacity: 0.7 !important;
}

.kanki .tabs__body > li > a:hover {
  text-decoration: none;
}

.kanki .tabs__body > li > a::after {
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 10;
  width: 0;
  height: 0;
  margin: 0 auto;
  content: '';
  border-style: solid;
  border-width: 0 30px 0 30px;
  transition: all 250ms ease;
}

@media screen and (max-width: 767px) {
  .kanki .tabs__body > li > a::after {
    top: 75px;
    border-width: 0 15px 0 15px;
  }
}

.kanki .tabs__body > li::after {
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 0;
  margin: 0 auto;
  content: '';
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  border-style: solid;
  border-width: 0 30px 0 30px;
  transition: all 250ms ease;
}

@media screen and (max-width: 767px) {
  .kanki .tabs__body > li::after {
    top: 75px;
    border-width: 0 15px 0 15px;
  }
}

.kanki .tabs__fresh a {
  background: rgb(67, 199, 244);
}

.kanki .tabs__fresh a::after {
  border-color: rgb(67, 199, 244) transparent transparent transparent;
}

.kanki .tabs__pure a {
  background: rgb(0, 160, 227);
}

.kanki .tabs__pure a::after {
  border-color: rgb(0, 160, 227) transparent transparent transparent;
}

.kanki .tabs02 {
  position: absolute;
  top: 100%;
  z-index: 50;
  width: 100%;
  height: 0;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  pointer-events: none;
  transition: height 0.4s ease;
}

@media screen and (max-width: 767px) {
  .kanki .tabs02 {
    display: none;
  }
}

.kanki .tabs02[data-status='purification'] .tabs02__purification a::after {
  border-width: 15px 15px 0;
}

.kanki .tabs02[data-status='purification'] .tabs02__purification::after {
  border-width: 15px 15px 0 15px;
}

.kanki .tabs02[data-status='filters'] .tabs02__filters a::after {
  border-width: 15px 15px 0;
}

.kanki .tabs02[data-status='filters'] .tabs02__filters::after {
  border-width: 15px 15px 0 15px;
}

.kanki .tabs02__body {
  top: 0;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 1px 0 0;
  margin: 0 auto;
  background: rgb(255, 255, 255);
  -ms-flex-pack: justify;
}

@media screen and (max-width: 767px) {
  .kanki .tabs02__body {
    right: 15px;
    left: 15px;
  }
}

.kanki .tabs02__body li {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 50%;
}

@media screen and (max-width: 767px) {
  .kanki .tabs02__body li {
    width: calc(50% - 10px);
  }
}

.kanki .tabs02__body li:first-child {
  border-right: 1px solid rgb(255, 255, 255);
}

.kanki .tabs02__body li a {
  position: relative;
  z-index: 10;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 21px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-decoration: none;
  background: rgb(239, 239, 239);
  background: rgb(0, 160, 227);
  transition: all 250ms ease;
  -ms-flex-align: center;
  -ms-flex-pack: center;
}

@media screen and (max-width: 767px) {
  .kanki .tabs02__body li a {
    height: 40px;
    font-size: 16px;
  }
}

.kanki .tabs02__body li a .hover,
.kanki .tabs02__body li a:hover {
  opacity: 0.7 !important;
}

.kanki .tabs02__body li a:hover {
  text-decoration: none;
}

.kanki .tabs02__body li a::after {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  z-index: 10;
  width: 0;
  height: 0;
  margin: 0 auto;
  content: '';
  border-style: solid;
  border-width: 0 15px 0;
  transition: all 250ms ease;
}

.kanki .tabs02__body li::after {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 0;
  margin: 0 auto;
  content: '';
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  border-style: solid;
  border-width: 0 15px 0 15px;
  transition: all 250ms ease;
}

.kanki .fresh {
  padding: 95px 20px 1px;
  text-align: center;
  background: rgb(247, 248, 248);
}

@media screen and (max-width: 767px) {
  .kanki .fresh {
    padding: 60px 15px 1px;
  }
}

.kanki .fresh__logo {
  margin: 0 auto 10px;
  font-family: Poppins, sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 70px;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__logo {
    font-size: 40px;
    line-height: 50px;
  }
}

.kanki .fresh__logo[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .fresh__title {
  margin: 0 0 35px;
  font-family: Poppins, sans-serif;
  font-size: 109px;
  font-weight: 600;
  line-height: 120px;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__title {
    font-size: 65px;
    line-height: 85px;
  }
}

.kanki .fresh__title[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .fresh__subtitle {
  margin: 0 0 50px;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 600;
  line-height: 50px;
  opacity: 0;
  transform: translateY(20px);
}

.kanki .fresh__subtitle[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__subtitle {
    margin: 0 0 15px;
    font-size: 30px;
    line-height: 2em;
  }
}

.kanki .fresh__bar {
  width: 50px;
  height: 5px;
  margin: 0 auto 45px;
  background: rgb(67, 199, 244);
  opacity: 0;
  transform: translateY(20px);
}

.kanki .fresh__bar[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__bar {
    margin: 0 auto 35px;
  }
}

.kanki .fresh__lead {
  max-width: 960px;
  margin: 0 auto 45px;
  font-size: 18px;
  line-height: 2em;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__lead {
    font-size: 15px;
  }
}

.kanki .fresh__lead[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .fresh__movie {
  width: 100%;
  max-width: 960px;
  height: calc((100vw - 40px) * 0.563);
  max-height: 540px;
  margin: 0 auto 60px;
  opacity: 0;
  transform: translateY(20px);
}

.kanki .fresh__movie[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__movie {
    height: 56.3vw;
  }
}

.kanki .fresh__movie iframe {
  width: 100%;
  height: 100%;
}

.kanki .fresh__ventilation {
  max-width: 960px;
  margin: 0 auto 60px;
}

@media screen and (min-width: 768px) {
  .kanki .fresh__ventilation {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.kanki .fresh__ventilation[data-scrollanm='show'] dt {
  opacity: 1;
  transition: opacity 1s ease 150ms, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 150ms;
  transform: translateY(0);
}

.kanki .fresh__ventilation[data-scrollanm='show'] dd {
  opacity: 1;
  transition: opacity 1s ease 0.3s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .kanki .fresh__ventilation dl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(50% - 10px);
    max-width: 450px;
  }
}

@media screen and (max-width: 767px) {
  .kanki .fresh__ventilation dl {
    margin: 0 0 30px;
  }
}

.kanki .fresh__ventilation dt {
  display: -ms-flexbox;
  display: flex;
  flex-grow: 10;
  align-items: center;
  justify-content: flex-start;
  min-height: 90px;
  margin: 0 0 22px;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  -ms-flex-align: center;
  -ms-flex-positive: 10;
  -ms-flex-pack: start;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__ventilation dt {
    margin: 0 0 15px;
    font-size: 15px;
    line-height: 1.6em;
  }
}

.kanki .fresh__ventilation dt strong {
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__ventilation dt strong {
    font-size: 12px;
  }
}

.kanki .fresh__ventilation dt em {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 0 20px 0 0;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 160, 227);
  border-radius: 100%;
  -ms-flex-align: center;
  -ms-flex-negative: 0;
  -ms-flex-pack: center;
}

.kanki .fresh__ventilation dt em img {
  width: 33px;
}

.kanki .fresh__ventilation dd {
  opacity: 0;
  transform: translateY(20px);
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .fresh__ventilation dd {
    height: 260px;
  }
}

.kanki .fresh__ventilation dd img {
  width: 100%;
}

.kanki .fresh__products {
  position: relative;
  box-sizing: border-box;
  max-width: 960px;
  padding: 75px 20px 75px;
  margin: 0 auto 159px;
  font-family: Poppins, sans-serif;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transform: translateY(20px);
}

.kanki .fresh__products[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products {
    padding: 50px 20px 50px;
    margin: 0 auto 89px;
  }
}

.kanki .fresh__products::after {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  box-sizing: border-box;
  margin: auto;
  pointer-events: none;
  content: '';
  border: 1.5px solid rgb(67, 199, 244);
}

.kanki .fresh__products h3 {
  margin: 0 0 50px;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products h3 {
    font-size: 24px;
    line-height: 1.8em;
  }
}

.kanki .fresh__products h3[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .kanki .fresh__products ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 840px;
    margin: 0 auto;
    -ms-flex-align: start;
    -ms-flex-pack: justify;
  }
}

@media screen and (min-width: 768px) {
  .kanki .fresh__products ul li {
    width: calc(50% - 10px);
    max-width: 400px;
    margin: 0 0 45px;
  }
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products ul li {
    margin: 0 0 30px;
  }
}

.kanki .fresh__products ul li[data-scrollanm='show'] div {
  opacity: 1;
  transition: opacity 1s ease 150ms, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 150ms;
  transform: translateY(0);
}

.kanki .fresh__products ul li[data-scrollanm='show'] p {
  opacity: 1;
  transition: opacity 1s ease 0.3s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  transform: translateY(0);
}

.kanki .fresh__products ul div {
  position: relative;
  height: 280px;
  background: rgb(239, 239, 239);
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products ul div {
    height: 210px;
  }
}

.kanki .fresh__products ul div img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.kanki .fresh__products ul p {
  margin: 5px 0 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products ul p {
    font-size: 15px;
    line-height: 1.8em;
  }
}

.kanki .fresh__products__01 img {
  width: 270px;
}

.kanki .fresh__products__02 img {
  width: 330px;
}

.kanki .fresh__products__03 img {
  width: 330px;
}

.kanki .fresh__products__04 img {
  width: 174px;
}

.kanki .fresh__set {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 770px;
  margin: 0 auto 60px;
  opacity: 0;
  transform: translateY(20px);
  -ms-flex-align: center;
  -ms-flex-pack: justify;
}

.kanki .fresh__set[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__set {
    margin: -25px auto 50px;
  }
}

.kanki .fresh__set div {
  width: 330px;
}

.kanki .fresh__set span {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
}

.kanki .fresh__set span::after,
.kanki .fresh__set span::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin: auto;
  content: '';
  background: rgb(67, 199, 244);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__set span::after,
  .kanki .fresh__set span::before {
    height: 4px;
  }
}

.kanki .fresh__set span::after {
  transform: rotate(90deg);
}

.kanki .fresh .anchor {
  display: block;
  height: 0;
  padding: 200px 0 0;
  margin: -200px 0 0;
  font-size: 0;
  line-height: 0;
}

@media screen and (max-width: 960px) {
  .kanki .fresh .anchor {
    padding: 200px 0 0;
    margin: -200px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .kanki .fresh .anchor {
    padding: 120px 0 0;
    margin: -120px 0 0;
  }
}

.kanki .fresh__products__01 img {
  width: 270px;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products__01 img {
    width: 202.5px;
  }
}

.kanki .fresh__products__02 img {
  width: 330px;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products__02 img {
    width: 247.5px;
  }
}

.kanki .fresh__products__03 img {
  width: 330px;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products__03 img {
    width: 247.5px;
  }
}

.kanki .fresh__products__04 img {
  width: 300px;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__products__04 img {
    width: 225px;
  }
}

.kanki .fresh__comfort {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 840px;
  margin: 0 auto 60px;
  opacity: 0;
  transform: translateY(20px);
  -ms-flex-align: center;
  -ms-flex-pack: justify;
}

.kanki .fresh__comfort[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__comfort {
    -ms-flex-direction: column;
    flex-direction: column;
    margin: -25px auto 50px;
  }
}

.kanki .fresh__comfort > div {
  position: relative;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  width: 43%;
  max-width: 360px;
  padding: 35px 15px 20px;
  font-family: Poppins, sans-serif;
  -ms-flex-pack: center;
  -ms-flex-item-align: stretch;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__comfort > div {
    width: 100%;
    max-width: none;
  }
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .fresh__comfort > div {
    height: 320px;
  }
}

.kanki .fresh__comfort > div h4 {
  position: absolute;
  top: -17px;
  left: 50%;
  padding: 0 10px;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  white-space: nowrap;
  background: rgb(255, 255, 255);
  transform: translateX(-50%);
}

.kanki .fresh__comfort > div div {
  display: -ms-flexbox;
  display: flex;
  flex-grow: 10;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 15px;
  -ms-flex-align: center;
  -ms-flex-positive: 10;
  -ms-flex-pack: center;
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .fresh__comfort > div div {
    height: 192px;
  }
}

.kanki .fresh__comfort > div p {
  font-size: 21px;
  font-weight: 600;
  line-height: 27px;
}

.kanki .fresh__comfort > div p img {
  width: 160px;
  height: 26px;
  margin: 10px 0 0;
}

.kanki .fresh__comfort span {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  -ms-flex-negative: 0;
}

@media screen and (max-width: 767px) {
  .kanki .fresh__comfort span {
    width: 30px;
    height: 30px;
    margin: 15px auto 20px;
  }
}

.kanki .fresh__comfort span::after,
.kanki .fresh__comfort span::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin: auto;
  content: '';
  background: rgb(67, 199, 244);
}

@media screen and (max-width: 767px) {
  .kanki .fresh__comfort span::after,
  .kanki .fresh__comfort span::before {
    height: 4px;
  }
}

.kanki .fresh__comfort span::after {
  transform: rotate(90deg);
}

.kanki .fresh__comfort--01 {
  border: 2px solid rgb(119, 135, 152);
}

.kanki .fresh__comfort--01 h4 {
  color: rgb(254, 16, 120);
}

.kanki .fresh__comfort--01 div img {
  width: 90%;
}

.kanki .fresh__comfort--02 {
  border: 2px solid rgb(67, 199, 244);
}

.kanki .fresh__comfort--02 h4 {
  color: rgb(67, 199, 244);
}

.kanki .fresh__comfort--02 div img {
  width: 81.81%;
}

.kanki .pure {
  padding: 100px 20px 1px;
  text-align: center;
  background: rgb(247, 248, 248);
}

.kanki .pure__logo {
  margin: 0 auto 10px;
  font-family: Poppins, sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 70px;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .pure__logo {
    font-size: 40px;
    line-height: 50px;
  }
}

.kanki .pure__logo[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .pure__title {
  margin: 0 0 35px;
  font-family: Poppins, sans-serif;
  font-size: 109px;
  font-weight: 600;
  line-height: 120px;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .pure__title {
    font-size: 65px;
    line-height: 85px;
  }
}

.kanki .pure__title[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .pure__subtitle {
  margin: 0 0 50px;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 600;
  line-height: 50px;
  opacity: 0;
  transform: translateY(20px);
}

.kanki .pure__subtitle[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .pure__subtitle {
    margin: 0 0 15px;
    font-size: 30px;
    line-height: 2em;
  }
}

.kanki .pure__bar {
  width: 50px;
  height: 5px;
  margin: 0 auto 45px;
  background: rgb(0, 160, 227);
  opacity: 0;
  transform: translateY(20px);
}

.kanki .pure__bar[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .pure__bar {
    margin: 0 auto 35px;
  }
}

.kanki .pure__lead {
  max-width: 960px;
  margin: 0 auto 45px;
  font-size: 18px;
  line-height: 2em;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .pure__lead {
    font-size: 15px;
  }
}

.kanki .pure__lead[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

.kanki .pure__movie {
  width: 100%;
  max-width: 960px;
  height: calc((100vw - 40px) * 0.563);
  max-height: 540px;
  margin: 0 auto 60px;
  opacity: 0;
  transform: translateY(20px);
}

.kanki .pure__movie[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .pure__movie {
    height: 56.3vw;
  }
}

.kanki .pure__movie iframe {
  width: 100%;
  height: 100%;
}

.kanki .pure__purification {
  max-width: 960px;
  margin: 0 auto 60px;
}

@media screen and (min-width: 768px) {
  .kanki .pure__purification {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.kanki .pure__purification[data-scrollanm='show'] dt {
  opacity: 1;
  transition: opacity 1s ease 150ms, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 150ms;
  transform: translateY(0);
}

.kanki .pure__purification[data-scrollanm='show'] dd {
  opacity: 1;
  transition: opacity 1s ease 0.3s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .kanki .pure__purification dl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: calc(50% - 10px);
    max-width: 450px;
  }
}

@media screen and (max-width: 767px) {
  .kanki .pure__purification dl {
    margin: 0 0 30px;
  }
}

.kanki .pure__purification dt {
  display: -ms-flexbox;
  display: flex;
  flex-grow: 10;
  align-items: center;
  justify-content: flex-start;
  min-height: 90px;
  margin: 0 0 22px;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  -ms-flex-align: center;
  -ms-flex-positive: 10;
  -ms-flex-pack: start;
}

@media screen and (max-width: 767px) {
  .kanki .pure__purification dt {
    margin: 0 0 15px;
    font-size: 15px;
    line-height: 1.6em;
  }
}

.kanki .pure__purification dt strong {
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .kanki .pure__purification dt strong {
    font-size: 12px;
  }
}

.kanki .pure__purification dt em {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: 0 20px 0 0;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 160, 227);
  border-radius: 100%;
  -ms-flex-align: center;
  -ms-flex-negative: 0;
  -ms-flex-pack: center;
}

.kanki .pure__purification dt em img {
  width: 33px;
}

.kanki .pure__purification dd {
  opacity: 0;
  transform: translateY(20px);
}

@media all and (-ms-high-contrast: none\0) {
  .kanki .pure__purification dd {
    height: 260px;
  }
}

.kanki .pure__purification dd img {
  width: 100%;
}

.kanki .pure__products {
  position: relative;
  box-sizing: border-box;
  max-width: 960px;
  padding: 75px 20px 75px;
  margin: 0 auto 159px;
  font-family: Poppins, sans-serif;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  opacity: 0;
  transform: translateY(20px);
}

.kanki .pure__products[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .pure__products {
    padding: 50px 20px 50px;
    margin: 0 auto 89px;
  }
}

.kanki .pure__products::after {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: 1;
  box-sizing: border-box;
  margin: auto;
  pointer-events: none;
  content: '';
  border: 1.5px solid rgb(0, 160, 227);
}

.kanki .pure__products h3 {
  margin: 0 0 50px;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .pure__products h3 {
    font-size: 24px;
    line-height: 1.8em;
  }
}

.kanki .pure__products h3[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (min-width: 768px) {
  .kanki .pure__products ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 840px;
    margin: 0 auto;
    -ms-flex-align: start;
    -ms-flex-pack: justify;
  }
}

@media screen and (min-width: 768px) {
  .kanki .pure__products ul li {
    width: calc(50% - 10px);
    max-width: 400px;
    margin: 0 0 45px;
  }
}

@media screen and (max-width: 767px) {
  .kanki .pure__products ul li {
    margin: 0 0 30px;
  }
}

.kanki .pure__products ul li[data-scrollanm='show'] div {
  opacity: 1;
  transition: opacity 1s ease 150ms, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 150ms;
  transform: translateY(0);
}

.kanki .pure__products ul li[data-scrollanm='show'] p {
  opacity: 1;
  transition: opacity 1s ease 0.3s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  transform: translateY(0);
}

.kanki .pure__products ul div {
  position: relative;
  height: 280px;
  background: rgb(239, 239, 239);
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .pure__products ul div {
    height: 210px;
  }
}

.kanki .pure__products ul div img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.kanki .pure__products ul p {
  margin: 5px 0 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  opacity: 0;
  transform: translateY(20px);
}

@media screen and (max-width: 767px) {
  .kanki .pure__products ul p {
    font-size: 15px;
    line-height: 1.8em;
  }
}

.kanki .pure__products__01 img {
  width: 270px;
}

.kanki .pure__products__02 img {
  width: 330px;
}

.kanki .pure__products__03 img {
  width: 330px;
}

.kanki .pure__products__04 img {
  width: 174px;
}

.kanki .pure__set {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 770px;
  margin: 0 auto 60px;
  opacity: 0;
  transform: translateY(20px);
  -ms-flex-align: center;
  -ms-flex-pack: justify;
}

.kanki .pure__set[data-scrollanm='show'] {
  opacity: 1;
  transition: opacity 1s ease 0s, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .kanki .pure__set {
    margin: -25px auto 50px;
  }
}

.kanki .pure__set div {
  width: 330px;
}

.kanki .pure__set span {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
}

.kanki .pure__set span::after,
.kanki .pure__set span::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin: auto;
  content: '';
  background: rgb(67, 199, 244);
}

@media screen and (max-width: 767px) {
  .kanki .pure__set span::after,
  .kanki .pure__set span::before {
    height: 4px;
  }
}

.kanki .pure__set span::after {
  transform: rotate(90deg);
}

.kanki .pure .anchor {
  display: block;
  height: 0;
  padding: 200px 0 0;
  margin: -200px 0 0;
  font-size: 0;
  line-height: 0;
}

@media screen and (max-width: 960px) {
  .kanki .pure .anchor {
    padding: 200px 0 0;
    margin: -200px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .kanki .pure .anchor {
    padding: 120px 0 0;
    margin: -120px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .kanki .pure {
    padding: 60px 15px 1px;
  }
}

.kanki .pure__products ul div.pure__products__01,
.kanki .pure__products ul div.pure__products__02,
.kanki .pure__products ul div.pure__products__05,
.kanki .pure__products ul div.pure__products__06 {
  height: 340px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products ul div.pure__products__01,
  .kanki .pure__products ul div.pure__products__02,
  .kanki .pure__products ul div.pure__products__05,
  .kanki .pure__products ul div.pure__products__06 {
    height: 255px;
  }
}

.kanki .pure__products ul div.pure__products__01 img,
.kanki .pure__products ul div.pure__products__02 img,
.kanki .pure__products ul div.pure__products__03 img {
  top: auto;
  bottom: 20px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products ul div.pure__products__01 img,
  .kanki .pure__products ul div.pure__products__02 img,
  .kanki .pure__products ul div.pure__products__03 img {
    bottom: 15px;
  }
}

.kanki .pure__products__01 img {
  width: 113px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products__01 img {
    width: 84.75px;
  }
}

.kanki .pure__products__02 img {
  width: 113px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products__02 img {
    width: 84.75px;
  }
}

.kanki .pure__products__03 img {
  width: 113px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products__03 img {
    width: 84.75px;
  }
}

.kanki .pure__products__04 img {
  width: 291px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products__04 img {
    width: 218.25px;
  }
}

.kanki .pure__products__05 img {
  width: 248px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products__05 img {
    width: 186px;
  }
}

.kanki .pure__products__06 img {
  width: 222px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__products__06 img {
    width: 166.5px;
  }
}

.kanki .pure__set--01 {
  width: 98.1%;
}

@media screen and (max-width: 767px) {
  .kanki .pure__set--01 {
    width: 88.1%;
  }
}

.kanki .pure__set--02 {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .kanki .pure__set--02 {
    width: 90%;
  }
}

.kanki .pure__filter {
  max-width: 840px;
  margin: 0 auto 60px;
}

.kanki .pure__filter img {
  width: 100%;
}

.kanki .pure__lead {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .kanki .pure__lead {
    margin-bottom: 45px;
  }
}

.pagetop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 100;
  width: 60px;
  height: 60px;
  overflow: hidden;
  transition: transform 0.3s ease;
  transform: translateY(100px);
}

@media screen and (max-width: 767px) {
  .pagetop {
    display: none;
  }
}

.pagetop[data-status='show'] {
  transform: translateY(0);
}

.pagetop a {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 0;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 100%;
}

.pagetop a span {
  position: absolute;
  top: 12.5px;
  right: 0;
  left: 0;
  width: 1px;
  height: 35px;
  margin: 0 auto;
  background: rgb(255, 255, 255);
}

.pagetop a span::after {
  position: absolute;
  top: -1px;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border-color: transparent transparent rgb(255, 255, 255) transparent;
  border-style: solid;
  border-width: 0 5px 15px 0;
}

.pagetop a:hover span {
  animation: pagetop 3s cubic-bezier(1, 0, 0, 1) 0s infinite;
}

@keyframes pagetop {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-48px);
  }

  30.1% {
    transform: translateY(60px);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}
