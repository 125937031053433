.m-heatpumpline {
  $root: &;
  width: 100%;
  padding: rem(2.75) rem(1) rem(1.71875) rem(1.5);
  background-color: $COLOR-PALE-GREY;

  @include tabFixedContent {
    padding-left: 0;
  }

  &_field {
    position: relative;

    @include tabFixedContent {
      display: flex;
    }

    &:before {
      position: absolute;
      bottom: -1px;
      left: 20px;
      height: 100%;
      content: '';
      border-right: solid 4px $COLOR-CERULEAN;

      @include tabFixedContent {
        left: 4px;
        width: 419px;
      }
    }

    &:not(:last-child) {
      padding-bottom: rem(2);
    }
  }

  &_wrapimage {
    position: relative;
    display: flex;

    @include tabFixedContent {
      justify-content: space-between;
      width: 448px;
    }
  }

  &_image {
    order: 2;
    max-width: 179px;
    padding: 0 0 rem(1) rem(2);
    @include u-fw-b;

    @include tabFixedContent {
      order: inherit;
      max-width: 269px;
      padding: 0;
    }
  }

  &_index {
    position: relative;
    z-index: z('heatpumpline', 'index');
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-top: rem(0.625);
    font-size: 20px;
    color: $COLOR-WHITE;

    @include tabFixedContent {
      width: 54px;
      height: 54px;
      font-size: rem(2);
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: z('heatpumpline', 'indexbefore');
      width: 100%;
      height: 100%;
      content: '';
      background-color: $COLOR-CERULEAN;
      transform: rotate(45deg);
    }
  }

  &_content {
    padding-left: rem(5);

    @include tabFixedContent {
      width: calc(100% - 448px);
      padding-left: rem(2.5);
      margin-top: rem(1.75);
    }
  }
}
