.a-phonefield {
  >input {
    width: 100%;
    height: rem(3);
    padding: rem(0.5) rem(1);
    font-size: rem(1);
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-GREYISH;
    border-radius: 6px;
    outline: none;
    appearance: none;

    &:focus {
      border-color: $COLOR-CERULEAN;
    }
  }

  &_errormessage {
    margin: rem(0.5625) rem(0.75) 0;
    font-size: rem(0.75);
    color: $COLOR-CHERRY;
  }

  &-error {
    >input {

      &,
      &:focus {
        border-color: $COLOR-CHERRY;
      }
    }
  }
}