.a-switch {
  $root: &;
  $animation: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  > span {
    position: relative;
    display: inline-flex;
    min-width: 42px;
    height: 20px;
    margin: 2px;
    background-color: rgba($COLOR-BLACK, 0.25);
    border-radius: 15px;
    transition: all $animation;

    &:before {
      position: absolute;
      top: -2px;
      width: 24px;
      height: 24px;
      content: '';
      background-color: $COLOR-WHITE;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba($COLOR-BLACK, 0.13);
      transition: all $animation;
      transform: translateX(-2px);
    }
  }

  > div {
    margin-right: rem(1.5);
  }

  > input {
    position: absolute;
    pointer-events: none;
    opacity: 0;

    &:checked ~ span {
      background-color: $COLOR-CERULEAN;
      &:before {
        transform: translateX(20px);
      }
    }
  }
}
