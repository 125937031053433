.p-solution_tab {
  @include sp {
    display: none;
  }
}

.m-dropdownSolution {
  $root: &;
  position: relative;
  border: 2px solid #dddddd;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 28px;

  &_mobile {
    @include pc {
      display: none;
    }
  }

  &_header {
    align-items: center;
    background: #F6F6F6;

    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    z-index: 1;
    padding: 4px 16px 4px 12px;
    color: #0097e0;

    #{$root}-sm & {
      max-height: 48px;
    }

    #{$root}-md & {
      max-height: 55px;
    }

    #{$root}-lg & {
      max-height: 60px;
    }

    &_content {
      align-items: center;
      display: flex;
      flex-grow: 1;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      #{$root}-sm & {
        padding: 16px 24px 16px;
      }

      #{$root}-md & {
        padding: 16px 24px 16px;
      }

      #{$root}-lg & {
        padding: 22px 24px;
      }

      span {
        @include text-overflow(1);
      }
    }
  }

  &-disabled {
    #{$root}_header {
      cursor: auto;
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &_placeholder {
    #{$root}-normal & {
      // color: $outer-space;
      // @include font-base(16, 24);
    }

    #{$root}-sm & {
      // @include font-base(16, 22);
    }
  }

  &_fn {
    align-items: center;
    display: flex;
    flex-shrink: 0;
  }

  &_arrow {
    display: block;
    height: 20px;
    position: relative;
    width: 20px;
    // background-color: $isabelline;
    border-radius: 5px;

    &::after {
      background-position: center !important;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.25s;
      width: 100%;

      #{$root}-normal & {
        background: url('~assets/images/icons/arrow-black-down.svg') no-repeat;
      }
    }

    &.opened {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &_clear {
    margin-right: 3px;
    padding: 6px;
    position: relative;

    &::after {
      // background: $dim-gray;
      content: '';
      height: 14px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      margin-right: 3px;
    }
  }

  &_wrapper {
    background: white;
    width: 100%;
    z-index: 2;
  }

  &_list {
    // border: 1px solid $languid-lavender;
    // color: $dim-gray;
    margin: 0;
    max-height: 161px;
    overflow-y: auto;
    padding: 0;
    // @include font-base(17, 23);

    #{$root}-normal & {
      // @include scroll-bars(4, $languid-lavender, transparent, 4, 2);
    }
  }

  &_item {
    position: relative;
    cursor: pointer;
    list-style: none;
    padding: 0 12px;
    // transition: all $transition linear;
    white-space: normal;
    color: #0097e0;
    background-color: #F6F6F6;

    &-wrap {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 0;
      border-top: 2px solid #dadada;
    }

    .a-toggleicon {
      margin-right: 10.5px;
    }

    &:hover {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgba($color: white, $alpha: 0.6);
      }
    }

    &.none {
      // color: $dim-gray;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: initial;
    }
  }

  &-error {
    #{$root}_header {
      // border-color: $sinopia;
    }

    #{$root}_error {
      // @include font-base(12, 16);
      // color: $sinopia;
      margin-top: 4px;
      opacity: 0.8;
    }
  }
}