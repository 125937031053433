.m-table {
  $root: &;

  position: relative;
  display: block;
  width: 100%;
  margin: auto;
  overflow-x: auto;
  overflow-y: hidden;

  &_table {
    table-layout: fixed;
  }

  &_th,
  &_td {
    position: relative;
    padding: rem(4 / 14) rem(4.8 / 14);
    font-size: rem(1);
    text-align: left;
    border: solid 1px $COLOR-WHITE-THREE;

    &-fixleft {
      position: sticky;
      left: -1px;
      z-index: z('table', 'fixed');
    }
  }

  &_th {
    color: $COLOR-WHITE;
    background-color: $COLOR-CERULEAN;
    @include u-fw-b;

    &-bgwhite {
      color: $COLOR-BLACK;
      background-color: $COLOR-WHITE;
    }

    &-comparison {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &-bordertransparent {
      border-color: $COLOR-UTILITY-TRANSPARENT;
    }
  }

  &_td {
    &-nobordertop {
      border-top: 0;
    }

    &-noborderbottom {
      border-bottom: 0;
    }

    .m-textlist li {
      font-size: rem(1);
    }

    &-verticalaligntop {
      vertical-align: top;
    }

    &-fixleft {
      background-color: $COLOR-WHITE-FOUR;
    }
  }

  &_th-center,
  &_td-center {
    text-align: center;
  }
}
