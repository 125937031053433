.o-sidebar {
  $root: &;
  $animation: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  position: relative;
  width: rem(19.5);
  height: auto;
  padding: rem(4) rem(1) rem(1) rem(1);
  overflow-y: auto;
  background-color: $COLOR-WHITE-FOUR;

  @include spMenu() {
    width: 100%;
    height: 0;
    padding: 0;
    background-color: $COLOR-UTILITY-TRANSPARENT;
  }

  &_wraplist {
    @include spMenu {
      position: fixed;
      top: 100px;
      left: 0;
      z-index: z('sidebar', 'base');
      width: 100%;
      height: 0;
      overflow: hidden;
      background-color: $COLOR-WHITE-FOUR;
      transition: height 0.5s;
    }

    #{$root}-open & {
      @include spMenu {
        height: calc(100vh - 100px);
        overflow-y: auto;
        transition: height 0.5s;
      }
    }
  }

  &_groupname {
    @include spMenu {
      display: none;
    }
  }

  &_item {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(1) 0;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: rem(-1);
      width: 4px;
      height: 0;
      content: '';
      background-color: $COLOR-CERULEAN;
      transition: all $animation;
      transform: translateY(-50%);
    }

    @include spMenu {
      margin: 0 rem(1);
    }

    &:first-child {
      @include spMenu {
        margin-top: rem(1);
      }
    }

    .a-text {
      font-size: rem(1.25);
      transition: color $animation;
    }

    .a-toggleicon {
      flex: 0 0 auto;
      margin-right: rem(1);

      &_icon {
        transition: all $animation;
      }
    }

    &-active,
    &:hover {
      .a-text {
        color: $COLOR-CERULEAN;
      }

      .a-toggleicon_icon:first-of-type {
        visibility: hidden;
        opacity: 0;
      }

      .a-toggleicon_icon:nth-of-type(2) {
        visibility: inherit;
        opacity: 1;
      }
    }

    &-active {
      &:before {
        height: 100%;
      }
    }
  }

  &_mobile {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: z('sidebar', 'base');
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 rem(0.75);
    cursor: pointer;
    background: $COLOR-LIGHT-BLUE-TWO;

    @include spMenu {
      display: flex;
    }

    .a-text {
      margin-bottom: 0;
      font-size: rem(1.5);
      @include text-overflow(1);
    }

    .a-icon {
      flex: 0 0 auto;
      transition: transform $animation;
    }

    #{$root}-open & {
      .a-icon {
        transform: rotateX(-180deg);
      }
    }
  }
}
