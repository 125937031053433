.m-technologystreamer {
  position: relative;

  &_wrapimage {
    @include pc {
      display: flex;
      flex-wrap: wrap;
      margin: 0 rem(-39/14);
    }
  }

  &_image {
    @include pc {
      flex: 50%;
      max-width: 50%;
      padding: 0 rem(39/14);
    }

    @include sp {
      margin-bottom: rem(37/14);
    }

    & + & {
      @include sp {
        margin-top: rem(37/14);
        margin-bottom: 0;
      }
    }
  }

  &_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: z('technologystreamer', 'technologystreamericon');
    line-height: 1;
    transform: translate(-50%, -50%);

    .a-icon {
      width: rem(21/14);
      height: rem(21/14);

      @include pc {
        width: rem(25/7);
        height: rem(25/7);
      }

      @include tab {
        width: rem(2.5);
        height: rem(2.5);
      }
    }
  }
}
