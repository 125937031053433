.m-servicepanel {
  $animation: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  position: relative;
  padding: rem(5.25) rem(2.5) rem(1.875) rem(2.5);
  background: $COLOR-UTILITY-GRD-BTT-ZTN, url('~assets/images/common/service-banner.jpg') center center/cover no-repeat;
  border-radius: 3px;

  &_title {
    .m-heading_body {
      margin-bottom: rem(1);
    }
  }

  &_description {
    margin-bottom: rem(2.1875);
    font-size: rem(1);
    color: $COLOR-WHITE;
  }

  &_services {
    display: flex;
    flex-wrap: wrap;
  }

  &_service {
    display: flex;
    align-items: center;
    width: 100%;

    @include pc {
      width: calc(100% / 3);
    }

    @include tab {
      width: 50%;
    }

    &:nth-child(n + 4) {
      @include pc {
        margin-top: rem(1);
      }
    }

    &:nth-child(n + 3) {
      @include tab {
        margin-top: rem(1);
      }
    }

    &:not(:first-child) {
      @include sp {
        margin-top: rem(1);
      }
    }

    &:hover {
      .a-text {
        color: $COLOR-CERULEAN;
      }

      .a-toggleicon_icon:nth-of-type(1) {
        visibility: hidden;
        opacity: 0;
      }

      .a-toggleicon_icon:nth-of-type(2) {
        visibility: inherit;
        opacity: 1;
      }
    }

    .a-text {
      margin-left: rem(0.5);
      transition: color $animation;
    }

    .a-toggleicon_icon {
      transition: all $animation;
    }
  }
}
