.m-cardfactory {
  $root: &;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &_wrap {
    #{$root}-typeA &,
    #{$root}-typeB & {
      position: relative;
      z-index: 1;
      width: calc((100%) - (780 / 1320 * 100%));
      padding: rem(4.75) rem(3.75);
    }

    #{$root}-typeA & {
      background-color: $COLOR-WHITE-FOUR;
    }

    #{$root}-typeB & {
      background-color: $COLOR-LIGHT-BLUE;
    }

    #{$root}-typeC &,
    #{$root}-typeG & {
      position: relative;
      z-index: 1;
      width: calc(100% - #{rem(1.875)});
      max-width: rem(27.875);
      margin: 0 auto 0 auto;
      background: $COLOR-WHITE-FOUR;
    }

    #{$root}-typeC & {
      padding: rem(2);
      margin-top: rem(-6.3125);
    }

    #{$root}-typeG & {
      padding: rem(1.25) rem(1.25) rem(2) rem(1.25);
      margin-top: rem(-1.5);
    }

    #{$root}-typeD &,
    #{$root}-typeE & {
      position: relative;
      z-index: 1;
      width: calc((100%) - (648 / 1320 * 100%));
      padding: rem(2);
      background-color: $COLOR-LIGHT-BLUE;
    }

    #{$root}-typeD & {
      max-width: rem(27.875);
      margin: rem(3.25) 0 0 rem(-5);
    }

    #{$root}-typeE & {
      align-self: flex-end;
      max-width: rem(33.5);
      margin: 0 rem(-5) rem(3.25) 0;
    }

    #{$root}-typeF & {
      position: relative;
      width: calc((100%) - (606 / 1320 * 100%));
      min-height: rem(25.25);
      padding: rem(4.625) rem(9.5);
      margin-top: rem(3.125);
      background-color: $COLOR-WHITE-FOUR;
    }

    #{$root}-typeA &,
    #{$root}-typeB &,
    #{$root}-typeC &,
    #{$root}-typeD &,
    #{$root}-typeE &,
    #{$root}-typeF &,
    #{$root}-typeG & {
      @include spMenu {
        z-index: 2;
        width: calc(100% - #{rem(1.875)});
        max-width: 100%;
        min-height: auto;
        padding: rem(0.75) rem(0.75) rem(1.5) rem(0.75);
        margin: rem(-2.8125) auto 0 auto;
        background-color: $COLOR-WHITE-FOUR;
      }
    }
  }

  &_title {
    font-size: rem(1.5);
    color: $COLOR-SLATE-BLUE;
    @include u-fw-m;

    #{$root}-typeA &,
    #{$root}-typeB & {
      font-size: rem(2);
    }

    #{$root}-typeG & {
      font-size: rem(1.25);
      text-align: center;

      @include spMenu {
        text-align: left;
      }
    }

    #{$root}-typeF & {
      max-width: rem(25);

      @include spMenu {
        max-width: 100%;
      }
    }

    #{$root}-typeA &,
    #{$root}-typeB &,
    #{$root}-typeC &,
    #{$root}-typeD &,
    #{$root}-typeE &,
    #{$root}-typeF &,
    #{$root}-typeG & {
      @include spMenu {
        width: 100%;
        margin-bottom: 0;
        font-size: rem(1);
      }
    }

    ul {
      list-style-type: none;
    }
  }

  &_description {
    font-size: rem(1);
    color: $COLOR-SLATE-BLUE;
    @include u-fw-m;

    #{$root}-typeF & {
      max-width: rem(25);
      margin-top: rem(1.6875);

      @include spMenu {
        max-width: 100%;
        margin-top: 12px;
      }
    }
  }

  &_index {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(2.5);
    height: rem(2.5);
    font-size: rem(1.5);
    color: $COLOR-WHITE;
    background-color: $COLOR-CERULEAN;
    @include u-fw-m;

    #{$root}-typeA &,
    #{$root}-typeB & {
      top: rem(-1.25);
    }

    #{$root}-typeC &,
    #{$root}-typeG & {
      right: rem(5);
      bottom: rem(-1.25);
    }

    #{$root}-typeG & {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    #{$root}-typeD &,
    #{$root}-typeE & {
      top: rem(-1.25);
    }

    #{$root}-typeD & {
      right: rem(5);
    }

    #{$root}-typeE & {
      right: auto;
      left: rem(5);
    }

    #{$root}-typeF & {
      top: rem(-1.25);
      right: auto;
      left: rem(9.5);
    }

    #{$root}-typeA &,
    #{$root}-typeB &,
    #{$root}-typeC &,
    #{$root}-typeD &,
    #{$root}-typeE &,
    #{$root}-typeF &,
    #{$root}-typeG & {
      @include spMenu {
        top: auto;
        bottom: rem(-0.75);
        left: 50%;
        width: rem(1.5);
        height: rem(1.5);
        font-size: rem(1);
        transform: translateX(-50%);
      }
    }
  }

  &_image {
    #{$root}-typeA &,
    #{$root}-typeB & {
      position: relative;
      z-index: 1;
      width: calc(780 / 1320 * 100%);
      overflow: hidden;
    }

    #{$root}-typeB &::after {
      border-bottom-color: $COLOR-LIGHT-BLUE;
    }

    #{$root}-typeC &,
    #{$root}-typeG & {
      order: -1;
      width: 100%;
    }

    #{$root}-typeD &,
    #{$root}-typeE & {
      width: calc(648 / 1320 * 100%);
    }

    #{$root}-typeD & {
      order: -1;
    }

    #{$root}-typeE & {
      order: inherit;
    }

    #{$root}-typeF & {
      position: relative;
      order: 1;
      width: calc(606 / 1320 * 100%);
      margin-left: -10%;
    }

    #{$root}-typeA &,
    #{$root}-typeB &,
    #{$root}-typeC &,
    #{$root}-typeD &,
    #{$root}-typeE &,
    #{$root}-typeF &,
    #{$root}-typeG & {
      @include spMenu {
        order: -1;
        width: 100%;
        margin-left: 0;
      }
    }
  }

  &-typeA,
  &-typeB {
    align-items: center;
    padding: rem(2) 0;

    @include spMenu {
      padding: 0;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 40%;
      z-index: 1;
      width: rem(21);
      height: 100%;
      content: '';
      background-color: $COLOR-CERULEAN;
      transform: translateX(-40%);

      @include spMenu {
        display: none;
      }
    }
  }

  &-typeD,
  &-typeE {
    align-items: flex-start;
  }

  &-typeE {
    justify-content: flex-end;
  }

  &-typeF {
    flex-wrap: inherit;
    align-items: flex-start;

    @include spMenu {
      flex-wrap: wrap;
    }
  }
}
