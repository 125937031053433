.a-loading {
  $root: &;
  $animation: dot-flashing 1s infinite alternate;

  position: relative;
  min-height: 50vh;

  &_flexWrapper {
    display: flex;
    justify-content: center;
  }

  &_spin {
    width: 50px;
    height: 50px;
    border: 8px solid rgb(243, 243, 243);
    border-top: 8px solid $COLOR-BROWNISH-GREY;
    border-radius: 50%;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }

  &_loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    height: 8px;
    margin: auto;
    color: $COLOR-BROWNISH-GREY;
    background-color: $COLOR-BROWNISH-GREY;
    border-radius: 5px;
    animation: $animation linear;
    animation-delay: 0.5s;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      display: inline-block;
      content: '';
    }

    &::before {
      left: -15px;
      width: 8px;
      height: 8px;
      color: $COLOR-BROWNISH-GREY;
      background-color: $COLOR-BROWNISH-GREY;
      border-radius: 5px;
      animation: $animation;
      animation-delay: 0s;
    }

    &::after {
      left: 15px;
      width: 8px;
      height: 8px;
      color: $COLOR-BROWNISH-GREY;
      background-color: $COLOR-BROWNISH-GREY;
      border-radius: 5px;
      animation: $animation;
      animation-delay: 1s;
    }

    #{$root}-nonfixed & {
      position: unset;
      margin: 0;
    }
  }

  &-nonfixed {
    min-height: auto;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: $COLOR-BROWNISH-GREY;
  }

  50%,
  100% {
    background-color: $COLOR-WHITE-TWO;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
