.o-comparisonbar {
  $root: &;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  position: fixed;
  bottom: 0;
  z-index: z('comparisonbar', 'fixed');
  width: 100%;
  padding: rem(2.875) 0 rem(3.1875) 0;
  background-color: $COLOR-UTILITY-COMPARISONBAR;
  border: solid 1px $COLOR-UTILITY-COMPARISONBAR;
  transition: transform 0.4s $animation;

  @include sp {
    padding: rem(1.5625) 0 rem(1) 0;
    background-color: $COLOR-WARM-GREY-TWO;
  }

  &_collapse {
    position: absolute;
    top: -12px;
    cursor: pointer;
    transform: rotate(180deg);

    #{$root}-collapsed & {
      top: -15px;
      transform: rotate(0);
    }
  }

  &_product {
    padding-top: rem(1.625);
    @include sp {
      padding-top: rem(1);
    }

    @include tabFixedContent {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_wraphead {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: rem(1);

    @include pc {
      margin-top: 0;
    }

    @include sp {
      justify-content: space-between;
    }
  }

  &_text {
    padding-right: rem(1.625);
    font-size: rem(1);
    cursor: pointer;

    .a-text {
      transition: color 0.4s $animation;
    }

    &:hover {
      .a-text {
        color: $COLOR-CERULEAN;
      }
    }
  }

  &_item {
    position: relative;
    display: flex;

    & + & {
      margin-top: rem(1);
    }

    @include tabFixedContent {
      flex-wrap: wrap;
      width: calc(100% / 3);

      & + & {
        padding-left: rem(1.5);
        margin-top: 0;
      }
    }
  }

  &_wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    background-color: $COLOR-WHITE;
  }

  &_add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: calc(29 / 424 * 100%);
    padding-bottom: calc(28 / 424 * 100%);
    cursor: pointer;
    background-color: $COLOR-BLACK-TWO;
    transition: opacity 0.4s $animation;

    &:hover {
      opacity: 0.9;
    }
  }

  &_image {
    width: calc(192 / 424 * 100%);
  }

  &_wrapcontent {
    width: calc((100%) - (192 / 424 * 100%));
    padding: rem(2) rem(1);

    @include sp {
      padding: rem(1);
    }
  }

  &_title {
    padding-bottom: rem(0.5625);
    .a-text {
      @include sp {
        font-size: rem(1);
      }
    }
  }

  &_description .a-text {
    @include sp {
      font-size: rem(0.625);
    }
  }

  &_close {
    position: absolute;
    top: rem(0.5);
    right: rem(0.5);
    cursor: pointer;
    transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    .a-icon {
      display: block;
      @include sp {
        width: rem(0.8125);
        height: rem(0.8125);
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &-collapsed {
    transform: translateY(calc(100% - 24px));
  }

  .a-button {
    min-width: rem(8.5625);
  }

  .m-heading_body {
    margin: 0;
    text-align: left;
  }
}
