.o-header {
  $root: &;
  transition: all ease-in-out 0.05s;

  &_wraplogo {
    display: none;

    @include spMenu {
      position: fixed;
      z-index: z('header', 'logo');
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      padding: 0 15px;
      background-color: $COLOR-WHITE;
      // TODO: Replace with color variable
      box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
    }

    // NOTE: For use only in the Productdetail component
    &-withanchorlinkpanel {
      @include spMenu {
        border-bottom: 1px solid rgb(238, 238, 238);
        box-shadow: none;
      }
    }

    .a-icon {
      width: rem(1.71);
      height: rem(1.71);
      cursor: pointer;
    }
  }

  &_hamburger {
    position: relative;
    width: 24px;
    height: 12px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    transform: rotate(0deg);

    span {
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background: $COLOR-CERULEAN;
      border-radius: 9px;
      opacity: 1;
      transition: 0.25s ease-in-out;
      transform: rotate(0deg);

      &:nth-child(1) {
        top: 0px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 5px;
      }

      &:nth-child(4) {
        top: 10px;
      }
    }

    &-open span {

      &:nth-child(1),
      &:nth-child(4) {
        top: 10px;
        left: 50%;
        width: 0%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  &_wrapcontent {
    position: fixed;
    z-index: z('header', 'menubar');
    width: 400px;

    @include tabFixedContent {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      background-color: $COLOR-WHITE;
    }

    @include spMenu {
      top: 50px;
      height: calc(100vh - 50px);
      padding-top: rem(0.29);
      overflow-y: auto;
      background-color: $COLOR-BLACK;
      transition: transform 0.5s;
      transform: translateX(-100%);
    }

    @include sp {
      width: 100%;
    }

    &-open {
      @include spMenu {
        transition: transform 0.5s;
        transform: translateX(0);
      }
    }

    .m-menu_title {
      @include spMenu {
        padding-top: rem(2.86);
        font-size: rem(1.14);
      }
    }

    .m-menu_submenuitem>a,
    .m-menu_menuitem:not(.m-menu_menuitem-active)>.m-menu_title {
      @include spMenu {
        color: $COLOR-WHITE;
      }
    }

    .m-menu_submenuitem>a:hover,
    .m-menu_menuitem:hover .m-menu_title {
      @include spMenu {
        color: $COLOR-CERULEAN;
      }
    }

    .m-menu_menuitem-haschildren.m-menu_menuitem-nonarrow:not(.m-menu_menuitem-active) .m-menu_title {

      &::before,
      &::after {
        @include spMenu {
          background-color: $COLOR-WHITE;
        }
      }
    }

    .m-menu_menuitem.m-menu_menuitem-haschildren.m-menu_menuitem-nonarrow:hover .m-menu_title {

      &::before,
      &::after {
        @include spMenu {
          background-color: $COLOR-CERULEAN;
        }
      }
    }

    .m-menu_menuitem-haschildren:not(.m-menu_menuitem-nonarrow) .m-menu_title::after {
      @include spMenu {
        border-top-color: $COLOR-WHITE;
      }
    }

    .m-menu_menuitem-active:not(.m-menu_menuitem-nonarrow) .m-menu_title::after,
    .m-menu_menuitem-haschildren:not(.m-menu_menuitem-nonarrow):hover .m-menu_title::after {
      @include spMenu {
        border-top-color: $COLOR-CERULEAN;
      }
    }
  }

  &_logo {
    display: none;

    .a-image {
      width: 114px;
      height: 24px;

      @include tabFixedContent {
        width: 174px;
        height: 37px;
      }
    }

    @include tabFixedContent {
      display: block;
    }

    #{$root}_wraplogo & {
      @include spMenu {
        display: block;
      }
    }
  }

  &_wraplang {
    padding-top: rem(2.86);
    margin-top: rem(2.5);
    border-top: 1px solid $COLOR-UTILITY-BD-SUBMENU-SP;

    @include tabFixedContent {
      display: block;
      padding-top: 0;
      padding-left: 16px;
      margin-top: 0;
      border-top: 0;
    }
  }

  &_top {
    @include tabFixedContent {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 62px;
    }

    @include spMenu {
      padding-bottom: 30px;
    }
  }

  &_menutop {
    @include tabFixedContent {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &_bottom {
    width: 100%;

    @include tabFixedContent {
      display: flex;
      align-items: center;
      order: 2;
      background-color: $COLOR-BLACK;
    }
  }

  &_menubottom {
    display: flex;

    @include tabFixedContent {
      position: relative;
      align-items: center;
      justify-content: flex-end;
      margin: 0 auto;
    }

    @include spMenu {
      position: relative;
      flex-direction: column;
      padding-bottom: rem(2.5);
    }

    &::before {
      @include spMenu {
        position: absolute;
        bottom: 0;
        left: 15px;
        width: calc(100% - 30px);
        height: 1px;
        content: '';
        background-color: $COLOR-UTILITY-BD-SUBMENU-SP;
      }
    }

    .m-menu_menuitem:not(.m-menu_menuitem-active)>.m-menu_title {
      color: $COLOR-WHITE;
    }

    .m-menu_menuitem:hover .m-menu_title {
      color: $COLOR-CERULEAN;
    }

    .m-menu_menuitem-button:hover .m-menu_title {
      @include tabFixedContent {
        color: $COLOR-WHITE;
      }
    }

    .m-menu_menuitem-button.m-menu_menuitem-active .m-menu_title {
      @include spMenu {
        color: $COLOR-CERULEAN;
      }
    }

    .m-menu_menuitem:not(.m-menu_menuitem-button) .m-menu_title {
      @include tabFixedContent {
        padding-top: 15.5px;
        padding-bottom: 15.5px;
      }
    }
  }

  &_search {
    margin-left: 16px;

    .a-autocomplete {
      input {
        width: rem(19);
        height: rem(37/14);
        padding-right: 3rem;
        border-radius: 6px;
      }

      .a-icon {
        width: rem(1.5);
        height: rem(1.5);
      }

      &_result {
        border-radius: 6px;
      }
    }
  }

  &_wrapmobilesearch {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z('header', 'searchbar');
    width: 100%;
    height: 100vh;
    padding-top: rem(0.36);
    overflow: hidden auto;
    background-color: $COLOR-WHITE;
    transition: transform 0.5s;
    transform: translateX(-100%);
    -webkit-overflow-scrolling: touch;

    &-open {
      transform: translateX(0);
    }
  }

  &_mobilesearch {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: rem(1.07);
    padding-left: rem(1.43);

    >.a-icon {
      display: block;
      flex: 0 0 auto;
      width: rem(1.71);
      height: rem(1.71);
      margin-right: rem(1.43);
      cursor: pointer;
    }

    >div {
      width: rem(57.14);
    }

    .a-autocomplete {
      input {
        width: 100%;
        height: rem(2.86);
        font-size: rem(1.14);
        border: 1px solid $COLOR-BROWNISH-GREY;
        border-radius: 5px;

        &::placeholder {
          font-size: rem(1.14);
        }
      }

      .a-icon {
        right: rem(0.71);
        width: rem(1.71);
        height: rem(1.71);
      }

      &_result {
        left: -58px;
        width: calc(100% + 58px);
        padding: 0;
        border-radius: 0;
        box-shadow: none;

        .a-autocomplete_resultgroup:first-of-type {
          padding-top: rem(2.07);
        }
      }

      &_resultgroup {
        padding-top: rem(2.29);
        padding-bottom: rem(0.86);
        font-size: rem(1.29);
      }

      &_resultitem {
        padding-top: rem(0.86);
        padding-bottom: rem(0.86);
        margin-left: rem(1.07);
        font-size: rem(1.14);
      }
    }
  }

  .a-language {
    @include spMenu {
      padding-right: 15px;
    }

    &_item {
      @include spMenu {
        font-size: rem(1.14);
      }

      &-active {
        @include spMenu {
          color: $COLOR-WHITE;
        }
      }

      &:not(.a-language_item-active):hover {
        color: $COLOR-BLACK-TWO;

        @include spMenu {
          color: $COLOR-GRAY;
        }
      }
    }
  }

  &-hide {
    visibility: hidden;
    opacity: 0;
  }

  &_contactUs {
    position: relative;
    clip-path: polygon(100% 0, 97% 100%, 0 100%, 3% 0);
    background-image: linear-gradient(to right, #00A1E4, #4bc3f6, #26b6f3, #4bc3f6);
    background-size: 300% 100%;
    width: 142px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    transition: all .4s ease-in-out;

    &:hover {
      background-position: 100% 0;
      transition: all .4s ease-in-out;
    }

    @include break(992px, 1024px) {
      width: rem(5);
    }

    @include break-max(991px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-top: rem(2);
      max-width: 200px;
      background-size: auto;
    }

    &_wrap {
      @include spMenu {
        display: none;
      }
    }

    &_icon {
      width: 24px;
    }

    &-text {
      margin-left: 11px;
      color: $COLOR-WHITE;
      font-family: 'Roboto', sans-serif;

      @include break(992px, 1024px) {
        display: none;
      }
    }
  }
}