.m-lineupbanner {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: $COLOR-WHITE-FOUR;

  &_img {
    width: 100%;

    @include pcFixedContent {
      width: calc(448 / #{$WIDTH-CONTAINER-UNIT} * 100%);
    }
  }

  &_content {
    width: 100%;
    padding: 0 rem(2.25) rem(2.25);

    @include pcFixedContent {
      width: calc((100%) - (448 / #{$WIDTH-CONTAINER-UNIT} * 100%));
      padding-left: rem(5.5);
    }

    @include sp {
      padding: 0 24px 24px;
    }
  }

  &_title {
    padding-top: 24px;

    @include pc {
      padding-top: rem(2.25);
    }
  }

  &_button {
    .a-button {
      @include pcFixedContent {
        min-width: rem(20.625);
      }
    }
  }
}
