.o-news {
  $root: &;

  &_box {
    margin-top: rem(1);
  }

  &_description {
    max-width: 450px;
    margin: rem(1) auto 0 auto;
  }

  &_caption {
    margin-top: rem(1);

    @include pc {
      margin-top: rem(2);
    }
  }

  &_background {
    padding: rem(1);
    margin-top: rem(1);
    background-color: $COLOR-CERULEAN;

    @include pc {
      margin-top: rem(2);
    }
  }

  &_top {
    margin-top: rem(1);

    @include pcFixedContent {
      display: flex;
      align-items: center;
    }
  }

  &_videofull {
    @include pcFixedContent {
      width: calc(540 / #{$WIDTH-CONTAINER-UNIT} * 100%);
    }
  }

  &_info {
    @include pcFixedContent {
      width: calc(100% - (540 / #{$WIDTH-CONTAINER-UNIT} * 100%));
      padding-left: 15px;
    }

    @include pc-large {
      padding-left: 100px;
    }

    .m-textlist {
      padding-right: rem(1);
      padding-left: rem(3);
    }
  }

  &_boxtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(0.5);
    margin-top: rem(1);
    margin-bottom: 0;
    margin-bottom: rem(2);
    background-color: $COLOR-CAROLINA-BLUE;
    border-radius: 30px;

    .a-icon {
      width: rem(1.5);
      height: rem(0.9);
      margin-right: rem(0.5);
    }

    @include pcFixedContent {
      margin-top: 0;
    }
  }

  &_bottom {
    padding-top: rem(1);
  }

  &_boxborderblue {
    padding: rem(1);
    margin-top: rem(1);
    border: solid 5px $COLOR-CERULEAN;
    border-radius: 20px;

    @include pc {
      padding: rem(2);
    }
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(1);
    margin: 0 -15px;
  }

  &_item {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px;
    margin-bottom: rem(1.5);
    counter-increment: demoCounter;

    &:before {
      display: block;
      width: rem(1.875);
      height: rem(1.875);
      line-height: rem(1.875);
      color: $COLOR-WHITE;
      text-align: center;
      content: counter(demoCounter);
      background-color: $COLOR-CERULEAN;
      border-radius: 50%;
    }

    @include pc {
      flex: 0 0 calc(100% / 2);
    }

    @include pcFixedContent {
      flex: 0 0 calc(100% / 3);
    }
  }

  &_video {
    margin-top: rem(1);
  }
}
