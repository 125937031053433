.m-errorcodepanel {
  display: flex;

  @include sp {
    flex-wrap: wrap;
  }

  &_maincontent {
    width: 100%;
    padding: rem(1.5) rem(1.5) rem(2.875) rem(1.5);
    background: $COLOR-UTILITY-GRD-ERRORCODE,
      url('~assets/images/common/error-code-panel-background.png') center/cover no-repeat;

    @include pc {
      width: 34%;
      min-width: 300px;
    }
  }

  &_description {
    margin-bottom: rem(1);
    color: $COLOR-WHITE;
  }

  &_wrapcode {
    display: flex;
    .a-pulldown + .a-pulldown {
      margin-left: 15px;
    }
  }

  &_subcontent {
    width: 100%;
    padding: rem(1.5);
    background-color: $COLOR-WHITE-FOUR;

    @include pc {
      width: 66%;
    }

    li {
      padding-left: calc(#{rem(1.25)} + 3px);
      color: $COLOR-WARM-GREY;
    }
  }

  &_wraptags {
    margin-bottom: rem(1.5);

    .a-tag {
      margin-bottom: 2px;

      &:not(:last-child) {
        margin-right: rem(0.5);
      }
    }
  }

  .m-heading_body {
    margin-bottom: rem(0.75);
  }
}
