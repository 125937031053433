.a-text {
  font-size: rem(1);

  &-center {
    text-align: center;
  }

  &-bold {
    @include u-fw-b;
  }

  &-normal {
    @include u-fw-r;
  }

  &-fs-16 {
    font-size: 16px;
  }

  &-white {
    color: $COLOR-WHITE;
  }

  &-cerulean {
    color: $COLOR-CERULEAN;
  }

  &-medium {
    @include u-fw-m;
  }

  &-blacktwo {
    color: $COLOR-BLACK-TWO;
  }

  &-brownishgrey {
    color: $COLOR-BROWNISH-GREY;
  }

  &-warmgrey {
    color: $COLOR-WARM-GREY;
  }

  &-greyish {
    color: $COLOR-GREYISH;
  }

  &-brownishgrey-op-05 {
    color: $COLOR-UTILITY-BTN-BLACK-OP-05;
  }
}