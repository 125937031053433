.m-listitem {
  &_title {
    .a-text {
      font-size: rem(8/7);
      line-height: rem(1.5);
    }
  }

  &_description {
    margin-top: rem(0.5);
    color: $COLOR-RAISIN-BLACK;
  }

  &_footer {
    display: inline-flex;
    align-items: center;
    font-size: rem(1);
  }

  &_website {
    margin-left: rem(1);
    text-decoration: underline;
  }

  &-showroom {
    &,
    ul {
      list-style-type: none;
    }
  }
}
