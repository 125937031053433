.m-cardtag {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  position: relative;
  display: block;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 $COLOR-UTILITY-TXT-SLIDE-INACTIVE;

  &:hover {
    .a-image {
      img {
        transform: scale(1.05);
      }

      &::after {
        background-image: linear-gradient(to top, $COLOR-BLACK, rgba($COLOR-BLACK, 0) 60%);
        opacity: 1;
      }
    }
  }

  &_building {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    font-size: rem(1.5);
    line-height: 1.5;
    color: $COLOR-WHITE;
    text-align: center;
    transform: translate(-50%, -50%);
    @include u-fw-m;
  }

  &_tag {
    position: absolute;
    bottom: rem(1.5);
    left: rem(1.5);
    display: block;
    font-size: rem(1.5);
    line-height: 1.5;
    color: $COLOR-WHITE;
    @include u-fw-m;

    #{$root}-overlay & {
      color: $COLOR-BROWNISH-GREY;
    }
  }

  // NOTES: –多重影分身の術
  .a-image {
    position: relative;
    background-color: $COLOR-UTILITY-TXT-SLIDE-INACTIVE;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      opacity: 0;
      transition: opacity 2.4s $animation;
    }

    img {
      transition: transform 2.4s $animation;
    }
  }

  &-overlay {
    filter: grayscale(1);

    .a-image {
      &::after {
        background-image: linear-gradient(to top, $COLOR-BLACK, rgba($COLOR-BLACK, 0) 100%);
        opacity: 1;
      }
    }
  }

  &-equalonspmenu {
    .a-image {
      @include spMenu {
        @include aspectRatio(1, 1);
      }

      & > img {
        @include spMenu {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
