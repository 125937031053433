.o-list {
  &-showroom {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-12/14);

    .m-listitem-showroom {
      flex: 0 0 100%;
      padding: rem(12/14);

      @include pc {
        flex: 0 0 50%;
      }
    }
  }
}
