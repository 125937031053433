@import './mixins';

.documentlist {
  .mv-common {
    background-image: url('~assets/marutto/img/documentlist/mv.jpg');
  }

  .intro {

    &__title {
      margin: {
        top: -24px;
        bottom: 30px;
      }

      @include sp {
        margin: {
          left: -20px;
          bottom: 20px;
        }
      }

      > img {
        max-width: 340px;

        @include sp {
          max-width: 250px;
        }
      }
    }

    &__txt {
      padding: 0 25px;
      margin-bottom: 60px;
      font-size: 18px;
      line-height: 1.8;

      @include sp {
        padding: 0;
        margin-bottom: 40px;
        font-size: 16px;
      }
    }

    &__img {
      border-radius: 5px;
      overflow: hidden;
    }
  } //intro

  .download {
    .inner {
      padding-top: 0;
    }

    &__table {
      width: 100%;
      font-size: 20px;
      border-collapse: collapse;
      border: 1px solid var(--color-gray2);

      @include tablet{
        font-size: 16px;
      }

      @include sp {
        border: none;
      }
    }

    &__header {
      border-bottom: 1px solid var(--color-gray2);
    }

    &__header-title,
    &__thumb,
    &__title,
    &__type,
    &__date,
    &__action {
      padding: 20px;
    }

    &__header-title,
    &__thumb,
    &__title,
    &__type,
    &__date {
      padding: 20px;
      border-right: 1px solid var(--color-gray2);

      @include sp {
        padding: 10px;
        border-right: none;
        text-align: center;
      }
    }

    &__header-title {
      font-weight: 500;
    }

    &__item {
      &:nth-child(odd) {
        background: var(--color-light-blue);

        @include sp {
          background: transparent;
        }
      }

      &:not(:last-child){
        border-bottom: 1px solid var(--color-gray2);
      }

      @include sp {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        border: 1px solid var(--color-gray2);
      }
    }

    &__thumb {
      max-width: 200px;

      @include sp {
        max-width: 100%;
        padding: 20px;
      }
    }

    &__title {
      @include sp {
        font: {
          size: 20px;
          weight: 500;
        }
      }
    }

    &__action {
      text-align: center;

      a {
        padding: 10px 20px;
        display: block;
        border-radius: 2px;
        background: linear-gradient(var(--color-blue2) 0%, var(--color-blue) 100%);
        box-shadow: 0px 0px 20px rgba(30, 73, 107, 0.2);
        transition: .5s;
        color: #ffffff;
        white-space: nowrap;

        &:after {
          margin-left: 10px;
          content: url("~assets/marutto/img/common/arrow_right.svg");
          display: inline-block;
          vertical-align: middle;
        }

        @include hover {
          background: linear-gradient(var(--color-blue) 0%, var(--color-blue) 100%);
        }
      }
    }

    thead {
      @include sp {
        display: none;
      }
    }
  } //download
}