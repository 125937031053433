.a-button {
  $root: &;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: rem(6.375);
  padding: rem(0.75) rem(1);
  color: $COLOR-WHITE;
  text-align: center;
  background-color: $COLOR-CERULEAN;
  border: 1px solid $COLOR-UTILITY-TRANSPARENT;
  border-radius: 6px;
  outline: none;
  appearance: none;
  transition: all 0.3s $animation;
  @include u-fw-m;

  &:hover {
    background-color: $COLOR-PICTON-BLUE;

    &#{$root}-outline {
      background-color: $COLOR-UTILITY-BG-LOCATION-HOVER;
    }
  }

  &:active,
  &:focus {
    background-color: $COLOR-STAR-COMMAND-BLUE;
    box-shadow: 0 0 10px $COLOR-UTILITY-LINE;

    &#{$root}-outline {
      background-color: $COLOR-UTILITY-BG-LOCATION-ACTIVE;
    }
  }

  >span {
    min-width: rem(6.375);
    font-size: rem(1);
    line-height: 1.5;
  }

  &_iconwrapper {
    flex-shrink: 0;
    margin-right: rem(4/7);

    >.a-icon {
      display: block;
      width: rem(6/7);
      height: rem(6/7);
    }
  }

  &-medium {
    padding: rem(0.5) rem(1);

    >span {
      font-size: rem(1);
    }
  }

  &-large {
    min-width: rem(12.5);
  }

  &-round {
    border-radius: 6px;
  }

  &-success {
    background-color: $COLOR-KELLEY-GREEN;

    &:hover {
      background-color: $COLOR-UTILITY-BTN-SUCCESS-HOVER;
    }

    &:active,
    &:focus {
      background-color: $COLOR-UTILITY-BTN-SUCCESS-ACTIVE;
    }
  }

  &-black {
    background-color: $COLOR-BLACK;

    &:hover {
      background-color: $COLOR-BROWNISH-GREY;
    }

    &:active,
    &:focus {
      background-color: $COLOR-BLACK;
    }
  }

  &-white {
    color: $COLOR-CERULEAN;
    background-color: $COLOR-WHITE;

    &:hover {
      background-color: $COLOR-UTILITY-BTN-WHITE-HOVER;
    }

    &:active,
    &:focus {
      background-color: $COLOR-WHITE;
    }
  }

  &-outline {
    color: $COLOR-CERULEAN;
    background-color: $COLOR-UTILITY-TRANSPARENT;
    border-color: $COLOR-CERULEAN;

    &#{$root}-success {
      color: $COLOR-KELLEY-GREEN;
      border-color: $COLOR-KELLEY-GREEN;

      &:hover {
        background-color: $COLOR-UTILITY-BTN-SUCCESS-OUTLINE-HOVER;
      }

      &:active,
      &:focus {
        background-color: $COLOR-UTILITY-BTN-SUCCESS-OUTLINE-ACTIVE;
      }
    }

    &#{$root}-black {
      color: $COLOR-BLACK;
      border-color: $COLOR-BLACK;

      &:hover {
        background-color: $COLOR-UTILITY-BTN-BLACK-HOVER;
      }

      &:active,
      &:focus {
        background-color: $COLOR-UTILITY-BTN-BLACK-ACTIVE;
      }
    }

    &#{$root}-white {
      color: $COLOR-WHITE;
      border-color: $COLOR-WHITE;

      &:hover,
      &:active,
      &:focus {
        color: $COLOR-CERULEAN;
        background-color: $COLOR-WHITE;
      }
    }

    &#{$root}-disabled {
      border-color: $COLOR-GRAY;
    }

    &#{$root}-hasbackgroundimage {

      &:hover,
      &:active,
      &:focus {
        background-color: unset;
      }
    }
  }

  &-disabled {
    color: $COLOR-GRAY;
    pointer-events: none;
    background-color: $COLOR-UTILITY-LINE;
  }

  &-inline {
    display: inline-flex;
  }

  &-hasbackgroundimage {
    min-width: rem(115/14);
    padding: rem(5/14) rem(10/14);
    background-color: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    @include pc {
      min-width: rem(158/14);
      padding: rem(10/14) rem(20/14);
    }

    &:hover {
      background-color: unset;
      opacity: 0.7;
    }

    &:active,
    &:focus {
      background-color: unset;
      box-shadow: none;
    }

    &#{$root}-disabled {
      border-radius: 6px;
      opacity: 0.7;
    }

    >span {
      @include sp {
        font-size: rem(13/14);
      }
    }
  }
}

.a-button_3d {
  cursor: pointer;

  .a-icon {
    width: 46px;
    height: 35px;
  }
}