.m-cardsupport {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  height: 100%;
  box-shadow: 0 0 9px 0 $COLOR-UTILITY-CARD-SHADOW;
  transition: transform 2.4s $animation;

  &:hover {
    .a-image img {
      transform: scale(1.05);
    }
  }

  .a-image {
    overflow: hidden;

    img {
      transition: transform 2.4s $animation;
    }
  }

  &_header {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 117px;
    padding: rem(0.5) rem(0.5) 12px 16px;
    background-color: $COLOR-WHITE;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    @include pc {
      height: 125px;
      padding-bottom: rem(1);
      padding-left: rem(1.5);
    }
  }

  &_title {
    width: 50%;
    padding-right: rem(2);

    .a-text {
      font-size: 20px;

      @include pc {
        font-size: rem(1.5);
      }
    }
  }

  &_wrapimage {
    position: absolute;
    top: rem(0.5);
    right: rem(0.5);
  }

  &_image {
    position: relative;
    min-width: 160px;
    text-align: center;
  }

  &_content {
    height: calc(100% - 117px);
    padding: rem(1.125) rem(1);
    color: $COLOR-WHITE;
    border: 1px solid $COLOR-WHITE-TWO;
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    @include pc {
      height: calc(100% - 125px);
    }

    .a-button {
      display: inline-flex;
      padding-right: rem(1.5);
      background-image: url('~assets/images/icons/chevron-right-white.svg');
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 26px 18px;
      @include u-fw-r;

      &:hover {
        background-image: url('~assets/images/icons/chevron-right-blue.svg');
      }
    }

    #{$root}-spareparts & {
      background-color: $COLOR-MARINE-BLUE;
    }

    #{$root}-service & {
      background-color: $COLOR-PUMPKIN;
    }

    #{$root}-errorcodes & {
      background-color: $COLOR-TEAL;
    }

    #{$root}-hotline & {
      background-color: $COLOR-CERULEAN;
    }
  }

  &_description {
    padding-top: rem(1.8125);
    font-size: rem(1);
  }

  &_phonelist {
    display: flex;
    margin-top: rem(1);

    @include sp {
      justify-content: center;
    }
  }

  &_phone {
    position: relative;
    color: $COLOR-WHITE;
    .a-textlink {
      font-size: 26px;
      color: $COLOR-WHITE;
      @include u-fw-b;

      @include pc {
        font-size: rem(2);
      }
    }

    &:not(:last-child) {
      padding-right: 36px;

      @include pc {
        padding-right: 44px;
      }
      &::after {
        position: absolute;
        top: 0;
        right: 16px;
        width: 4px;
        height: 100%;
        content: '';
        background-color: $COLOR-WHITE;

        @include pc {
          right: 20px;
        }
      }
    }
  }
}
