.m-productdetail {
  display: flex;
  flex-wrap: wrap;

  @include tabFixedContent {
    flex-wrap: nowrap;
  }

  &_wrapimage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: rem(2.125) 0;
    background-color: $COLOR-WHITE;

    @include tabFixedContent {
      width: 40%;
    }

    .a-button {
      width: 100%;
      max-width: rem(15.375);
      margin: 0 auto;
    }
  }

  &_wrapfeature {
    display: flex;
    align-items: center;
    width: 100%;
    padding: rem(5.125) rem(6);
    background: $COLOR-CERULEAN;

    @include tabFixedContent {
      width: 60%;
    }

    @include sp {
      padding: rem(2);
    }
  }

  &_feature {
    display: flex;
    align-items: center;

    &content {
      padding-left: rem(1);

      .a-text {
        margin: 0;
        font-size: rem(1.5);
        line-height: rem(1.8125);
      }
    }

    & + & {
      margin-top: rem(2);
    }
  }
}
