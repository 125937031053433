.m-headoffice {
  $root: &;
  background-color: $COLOR-WHITE-SEVEN;

  @include pc {
    display: flex;
  }

  &_head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(1.25) rem(1);
    background-color: $COLOR-CERULEAN;

    @include pc {
      width: calc(199 / 648 * 100%);
    }

    .m-heading_body {
      margin-bottom: 0;
    }
  }

  &_content {
    padding: rem(1.25) rem(1);

    @include pc {
      width: calc((100%) - (199 / 648 * 100%));
    }
  }
}
