.m-cardbusiness {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  background-color: $COLOR-WHITE-FOUR;
  border: solid 1px $COLOR-WHITE-TWO;
  border-radius: 5px;

  .a-image {
    position: relative;
    overflow: hidden;

    img {
      transition: transform 2.4s $animation;
    }

    &,
    &::after {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: $COLOR-UTILITY-BG-IMG-LEADCARDLINK;
      opacity: 0;
      transition: opacity 2.4s $animation;
    }
  }

  &:hover {
    .a-image {
      img {
        transform: scale(1.05);
      }

      &::after {
        opacity: 0.25;
      }
    }
  }

  &_wrapcontent {
    padding: rem(1) rem(1) rem(1.5);
  }

  &_description {
    padding-top: rem(0.5);
    font-size: rem(1);
  }
}
