.a-tooltip {
  display: inline-flex;

  &_title {
    font-size: rem(0.75);
    color: $COLOR-WHITE;
    text-align: center;
  }

  .__react_component_tooltip {
    width: 154px;
    padding: 5px;
    background: $COLOR-UTILITY-TOOLTIP;
    box-shadow: 0 0 3px 0 $COLOR-UTILITY-TOOLTIP;
  }

  .place-top::after {
    border-top-color: $COLOR-UTILITY-TOOLTIP !important;
  }

  .place-bottom::after {
    border-bottom-color: $COLOR-UTILITY-TOOLTIP !important;
  }

  .place-left::after {
    border-left-color: $COLOR-UTILITY-TOOLTIP !important;
  }

  .place-right::after {
    border-right-color: $COLOR-UTILITY-TOOLTIP !important;
  }
}
