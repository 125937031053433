.a-toggleicon {
  $root: &;

  position: relative;
  width: rem(1.5);
  height: rem(1.5);
  cursor: pointer;
  flex-shrink: 0;

  &_icon {
    position: absolute;
    width: 100%;
    height: 100%;

    &:nth-of-type(1) {
      #{$root}:hover & {
        visibility: hidden;
        opacity: 0;
      }
    }

    &:nth-of-type(2) {
      visibility: hidden;
      opacity: 0;

      #{$root}:hover & {
        visibility: inherit;
        opacity: 1;
      }
    }
  }

  &-medium {
    width: rem(2);
    height: rem(2);
  }

  &-s-large {
    width: rem(2.572);
    height: rem(2.572);
  }

  &-m-large {
    width: rem(3);
    height: rem(3);
  }

  &-large {
    width: rem(4);
    height: rem(4);
  }
}