.m-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 rem(0.75) 0;
  font-size: rem(0.75);
  text-transform: uppercase;
  background-color: $COLOR-WHITE;

  &_item {
    position: relative;
    margin: rem(0.875) 18px 0 22px;
    list-style: none;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 6px;
      height: 10px;
      margin-left: -22px;
      content: '';
      border: 6px solid $COLOR-UTILITY-TRANSPARENT;
      border-left-color: $COLOR-GREYISH-TWO;
      border-radius: 3px;
      transform: translateY(calc(-50% - 1px));
    }

    &:first-of-type {
      margin-left: 0;
      &:before {
        display: none;
      }
    }

    &,
    a {
      color: $COLOR-GREYISH;
      @include u-fw-b;
    }

    &:not(:last-child) {
      &:hover a {
        color: $COLOR-CERULEAN;
      }
    }

    &:last-child {
      color: $COLOR-BLACK;
      pointer-events: none;
      cursor: auto;
    }
  }
}
