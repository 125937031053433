.a-tag {
  $root: &;

  display: inline-flex;
  align-items: center;
  padding: rem(0.3125) rem(0.5);
  font-size: rem(0.75);
  line-height: rem(0.875);
  color: $COLOR-WHITE;

  &_clearitem {
    position: relative;
    padding-left: 7px;
    margin-left: rem(0.5);
    cursor: pointer;

    &::before {
      position: absolute;
      top: rem(-0.3125);
      left: 0;
      width: calc(100% + 7px);
      height: calc(100% + #{rem(0.625)});
      content: '';
    }

    &:hover {
      z-index: z('clearable', 'text');
      color: $COLOR-UTILITY-CLEAR;

      &::before {
        z-index: z('clearable', 'textbefore');
        background-color: $COLOR-UTILITY-BG-CLEAR;
      }
    }
  }

  &-cerulean {
    background-color: $COLOR-CERULEAN;
  }

  &-pumpkin {
    background-color: $COLOR-PUMPKIN;
  }

  &-lightblue {
    background-color: $COLOR-LIGHT-BLUE;
  }

  &-yellow-orange {
    background-color: $COLOR-YELLOW-ORANGE;
  }

  &-light-gold {
    color: $COLOR-BLACK;
    background-color: $COLOR-LIGHT-GOLD;
  }

  &-kelly-green {
    background-color: $COLOR-KELLEY-GREEN;
  }

  &-white {
    color: $COLOR-CERULEAN;
    background-color: $COLOR-WHITE;
  }

  &-black {
    background-color: $COLOR-BLACK;
  }

  &-mention {
    padding: rem(0.3125) rem(0.75);
  }

  &-large {
    padding: rem(0.4375) rem(1.5) rem(0.375) rem(1.5);
    font-size: rem(1);
    line-height: rem(1.1875);
  }

  &-bold {
    @include u-fw-b;
  }

  &-bordered {
    border-radius: 3px;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-withselect {
    cursor: pointer;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      opacity: 0.7;
    }
  }
}
