.o-downloadselectpanel {
  &_wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;

    .m-downloadselect {
      padding-top: 15px;

      &:not(:last-child) {
        margin-right: 15px;
      }

      &_container {
        margin-top: 0;

        > div:first-child {
          position: unset;
        }
      }

      &__menu {
        margin-top: 0;
      }
    }
  }
}
