.m-downloadselect {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include sp {
    flex-wrap: wrap;
  }

  &_title {
    position: relative;
    padding-left: 20px;
    font-size: rem(1.25);
    line-height: rem(1.5);
    color: $COLOR-CERULEAN;
    @include u-fw-b;

    &::before {
      position: absolute;
      top: 4px;
      left: 0;
      content: '';
      border: 8px solid $COLOR-CERULEAN;
      border-right-color: $COLOR-UTILITY-TRANSPARENT;
      border-bottom-color: $COLOR-UTILITY-TRANSPARENT;
    }
  }

  &_container {
    z-index: z('downloadselect', 'select');

    @include sp {
      width: 100%;
      margin-top: 15px;
    }

    #{$root}:not(#{$root}-autowidth) & {
      min-width: 300px;
    }
  }

  &_wrapbutton {
    position: absolute;
    bottom: 12px;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 12px 0;
    clear: both;
    background-color: $COLOR-WHITE;
    border-top: 1px solid $COLOR-WHITE-TWO;
  }

  &_btnclear,
  &_btnsave {
    padding: 11px 40px;
    font-size: 14px;
    border-radius: 6px;
    outline: none;
  }

  &_btnclear {
    color: $COLOR-BLACK;
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-BLACK;
  }

  &_btnsave {
    color: $COLOR-WHITE;
    background-color: $COLOR-BLACK;
    border: 0;
  }

  // NOTE: Override react-select
  &__menu {
    @include pc {
      right: 0;
      width: 500px !important;
    }

    #{$root}-withouttitle & {
      left: 0;
    }
  }
}