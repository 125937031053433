@import './mixins';

.home {
  .mv {
    height: 100vh;
    display: grid;
    align-items: center;
    text-align: center;

    background: {
      image: url('~assets/marutto/img/home/mv_bg.jpg');
      repeat: no-repeat;
      position: center center;
      size: cover;
    }

    @include tablet-only {
      height: 600px;
    }

    @include sp {
      align-items: start;
      padding-top: 38%;
      background-image: url('~assets/marutto/img/home/mv_bg_sp.jpg');
    }

    @include pc-big {
      height: 720px;
    }

    &__inner {
      @include sp {
        padding: 0;
      }
    }

    &__title {
      @include sp {
        max-width: 320px;
      }
    }

    &__text {
      padding-left: 30px;
      color: $color-blue;
      margin-bottom: 0;

      font: {
        size: 20px;
        weight: 500;
      }

      text-align: left;
      letter-spacing: .1rem;

      @include sp {
        padding-left: 24px;
        font-size: 14px;
      }
    }
  }

  .headline1 {
    @include sp {
      font-size: 30px;
    }
  }

  // What is MARUTTO
  .about {
    background: {
      image: url('~assets/marutto/img/home/about_bg1.jpg'), url('~assets/marutto/img/home/about_bg2.jpg');
      repeat: no-repeat, no-repeat;
      position: top center, bottom center;
      size: 100% auto, 100% auto;
    }

    @include sp {
      background: {
        image: url('~assets/marutto/img/home/about_bg1_sp.jpg'), url('~assets/marutto/img/home/about_bg2_sp.jpg');
      }
    }

    @media screen and (min-width: 1441px) {
      background: {
        image: url('~assets/marutto/img/home/about_bg.jpg');
        repeat: no-repeat;
        position: top center;
        size: 100% 100%;
      }
    }

    &__lead {
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;

      @include sp {
        flex-direction: column;
      }
    }

    &__lead-text {
      width: 48%;

      @include sp {
        margin-bottom: 30px;
        width: 100%;
      }

      >h2 {
        margin-bottom: 50px;
        color: #ffffff;

        font: {
          size: 32px;
          weight: 500;
        }

        @include sp {
          margin-bottom: 30px;
          font-size: 28px;
        }

        >span {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            width: 480px;
            height: 2px;
            background: #ffffff;
            bottom: -8px;
            right: 0;

            @include pc-big {
              width: 700px;
            }

            @include tablet {
              width: calc(100% + 40px);
            }

            @include sp {
              width: calc(100% + 20px);
            }
          }
        }
      }

      >p {
        color: #ffffff;
        font-size: 18px;
        line-height: 1.8;
        margin-bottom: 0;

        @include sp {
          font-size: 16px;
        }
      }
    }

    &__lead-img {
      width: 50%;

      @include sp {
        width: 100%;
      }
    }

    &__movie {
      margin-bottom: 120px;
      display: grid;
      place-items: center;

      @include sp {
        margin-bottom: 60px;
      }
    }

    &__movie-wrap {
      width: 100%;
      aspect-ratio: 16 / 9;

      @include pc {
        width: 740px;
      }

      // YouTube
      iframe {
        width: 100%;
        height: 100%;
      }

      // mp4
      video {
        width: 100%;
        height: 100%;
      }
    }

    &__link {
      margin: 0 auto 200px;
      max-width: 900px;
      // display: flex;
      // justify-content: space-between;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;

      @include tablet-only {
        column-gap: 20px;
        margin-bottom: 100px;
      }

      @include sp {
        margin-bottom: 100px;
        max-width: 270px;
        // flex-direction: column;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
      }
    }

    &__link-btn {
      padding: 30px;
      // width: calc( calc( 100% - 80px ) / 3 );
      border: 1px solid var(--color-darkblue);
      border-radius: 5px;
      display: grid;
      place-items: center;
      text-align: center;
      transition: .5s;

      @include tablet-only {
        padding: 30px 10px;
      }

      >h4 {
        margin-bottom: 16px;
        color: var(--color-darkblue);

        font: {
          size: 22px;
          weight: 500;
        }

        transition: .5s;
      }

      >figure {
        margin-bottom: 26px;
        width: 110px;
        height: 110px;
      }

      >p {
        color: #ffffff;

        @include tablet-only {
          font-size: 14px;
        }

        span {
          padding: 10px 20px;
          border-radius: 2px;
          background: linear-gradient(var(--color-blue2) 0%, var(--color-blue) 100%);
          box-shadow: 0px 0px 20px rgba(30, 73, 107, 0.2);
          transition: .5s;
          font-size: 16px;

          &:after {
            margin-left: 10px;
            content: url('~assets/marutto/img/common/arrow_right.svg');
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      @include hover {
        border-color: var(--color-blue);

        >h4 {
          color: var(--color-blue);
        }

        >p {
          span {
            background: linear-gradient(var(--color-blue) 0%, var(--color-blue) 100%);
          }
        }
      }
    }

    &__benefits-wrap {
      display: flex;
      justify-content: space-between;

      @include tablet {
        flex-direction: column;
      }
    }

    &__benefits-img {
      width: 45%;

      @include tablet {
        width: 100%;

        padding: {
          left: 5%;
          right: 5%;
        }

        margin-bottom: 60px;
      }

      @include tablet-only {
        width: 60%;
        margin: 0 auto 60px;
      }
    }

    &__benefits-text {
      width: 55%;

      @include tablet {
        width: 100%;
      }

      >li {
        padding: {
          top: 3px;
          left: 30%;
        }

        margin-bottom: 40px;
        min-height: 138px;

        background: {
          size: 130px 138px;
          repeat: no-repeat;
          position: top left 2%;
        }

        @include tablet {
          padding: {
            top: 150px;
            left: 0;
          }

          margin-bottom: 60px;
          background-position: top center;
        }

        &:first-child {
          background-image: url('~assets/marutto/img/home/about_icon04.png');
        }

        &:nth-child(2) {
          background-image: url('~assets/marutto/img/home/about_icon05.png');
        }

        &:nth-child(3) {
          background-image: url('~assets/marutto/img/home/about_icon06.png');
        }

        .headline2 {
          margin-bottom: 15px;
        }
      }
    }
  }

  // Features
  .features {
    padding: {
      left: 30px;
      right: 30px;
    }

    background: {
      image: url('~assets/marutto/img/home/features_bg.jpg');
      repeat: no-repeat;
      position: bottom center;
      size: cover;
    }

    @include sp {
      padding: 0;
      background-image: url('~assets/marutto/img/home/features_bg_sp.jpg');
    }

    .headline2 {
      text-align: center;
    }

    &__list {
      margin-bottom: 120px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: auto;
      column-gap: 40px;
      row-gap: 30px;

      @include tablet {
        margin-bottom: 80px;
        // grid-template-columns: repeat(2, 1fr);
      }

      @include sp {
        margin-bottom: 60px;
        // grid-template-columns: repeat(1, 1fr);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &[data-type="col2"] {
        margin: {
          left: 12%;
          right: 12%;
        }

        // grid-template-columns: repeat(2, 1fr);

        >li {
          width: calc(calc(100% - 40px) / 2);
        }

        @include tablet {
          margin: {
            left: 0;
            right: 0;
          }
        }

        @include sp {
          margin: {
            left: 0;
            right: 0;
          }

          // grid-template-columns: repeat(1, 1fr);

          >li {
            width: 100%;
          }
        }
      }

      >li {
        width: calc(calc(100% - 80px) / 3);
        padding: 30px;
        display: grid;
        place-items: center;
        place-content: start;
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid var(--color-gray2);

        @include tablet {
          width: calc(calc(100% - 40px) / 2);
        }

        @include sp {
          width: 100%;
        }

        >h4 {
          font: {
            // size: 2.2rem;
            size: 20px;
            weight: 500;
          }

          color: var(--color-darkblue);
          text-align: center;
          // min-height: 3em;
        }

        >figure {
          // margin-bottom: 20px;
          width: 147px;
          height: 147px;
        }

        >h5 {
          margin-top: 20px;
          justify-self: start;
          color: var(--color-blue);

          font: {
            size: 18px;
            weight: 500;
          }

          text-align: left;

          .option {
            margin-left: 10px;
          }
        }
      }
    }

    .option {
      padding: 3px 10px;
      color: #ffffff;

      font: {
        size: 14px;
        weight: 500;
      }

      background: var(--color-blue2);
      border-radius: 2px;
    }

    &__list-tag {
      margin-bottom: 20px;
      text-align: center;
    }

  }

}

//home