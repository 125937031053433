.m-imageselector {
  $root: &;

  position: relative;
  height: 100%;

  &:hover {
    .a-image img {
      filter: none;
      transform: scale(1.05);
    }

    .a-button-black {
      background-color: $COLOR-CERULEAN;
    }
  }

  &_image {
    .a-image {
      overflow: hidden;

      img {
        filter: grayscale(100%);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  &_wrapbutton {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: rem(1);
  }

  .a-button-black {
    width: 100%;
    margin: 0;
    transition: background-color 0.4s ease-in-out;

    >span {
      font-size: rem(1.5);
    }
  }
}