.p-productlist {
  margin-bottom: rem(20/7);

  @mixin withbackground {
    padding-bottom: rem(20/7);
    margin: 0;

    @include pc {
      padding-bottom: rem(3.75);
    }
  }

  @include pc {
    margin-bottom: rem(3.75);
  }

  &-bgwhitesix {
    background-color: $COLOR-WHITE-SIX;

    @include withbackground;
  }

  &_floatingbutton {
    position: fixed;
    right: rem(12/7);
    bottom: rem(40/7);
    z-index: z('productlist', 'floatingbutton');

    @include spMedium {
      right: rem(8/7);
      min-width: auto;
    }

    .a-button {
      width: 100%;
      max-width: rem(4.9);
      padding: rem(11/14);

      @include spMedium {
        min-width: auto;
        padding: rem(3/7) 0;
      }

      > span {
        font-size: rem(8/7);
        @include u-fw-b;

        @include sp {
          font-size: rem(13/14);
        }
      }

      &:nth-of-type(2) {
        margin-top: rem(0.586);
        color: $COLOR-AZURE;
      }

      .a-icon {
        width: rem(5/2);
        height: rem(5/2);
        margin-top: rem(13/7);

        @include spMedium {
          width: rem(13/7);
          height: rem(13/7);
          margin-top: rem(19/14);
        }
      }
    }

    a {
      &:nth-of-type(2) {
        .a-button {
          margin-top: rem(0.906);
        }
      }
    }
  }

  &_heading {
    padding: rem(4/7) 0;
    font-size: rem(2.375);
    color: $COLOR-BLACK;
    text-align: center;

    &-ipadtype {
      max-width: rem(74.375);
      margin-right: auto;
      margin-left: auto;

      @include tab {
        max-width: rem(61.25);
        font-size: rem(2);
      }

      @include spMenu {
        max-width: rem(31.25);
      }
    }

    @include spMenu {
      font-size: calc(38 * ((100vw) / #{$WIDTH-CONTAINER-UNIT}));
    }

    @include sp {
      padding: 0;
      font-size: rem(1.25);
    }

    > span {
      display: block;

      &:not(:last-of-type) {
        margin-bottom: rem(6/7);

        @include sp {
          margin-bottom: rem(1);
        }
      }
    }

    &-technology {
      padding-top: 32px;

      @include pc {
        padding-top: rem(1.75);
      }
    }
  }

  &_title {
    // font-family: $FONTFAMILY-RALEWAY;
    @include u-fw-smb;
    text-transform: uppercase;

    @include sp {
      line-height: rem(10/7);
    }
  }

  &_subtitle {
    // font-family: $FONTFAMILY-RALEWAY;

    @include sp {
      line-height: rem(10/7);
    }
  }

  &_strengthheading,
  &_productlistheading .m-heading_text,
  &_wheretobuyheading .m-heading_text,
  &_martheading .m-heading_text {
    font-size: rem(12/7);
    line-height: rem(27/14);

    @include pc {
      font-size: rem(16/7);
    }
  }

  &_strengthheading {
    display: block;
    padding-bottom: rem(15/7);
    text-align: center;
    @include u-fw-m;

    @include sp {
      padding: 0 0 rem(20/7);
    }
  }

  &_productlistheading {
    .m-heading_body {
      margin-bottom: rem(5/7);
    }

    + .o-section {
      @include pc {
        padding-bottom: rem(52.5/14);
      }
    }
  }

  &_wheretobuy {
    margin-bottom: rem(16/7);

    @include tabFixedContent {
      margin-bottom: rem(12/7);
    }
  }

  &_wheretobuycontent {
    margin-right: rem(-6/7);
    margin-left: rem(-6/7);

    > div {
      padding-right: rem(6/7);
      padding-left: rem(6/7);
    }
  }

  &_wheretobuyheading {
    .m-heading_body {
      margin-top: rem(4/7);
      margin-bottom: rem(8/7);
    }
  }

  &_dealerheading {
    font-size: rem(16/7);
    line-height: rem(24/7);
    color: $COLOR-BLACK;
    @include u-fw-m;

    @include sp {
      font-size: rem(2);
    }
  }

  &_technologycontent {
    max-width: rem(56.375);
    margin: rem(29/14) auto rem(4/7) auto;

    text-align: justify;

    @include tabFixedContent {
      margin-top: rem(12/7);
    }

    > span {
      display: block;
    }

    &_description {
      div {
        font-size: rem(1);
        line-height: 1.54;
        ul {
          margin-top: rem(4/7);

          @include tabFixedContent {
            margin-top: rem(5/7);
          }
          li {
            width: fit-content;
            padding-left: rem(10/7);
            margin-right: auto;
            margin-left: auto;
            font-size: rem(13/14);
            line-height: 1.54;

            &:not(:first-of-type) {
              margin-top: rem(4/7);

              @include tabFixedContent {
                margin-top: rem(7/14);
              }
            }

            &::before {
              border-width: rem(5/14);

              border-top-color: $COLOR-PICTON-BLUE2;
              border-left-color: $COLOR-PICTON-BLUE2;

              @include tabFixedContent {
                border-width: rem(7/14);
              }
            }
          }
        }
      }
    }
  }

  &_technologytitle {
    font-size: rem(10/7);
    line-height: 1.5;
    color: $COLOR-SLATE-BLUE;
    @include u-fw-m;

    @include tabFixedContent {
      font-size: rem(18/7);
      line-height: 1.2;
      color: $COLOR-YALE-BLUE;
    }
  }

  &_technologysubtitle {
    margin-top: rem(6/7);
    font-size: rem(13/14);
    line-height: 1.54;

    @include tabFixedContent {
      margin-top: rem(12/7);
      font-size: rem(10/7);
      line-height: 1.2;
    }
  }

  &_technologyquote {
    position: relative;
    display: flex;
    align-items: center;
    max-width: rem(34.813);
    min-height: rem(5);
    padding: rem(1) rem(24/7);
    margin: rem(28.9/14) auto 0 auto;
    // font-family: $FONTFAMILY-RALEWAY;
    font-size: rem(1);
    line-height: 1.43;
    text-align: center;
    background: url('~assets/images/common/quote-frame-mobile.jpg') center/100% 100% no-repeat;
    @include u-fw-m;

    @include pc {
      margin-bottom: rem(-2.25);
      background-image: url('~assets/images/common/quote-frame-pc.jpg');
    }

    @include tabFixedContent {
      padding: rem(6/7) rem(3.375) rem(6/7) rem(4.813);
      margin-top: rem(55/14);
      font-size: rem(1.25);
      line-height: 1.6;
    }

    &::before {
      position: absolute;
      top: rem(-4/7);
      left: rem(5/7);
      display: inline-block;
      width: rem(36.1/14);
      height: rem(31.8/14);

      content: '';
      background: url('~assets/images/common/left-quote.jpg') center/cover no-repeat;

      @include tabFixedContent {
        top: rem(-1);
        left: rem(1);
        width: rem(47.2/14);
        height: rem(41.6/14);
      }
    }
  }

  &_eshopbanner {
    position: relative;

    > a {
      height: 100%;

      @include spMedium {
        max-width: rem(175/7);
      }
    }
  }

  &_contactus {
    position: absolute;
    bottom: rem(15/14);
    left: 50%;
    transform: translateX(-50%);

    .a-button {
      display: block;
      padding: rem(4/7) rem(12/7);
      text-transform: uppercase;
    }
  }

  .m-contactbanner {
    background-color: unset;
    background-image: linear-gradient(to right, $COLOR-CERULEAN, $COLOR-SPIRO-DISCO-BALL 147%);

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include pc {
        display: block;
      }
    }
  }

  &_martwrapper {
    background-color: $COLOR-WHITE;

    @include pc {
      background-color: unset;
    }
  }

  &_mart {
    padding: rem(12/7) 0;
    margin-bottom: rem(16/7);
    background-color: $COLOR-WHITE;

    @include pc {
      padding: rem(16/7) 0;
    }

    @include tabFixedContent {
      margin-bottom: rem(52.5/14);
    }

    @include pcFixedContent {
      padding: rem(32/7) 0;
    }
  }

  &_martheading {
    .m-heading_body {
      margin-bottom: rem(19/14);

      @include pc {
        margin-bottom: rem(16/7);
      }

      @include pcFixedContent {
        margin-bottom: rem(20/7);
      }
    }
  }

  &_martcarousel {
    .m-carousel {
      &_prev,
      &_next {
        display: block;

        .a-icon {
          width: rem(4/7);
          height: rem(8/7);
          background-image: url('~assets/images/common/angle-right-black.svg');

          @include pc {
            width: rem(6/7);
            height: rem(12/7);
          }

          &-arrow-left-black {
            transform: rotate(180deg);
          }
        }
      }

      &_prev {
        margin-left: rem(1/7);

        @include pc {
          margin-left: rem(8/7);
        }

        @include pcFixedContent {
          margin-left: rem(10/7);
        }
      }

      &_next {
        margin-right: rem(1/7);

        @include pc {
          margin-right: rem(8/7);
        }

        @include pcFixedContent {
          margin-right: rem(10/7);
        }
      }

      .swiper-button-lock {
        display: none;
      }

      .swiper-container {
        margin: 0 rem(19/14);

        @include pc {
          margin: 0 rem(49/14);
        }

        @include pcFixedContent {
          margin: 0 rem(26/7);
        }
      }
    }
  }

  .m-breadcrumbs {
    @include sp {
      display: none;
    }
  }
}
