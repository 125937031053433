.p-general {
  margin-bottom: 40px;

  @mixin withbackground {
    padding-bottom: 40px;
    margin: 0;

    @include pc {
      padding-bottom: rem(3.75);
    }
  }

  @include pc {
    margin-bottom: rem(3.75);
  }

  &-bgwhitesix {
    background-color: $COLOR-WHITE-SIX;

    @include withbackground;
  }

  &-nomarginbottom {
    margin-bottom: 0;
  }

  .m-breadcrumbs {
    @include sp {
      display: none;
    }
  }
}