@import './mixins';
@import '../functions';

.case-study {
  .mv-common {
    background-image: url('~assets/marutto/img/case-studies/mv.jpg');
  }

  .list {
    &:not(:first-of-type) {
      .inner {
        padding-top: 20px;
      }
    }

    &__wrap {
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: 40px;

      @include sp {
        grid-template-columns: repeat(1, 1fr);
      }

      + .list__wrap {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 1px solid var(--color-gray2);
      }
    }

    &__type {
      margin-bottom: 30px;
      color: var(--color-blue);
      font: {
        size: 28px;
        weight: 500;
      }
      line-height: 1.1;

      @include tablet {
        margin-bottom: 20px;
        font-size: 26px;
      }

      @include sp {
        font-size: 22px;
        line-height: 1.5;
      }
    }

    &__img {
      @include sp {
        margin-bottom: 30px;
      }

      img {
        border-radius: 5px;
      }
    }

    &__info-title {
      color: var(--color-darkblue);
      font: {
        size: 20px;
        weight: 500;
      }

      @include sp {
        font-size: 18px;
      }
    }

    &__info-details {
      margin-top: 5px;

      + .list__info-title {
        margin-top: 20px;
      }

      > li {
        padding-left: 16px;
        position: relative;
        text-indent: 16px;
        font-size: 16px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          left: 10px;
          top: 10px;
          border-radius: 10px;
          background: var(--color-blue);
        }
      }
    }
  } //list
}