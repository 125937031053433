.m-cardsolution {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  position: relative;
  overflow: hidden;
  border: solid 1px $COLOR-WHITE-TWO;
  border-radius: 5px;

  &:hover {
    .a-image img {
      transform: scale(1.05);
    }
  }

  &-nohover:hover {
    .a-image img {
      transform: unset;
    }
  }

  &_images {
    #{$root}-horizontal & {
      @include pc {
        width: calc(286 / 672 * 100%);
      }
    }
  }

  &_wrapimage {
    position: relative;

    #{$root}-horizontal & {
      @include pc {
        padding-right: rem(2.875);
      }
    }
  }

  &_thumb {
    #{$root}-horizontal & {
      position: absolute;
      top: 0;
      right: 0;
      z-index: z('cardsolution', 'thumb');
      width: calc(168 / 289 * 100%);
    }

    #{$root}-productsolutions & {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s $animation;
    }

    #{$root}-productsolutions:hover & {
      visibility: visible;
      opacity: 1;
    }
  }

  &_image {
    position: relative;
    overflow: hidden;

    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }

    #{$root}-horizontal & {
      .a-image,
      &:before {
        overflow: hidden;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        @include pc {
          border-top-left-radius: 4px;
          border-top-right-radius: 0;
          border-bottom-left-radius: 4px;
        }
      }
    }

    #{$root}-vertical & {
      .a-image,
      &:before {
        overflow: hidden;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    #{$root}-productsolutions & {
      transition: all 0.3s $animation;
    }

    #{$root}-productsolutions:hover & {
      visibility: hidden;
      opacity: 0;
    }

    #{$root}-nohover:hover & {
      visibility: unset;
      opacity: 1;
    }
  }

  &_content {
    #{$root}-horizontal & {
      position: relative;

      @include pc {
        width: calc((100%) - (286 / 672 * 100%));
      }
    }
  }

  &_wrapcontent {
    padding: rem(1);

    #{$root}-horizontal & {
      @include pc {
        padding-left: rem(0.5);
      }
    }
  }

  &_wraptitle {
    #{$root}-horizontal & {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: rem(1);
    }

    #{$root}-vertical & {
      margin-bottom: rem(1.5);
    }
  }

  &_title {
    #{$root}-horizontal & {
      padding-right: rem(0.5);
    }

    #{$root}-vertical & {
      margin-top: rem(0.5);
    }
  }

  &_productsolutiontitle {
    font-size: rem(16/14);
    line-height: rem(24/14);
    @include u-fw-b;
    @include text-overflow(2);
  }

  &_descriptions,
  &_note {
    #{$root}-solutionparadigm & {
      ul {
        color: $COLOR-BLACK-TWO;
        list-style-type: none;

        li {
          position: relative;
          padding-left: calc(#{rem(1.25)} + 12px);
          font-size: rem(1);

          &::before {
            position: absolute;
            top: 2px;
            left: 0;
            width: 16px;
            height: 16px;
            content: '';
          }
        }
      }
    }
  }

  &_descriptions {
    #{$root}-solutionparadigm & {
      ul li {
        &::before {
          background-image: url('~assets/images/icons/order-plus-circle.svg');
        }
      }
    }
  }

  &_note {
    #{$root}-horizontal & {
      margin-top: rem(1);
    }

    #{$root}-vertical & {
      margin: rem(1) 0;
      margin-bottom: 0;
    }

    #{$root}-solutionparadigm & {
      ul li {
        &::before {
          background-image: url('~assets/images/icons/note.svg');
        }
      }
    }
  }

  &-horizontal {
    background-color: $COLOR-WHITE-SIX;

    @include pc {
      display: flex;
    }
  }

  &-vertical {
    height: 100%;
    background-color: $COLOR-WHITE;

    .m-heading_text {
      text-align: center;
    }
  }

  .m-heading_body {
    margin-bottom: 0;
  }

  .m-textlist {
    color: $COLOR-BLACK-TWO;
  }

  .a-image img {
    transition: transform 2.4s $animation;
  }
}

.swiper-slide-highlight {
  .m-cardsolution-productsolutions.m-cardsolution-hightlightactiveslide {
    border: 1px solid $COLOR-CERULEAN;
    box-shadow: 0 0 10px 0 rgba($COLOR-BLACK, 0.1);
  }
}
