.m-cardcompareoption {
  $root: &;
  position: relative;
  background-color: $COLOR-WHITE-FOUR;

  &_wrapcontent {
    padding: rem(1.5) rem(1) rem(1);
  }

  &_title {
    padding-bottom: rem(1);
    font-size: rem(1.25);
    @include u-fw-m;
  }

  &_close {
    position: absolute;
    top: rem(0.75);
    right: rem(0.75);
    cursor: pointer;
    transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    .a-icon {
      display: block;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
