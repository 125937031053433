.m-cardproducttype {
  $root: &;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  position: relative;
  z-index: z('cardproducttype', 'box');
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    content: '';
    transition: background-color 0.5s $animation;
  }

  &:before {
    top: 0;
    height: calc(100% / 3);
    background-color: $COLOR-UTILITY-TRANSPARENT;
  }

  &:after {
    bottom: 0;
    z-index: z('cardproducttype', 'after');
    height: calc(100% / 3 * 2);
    background-color: $COLOR-WHITE;
  }

  &:hover {
    &:before,
    &:after {
      background-color: $COLOR-CERULEAN;
    }

    .m-heading_text {
      color: $COLOR-WHITE;
    }

    #{$root}_title {
      .a-image {
        height: 0;
        margin: 0;
        overflow: hidden;
        opacity: 0;
      }
    }

    #{$root}_titleactive {
      .a-image {
        height: auto;
        overflow: visible;
        pointer-events: inherit;
        opacity: 1;
      }
    }
  }

  &_wrapimg {
    max-width: 312px;
    margin: 0 auto;
  }

  &_title,
  &_titleactive {
    .a-image_img {
      max-height: 24px;
      margin: 12px;

      @include pc {
        margin: 24px;
      }
    }

    .m-heading_body {
      margin: 12px;

      @include pc {
        margin: 24px;
      }
    }

    .m-heading_text {
      transition: color 0.5s $animation;
    }
  }
  &_titleactive {
    .a-image {
      height: 0;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
      transition: all 0.5s $animation;
    }
  }
}
