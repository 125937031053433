.m-faqbox {
  $root: &;
  $animation: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  width: 100%;
  height: 100%;
  padding: rem(2.875) rem(1.5625) rem(1) rem(1.5625);
  cursor: pointer;
  background-color: $COLOR-WHITE;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 $COLOR-UTILITY-BADGE-SHADOW;
  transition: background-color $animation;

  &_wrapicon {
    position: relative;
    width: rem(4);
    height: rem(4);
    margin: 0 auto;
  }

  .a-toggleicon {
    margin: 0 auto;

    &_icon {
      transition: all $animation;
    }
  }

  .a-text {
    padding-top: rem(2);
    transition: color $animation;
  }

  &:hover,
  &-actived {
    background-color: $COLOR-CERULEAN;
    .a-text {
      color: $COLOR-WHITE;
    }

    .a-toggleicon_icon:nth-of-type(1) {
      visibility: hidden;
      opacity: 0;
    }

    .a-toggleicon_icon:nth-of-type(2) {
      visibility: inherit;
      opacity: 1;
    }
  }
}
