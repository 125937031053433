.o-modal {
  $root: &;
  $content: #{$root}_content;

  &_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z('modal', 'base');
    content: '';
    background: $COLOR-UTILITY-BG-CATALOGUE-BLACK;
  }

  &_close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: z('modal', 'close');
    display: inline-flex;
    cursor: pointer;

    #{$content}-fullscreenMobile & {
      top: 5px;
      right: 5px;
    }

    #{$content}-hasbackgroundimage & {
      top: rem(6 / 7);
      right: rem(6 / 7);

      .a-icon {
        width: rem(10 / 7);
        height: rem(10 / 7);

        @include pc {
          width: rem(13 / 7);
          height: rem(13 / 7);
        }
      }
    }
  }

  &_content {
    position: absolute;
    top: 163px;
    right: 40px;
    bottom: 40px;
    left: 40px;
    width: auto;
    max-width: 1140px;
    height: max-content;
    max-height: calc(100% - 203px);
    padding: rem(1.5);
    margin: 0 auto;
    overflow: hidden auto;
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-UTILITY-BD-MODAL;
    border-radius: 4px;
    outline: none;
    appearance: none;

    @include spMenu {
      top: calc(50% - 15px);
      right: 15px;
      bottom: 15px;
      left: 15px;
      max-height: calc(100% - 30px);
      padding: 12px;
      transform: translateY(calc(-50% + 15px));
    }

    &-fullbox {
      padding: 0;
    }

    &-fullscreenMobile {
      @include spMenu {
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        max-height: 100%;
        padding: 0;
        border-radius: 0;
      }
    }

    &-centered {
      top: 50%;
      transform: translateY(-50%);
    }

    &-hascheckbox {
      overflow: unset;
    }

    &-hasbackgroundimage {
      right: rem(5 / 7);
      left: rem(5 / 7);
      max-width: fit-content;
      max-height: calc(100vh - #{rem(55 / 7)});
      background-color: $COLOR-UTILITY-TRANSPARENT;
      border: 0;

      @include pc {
        right: rem(16 / 7);
        left: rem(16 / 7);
      }

      @include pc-large {
        max-height: calc(100vh - #{rem(44 / 7)});
      }
    }

    .a-checkbox {
      position: absolute;
      top: calc(100% + #{rem(9 / 14)});
      right: 0;

      @include pc {
        top: calc(100% + #{rem(8 / 7)});
      }

      &_input {
        &:not(:checked) {
          + .a-checkbox_checkmark {
            background-color: unset;
            border-color: $COLOR-WHITE;
          }
        }
      }

      &_label {
        display: inline-flex;
        flex-direction: row-reverse;

        &:hover {
          > .a-checkbox_checkmark {
            border-color: $COLOR-CERULEAN;
          }
        }
      }

      &_checkmark {
        width: rem(8 / 7);
        height: rem(8 / 7);
        border-width: rem(1 / 14);
        border-radius: rem(1 / 14);

        @include pc {
          width: rem(2);
          height: rem(2);
          border-width: rem(1 / 7);
          border-radius: rem(1 / 7);
        }
      }

      .a-text {
        padding-right: rem(13 / 7);
        padding-left: 0;
        font-size: rem(13 / 14);
        line-height: rem(9 / 7);
        color: $COLOR-WHITE;

        @include pc {
          padding-right: rem(19 / 7);
          font-size: rem(1);
          line-height: rem(11 / 14);
        }

        @include pcFixedContent {
          font-size: rem(9 / 7);
          line-height: rem(9 / 7);
        }
      }
    }
  }

  &_body {
    #{$content}-hasbackgroundimage & {
      position: relative;
      max-width: rem(510 / 7);
      max-height: inherit;
      overflow: hidden auto;
      border-radius: rem(5 / 7);

      @include pc-large {
        max-width: rem(548 / 7);
      }
    }
  }
}
