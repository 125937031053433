.m-policybox {
  $root: &;

  &_content {
    #{$root}-horizontal & {
      padding-left: rem(1.5);
    }

    #{$root}-hasexplorer & {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    #{$root}-horizontal#{$root}-hasexplorer & {
      display: block;
    }
  }

  &_description {
    #{$root}-mlkk & {
      font-size: rem(0.85);
    }
  }

  &-horizontal {
    display: flex;

    .a-image {
      flex: 0 0 auto;
    }
  }

  &-center {
    .a-text,
    .a-textlink,
    .a-image {
      text-align: center;
    }
  }

  &-hasexplorer {
    display: flex;
    flex-direction: column;
    height: 100%;

    &#{$root}-horizontal {
      flex-direction: row;
    }
  }

  &-mlkk {
    .a-text {
      font-size: rem(1.28);
    }
  }

  .a-image {
    width: auto;
    height: rem(4.5);
    margin-bottom: rem(0.75);

    @include pc {
      height: rem(6.25);
    }

    &_img {
      height: 100%;
    }
  }

  .a-text {
    @include text-overflow(2);
    min-height: rem(3.75);

    &:not(:last-child) {
      margin-bottom: rem(0.5);
    }
  }

  .a-textlink {
    width: 100%;
    padding-top: rem(16/14);
    margin-top: auto;
    font-size: rem(0.857);
    color: $COLOR-BRIGHT-CERULEAN;
  }
}
