.m-policydetail {
  display: flex;
  align-items: center;

  &_image {
    flex: 0 0 auto;
    width: rem(2);
    height: rem(2);
  }

  &_description {
    padding-left: rem(1.25);
  }

  & + & {
    margin-top: rem(1.75);
  }
}
