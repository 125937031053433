.o-anchorlinkpanel {
  width: 100%;
  padding-top: rem(1);
  background-color: $COLOR-WHITE;
  border-bottom: 1px solid $COLOR-VERY-LIGHT-PINK;

  &_link {
    display: inline-block;
    padding: 0 rem(1.5) rem(0.75);
    cursor: pointer;

    &:hover,
    &-active {
      .a-text {
        color: $COLOR-CERULEAN;
      }
    }

    &-active {
      position: relative;

      &::before {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background-color: $COLOR-CERULEAN;
      }
    }
  }
}
