.m-cardpichonkun {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  position: relative;
  height: 100%;

  .a-image {
    position: relative;
    overflow: hidden;

    img {
      transition: transform 2.4s $animation;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: $COLOR-UTILITY-BG-IMG-LEADCARDLINK;
      opacity: 0;
      transition: opacity 2.4s $animation;
    }
  }

  &:hover {
    .a-image {
      img {
        transform: scale(1.05);
      }

      &::after {
        opacity: 0.25;
      }
    }
  }

  &_footer {
    display: flex;
    align-items: center;
    padding: rem(1.125);
    cursor: pointer;
    background-color: $COLOR-WHITE-FOUR;
    border: solid 1px $COLOR-WHITE-TWO;
    border-top: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .a-icon {
      width: rem(1.25);
      height: rem(1.25);
    }

    .m-heading_body {
      padding-left: rem(0.65);
      margin: 0;
    }
  }
}
