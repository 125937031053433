.a-textlink {
  display: inline-block;
  font-size: rem(1);
  color: $COLOR-CERULEAN;
  text-decoration: none;
  cursor: pointer;

  .a-icon {
    margin: 0 0 rem(0.1875) rem(0.5);
  }

  &-disabled {
    color: $COLOR_WHITE;
    cursor: not-allowed;
    opacity: 0.6;
  }
}
