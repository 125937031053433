.p-productdetail {
  $root: &;
  $featurepanel: #{$root}_featurepanel;

  margin-bottom: 40px;

  @mixin withbacground {
    padding-bottom: 40px;
    margin: 0;

    @include pc {
      padding-bottom: rem(3.75);
    }
  }

  @include pc {
    margin-bottom: rem(3.75);
  }

  &-nomarginbottom {
    margin-bottom: 0;
  }

  &_featurepanel {
    position: relative;

    .o-anchorlinkpanel {
      padding-top: 0;
      transition: all ease-in-out 0.3s;

      .container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;

        @include spMenu {
          align-items: center;
          justify-content: center;
        }
      }

      &_link {
        padding-top: rem(16/14);
        padding-bottom: rem(12/14);
      }

      &-fixed {
        position: fixed;
        top: 107px;
        z-index: z('productdetailpage', 'anchorlinkpanel');
        border: 0;
        box-shadow: $COLOR-UTILITY-TXT-SLIDE-INACTIVE 0px 0px 5px 0px;

        @include spMenu {
          top: 50px;
        }

        &-top {
          top: 0;
        }
      }
    }

    &-multicatalogues {
      .o-anchorlinkpanel_link {
        @include spMedium {
          padding-right: rem(15/14);
          padding-left: rem(15/14);
        }

        @include sp {
          padding-right: rem(10/14);
          padding-left: rem(10/14);
        }
      }
    }
    &-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;

      @include sp {
        display: block;
      }
    }
  }

  &_catalogue {
    position: relative;
    display: contents;
    max-width: fit-content;
    margin-left: rem(10/14);

    @include sp {
      flex-basis: 100%;
    }

    .a-button {
      padding: rem(9/14) rem(16/14) rem(8/14) rem(20/14);
      margin: rem(7/14) rem2(3);
      border: 0;
      border-radius: 8px;

      &:not(.a-button:hover):not(.a-button:focus):not(.a-button:active) {
        background-color: $COLOR-AZURE;
      }

      &:focus {
        + #{$root}_catalogueoptions {
          visibility: visible;
          opacity: 1;
        }
      }

      > span {
        overflow: hidden;
        font-size: rem(1);
        line-height: rem(19/14);

        @include u-fw-r;

        @include pcFixedContent {
          font-size: rem(16/14);
        }
      }

      &_iconwrapper {
        > .a-icon {
          #{$featurepanel}-multicatalogues & {
            width: rem(12.1/14);
            height: rem(7/14);

            @include spMedium {
              width: rem(11/14);
            }

            @include sp {
              width: rem(12.1/14);
            }
          }
        }

        #{$featurepanel}-multicatalogues & {
          margin-right: 0;
          margin-left: rem(8/14);
        }
      }

      #{$featurepanel}-multicatalogues & {
        flex-direction: row-reverse;
        padding-right: rem(24/14);
        padding-left: rem(24/14);

        @include spMedium {
          padding-right: rem(12/14);
          padding-left: rem(12/14);
        }

        @include sp {
          padding-right: rem(24/14);
          padding-left: rem(24/14);
        }
      }
      @include sp {
        width: 100%;
      }
    }
  }

  &_catalogueSeries {
    .a-button {
      #{$featurepanel}-multicatalogues & {
        flex-direction: row;
        padding-right: rem(24/14);
        padding-left: rem(24/14);

        @include spMedium {
          padding-right: rem(12/14);
          padding-left: rem(12/14);
        }

        @include sp {
          padding-right: rem(24/14);
          padding-left: rem(24/14);
        }
      }
      .a-button_iconwrapper {
        > .a-icon {
          width: rem(1);
          height: rem(1);
        }
        padding-right: rem(10/14);
      }
    }
  }

  &_catalogueoptions {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    width: max-content;
    max-width: rem(500/14);
    overflow-x: hidden;
    visibility: hidden;
    background-color: $COLOR-WHITE;
    border-radius: rem(18/14);
    box-shadow: 0 0 rem(10/14) 0 $COLOR-UTILITY-TAB-SHADOW;
    opacity: 0;
    transition: visibility;

    @include spMedium {
      max-width: rem(550/14);
    }

    @include sp {
      right: unset;
      left: 0;
      max-width: rem(350/14);
    }
  }

  &_catalogueitem {
    display: flex;
    align-items: baseline;
    padding: rem(17/14) rem(16/14) rem(17/14) rem(20/14);
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: $COLOR-ALICE-BLUE;
    }

    .a-icon {
      flex-shrink: 0;
      width: rem(12/14);
      height: rem(12/14);
      margin-right: rem(8/14);
    }

    .a-text {
      font-size: rem(1);
      color: $COLOR-AZURE;

      @include pcFixedContent {
        font-size: rem(16/14);
      }
    }
  }

  .m-breadcrumbs {
    @include sp {
      display: none;
    }
  }
}

.p-detail360wrapper {
  position: relative;

  &_panel {
    @include sp {
      display: flex;
      justify-content: center;
    }
  }

  &_btnseries {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    padding: 5.5px 10px 7.5px;
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-BRIGHT-CERULEAN;
    border-radius: 8px;

    &_icon {
      position: relative;
      padding-top: 4px;
    }

    &_iconText {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      font-size: 13px;
      font-weight: 500;
      color: $COLOR-CERULEAN;
    }

    &_text {
      margin-top: 4px;
      margin-left: rem(1);
      font-weight: 500;
      color: $COLOR-CERULEAN;
    }
  }

  &_btnserieschild {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5;
    // background-color: $COLOR-WHITE;
    padding: 6px 5px 7.5px;
  }

  &_modalbodywrapper {
    display: flex;
    justify-content: center;
    background-color: rgb(153, 153, 153);
  }
}
