.m-specificationcheckbox {
  $root: &;

  label {
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    input {
      position: absolute;
      pointer-events: none;
      opacity: 0;

      &:checked~#{$root}_inner {
        color: $COLOR-WHITE;
        background-color: $COLOR-CERULEAN;
        border-color: $COLOR-CERULEAN;

        >.a-icon {
          display: block;
        }
      }
    }

    #{$root}_inner {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: rem(1) rem(3.5);
      font-size: rem(1);
      color: $COLOR-BLACK;
      text-align: center;
      cursor: pointer;
      border: 1px solid $COLOR-GREYISH;
      border-radius: 6px;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        color: $COLOR-WHITE;
        background-color: $COLOR-CERULEAN;
        border-color: $COLOR-CERULEAN;
      }

      .a-icon {
        position: absolute;
        top: 50%;
        left: rem(1);
        display: none;
        transform: translateY(-50%);
      }
    }
  }
}