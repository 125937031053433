.o-map {
  width: 100%;
  height: 100%;

  &_infowindow {
    max-width: rem(15.625);
  }

  &_marker {
    &title {
      margin-bottom: 4px;
    }

    &description .a-text {
      font-size: rem(1);
      color: $COLOR-GREYISH-BROWN;
    }

    &phone {
      font-size: rem(1);
      @include u-fw-b;
    }
  }

  .gm-ui-hover-effect,
  .gm-style-mtc,
  .gm-fullscreen-control,
  .gm-svpc,
  .gmnoprint,
  .gm-style-cc {
    display: none !important;
  }
}
