@import './mixins';
@import '../functions';

/* Common styles */
:root {
  --color-text: rgb(51, 51, 51);
  --color-text2: rgb(102, 102, 102);
  --color-footer: rgb(48, 53, 58);
  --color-blue: rgb(0, 151, 224);
  --color-blue2: rgb(84, 195, 241);
  --color-darkblue: rgb(30, 73, 107);
  --color-gray: rgb(102, 102, 102);
  --color-gray2: rgb(181, 181, 182);
  --color-light-blue: rgb(229, 242, 255);
  --color-light-blue2: rgb(247, 252, 255);
  --color-light-gray: rgb(244, 244, 244);
  --header-height: 80px;
  --header-height-sp: 56px;
  --footer-height: 80px;
  --footer-height-sp: 118px;
}

.p-marutto {
  font-size: 16px;
  scroll-behavior: smooth;
}

// html ~ .p-marutto {
//   font-size: 62.5%;
//   scroll-behavior: smooth;
// }

// body {
//   display: flex;
//   flex-direction: column;
//   margin: 0;
//   font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
//     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
//   color: var(--color-text);
//   background-color: rgb(255, 255, 255);
// }

main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(100vh - var(--footer-height));

  @include sp {
    min-height: calc(100vh - var(--footer-height-sp));
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1.5;
}

p {
  margin: 0;

  font: {
    size: 16px;
    weight: 400;
  }

  line-height: 26px;
}

figure {
  margin: 0;
}

ul,
ol,
dl,
dd {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

/* メインコンテンツ内枠 */
.inner {
  width: 100%;
  padding: 100px 15px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1170px;
  }

  @include tablet {
    padding: 80px 40px;
  }

  @include tablet-min {
    padding: 60px 20px;
  }
}

/* 見出し h2 h3 */
.headline1 {
  margin-bottom: 80px;

  font: {
    size: 32px;
    weight: 500;
  }

  text-align: center;

  @include tablet {
    margin-bottom: 60px;
    font-size: 30px;
  }

  @include sp {
    margin-bottom: 40px;
    font-size: 26px;
  }

  > span {
    position: relative;

    &:before {
      position: absolute;
      bottom: -8px;
      left: -15px;
      display: block;
      width: calc(100% + 30px);
      height: 3px;
      content: '';
      background: var(--color-blue);
    }
  }
}

.headline2 {
  margin-bottom: 60px;
  color: var(--color-blue);

  font: {
    size: 28px;
    weight: 500;
  }

  @include sp {
    margin-bottom: 30px;
  }
}

/* header */
.marutto-header {
  padding: {
    right: 70px;
    left: 70px;
  }
  position: fixed;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;

  @include tablet {
    padding: {
      right: 20px;
      left: 20px;
    }

    height: 56px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 80px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    opacity: 0.75;

    @include tablet {
      height: 56px;
    }
  }

  &__logo {
    padding: {
      top: 23px;
      bottom: 23px;
    }

    flex-shrink: 0;

    @include sp {
      padding: {
        top: 12px;
        bottom: 10px;
      }
    }
  }

  &__nav {
    @include tablet {
      position: fixed;
      top: 56px;
      right: -350px;
      z-index: 100;
      width: 350px;
      overflow-y: scroll;
      visibility: hidden;
      background: rgb(255, 255, 255);
      transition: 0.3s;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &.is-open {
        right: 0;
        visibility: visible;
      }
    }

    @include sp {
      right: -100vw;
      width: 100%;
    }
  }

  &__nav-list {
    display: flex;

    @include tablet {
      display: block;
      padding: 20px 30px 40px;
    }

    > li {
      padding: {
        right: 20px;
        left: 20px;
      }

      font: {
        size: 16px;
        weight: 500;
      }

      @include pc-big {
        padding: {
          right: 30px;
          left: 30px;
        }
      }

      @include tablet {
        padding: 0;
        border-bottom: 1px solid var(--color-gray2);

        background: {
          position: center right;
          image: url('~assets/marutto/img/common/arrow_right_black.svg');
          repeat: no-repeat;
        }
      }

      &:last-child {
        padding-right: 0;
      }

      > a,
      > p {
        padding: {
          top: 23px;
          bottom: 23px;
        }

        display: block;
        font-weight: 500;
        line-height: 1.5;
        text-decoration: none;
        transition: 0.5s;

        @include sp {
          padding: {
            top: 15px;
            bottom: 15px;
          }
        }

        @include hover {
          color: var(--color-blue);
        }
      }
    }
  }

  &__wraplang {
    .a-language {
      padding-top: 23px;
      padding-bottom: 23px;
    }

    // @include tablet {
    //   display: none;
    // }
  }

  &__trigger {
    display: none;

    @include tablet {
      position: absolute;
      top: 18px;
      right: 18px;
      z-index: 110;
      display: block;
      width: 20px;
      height: 18px;
      cursor: pointer;

      > span {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 3px;
        background: var(--color-text);
        border-radius: 5px;
        transition: all 0.4s;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: 50%;
        }

        &:nth-of-type(3) {
          top: 100%;
        }
      }

      &.is-active {
        > span {
          &:nth-of-type(1) {
            top: 50%;
            transform: rotate(225deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            top: 50%;
            transform: rotate(-225deg);
          }
        }
      }
    }
  }
}

//.header

/* footer */
.marutto-footer {
  height: var(--footer-height);
  // height: var(--footer-height);
  background-color: var(--color-footer);
  border-top: 5px solid var(--color-blue);

  @include sp {
    height: var(--footer-height-sp);
  }

  &__inner {
    padding: {
      top: 24px;
      bottom: 26px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include sp {
      flex-direction: column;
      justify-content: center;

      padding: {
        top: 20px;
        bottom: 20px;
      }
    }
  }

  &__copyright {
    font-size: 12px;
    color: rgb(255, 255, 255);

    @include sp {
      order: 2;
    }
  }

  &__link {
    display: block;
    padding: 10px 15px;
    font-size: 12px;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    transition: 0.5s;

    @include sp {
      order: 1;
      margin-bottom: 10px;
    }

    @include hover {
      color: var(--color-blue);
      border-color: var(--color-blue);
    }
  }
}

//.footer

/* ページトップへ戻るボタン */
main {
  .btn-top {
    position: fixed;
    right: 0;
    bottom: 50px;
    display: flex;
    justify-content: flex-end;

    padding: {
      right: 50px;
      bottom: 50px;
    }

    @include tablet {
      padding: {
        right: 30px;
        bottom: 30px;
      }
    }

    @include sp {
      padding: {
        right: 20px;
        bottom: 20px;
      }
    }
  }
}

/* Contact us 固定バナー */
.btn-contact {
  position: fixed;
  right: 30px;
  bottom: 180px;
  z-index: 10;
  opacity: 0;
  transition: 0.5s;

  @include tablet {
    right: 15px;
    bottom: 85px;
  }

  &.is-show {
    display: block;
    opacity: 1;
  }

  &.is-hide {
    display: none;
  }

  &.is-stop {
    bottom: 220px;

    @include tablet {
      bottom: 200px;
    }
  }

  &__link {
    display: grid;
    width: 110px;
    height: 80px;
    background: rgb(255, 255, 255);
    border: 2px solid var(--color-darkblue);
    border-radius: 5px;
    place-content: center;

    figure {
      img {
        margin: {
          right: auto;
          left: auto;
        }
      }

      figcaption {
        margin: {
          top: 4px;
        }

        color: var(--color-darkblue);

        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: -9px;
    right: -9px;
    cursor: pointer;
  }
}

/* mv 下層ページ共通 */
.mv-common {
  display: grid;
  align-items: center;
  width: 100%;
  height: 360px;

  background: {
    position: center center;
    repeat: no-repeat;
    size: cover;
  }

  @include tablet {
    height: 300px;
  }

  @include sp {
    height: 220px;
  }

  &__inner {
    padding: {
      top: var(--header-height);
      bottom: 0;
    }

    @include tablet {
      padding-top: var(--header-height-sp);
    }
  }

  &__title {
    color: var(--color-blue);

    text-align: left;

    font: {
      size: 30px;
      weight: 500;
    }

    @include sp {
      font-size: 24px;
      text-shadow: 0px 0px 10px rgba(255, 255, 255, 1), 0px 0px 10px rgba(255, 255, 255, 1);
    }
  }

  &__category {
    font-size: 22px;
    color: var(--color-blue);
    text-align: left;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 1), 0px 0px 4px rgba(255, 255, 255, 1);

    @include sp {
      font-size: 16px;
      color: rgb(12, 133, 190);
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 1), 0px 0px 4px rgba(255, 255, 255, 1),
        1px 0px 3px rgba(255, 255, 255, 0.8);
    }
  }
}
