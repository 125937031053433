.m-imagesolution {
  $root: &;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  position: relative;
  width: 100%;

  &_option {
    position: absolute;
    z-index: z('imagesolution', 'option');
    text-align: center;
    cursor: pointer;

    &-active {
      z-index: z('imagesolution', 'optionactive');
    }
  }

  &_title {
    position: relative;
    padding: rem(0.5) rem(1.5);
    font-size: rem(1.25);
    color: $COLOR-CERULEAN;
    text-align: center;
    background: $COLOR-WHITE;
    border-radius: 0.25rem;
    transition: all 0.4s $animation;
    @include u-fw-m;

    &:before {
      position: absolute;
      bottom: 0;
      left: calc(50% - #{rem(0.5)});
      content: '';
      border-top: 10px solid $COLOR-WHITE;
      border-right: rem(0.5) solid transparent;
      border-left: rem(0.5) solid transparent;
      transition: all 0.4s $animation;
      transform: translateY(10px);
    }

    @include sp {
      padding: rem(0.25) rem(0.75);
      font-size: rem(0.75);
    }

    #{$root}_option-active & {
      color: $COLOR-WHITE;
      background-color: $COLOR-CERULEAN;

      &::before {
        border-top-color: $COLOR-CERULEAN;
      }
    }
  }

  &_index {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem(3);
    height: rem(3);
    padding: rem(1);
    margin-top: rem(1);
    font-size: rem(1.75);
    color: $COLOR-CERULEAN;
    background-color: $COLOR-WHITE;
    border: solid 5px $COLOR-WHITE;
    border-radius: 50%;
    transition: all 0.4s $animation;
    @include u-fw-m;

    @include sp {
      width: rem(1.5);
      height: rem(1.5);
      padding: rem(0.5);
      font-size: rem(1);
      border-width: 2px;
    }

    #{$root}_option-active & {
      color: $COLOR-WHITE;
      background-color: $COLOR-CERULEAN;
    }
  }
}
