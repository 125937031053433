/* This function should be used for font-size, padding, margin,...
--------------------------------------------------------- */
@function rem($rem) {
  @if not $rem {
    @error "Missing argument $rem";
  }

  @return (14/16) * $rem + rem;
}

@function rem2($rem) {
  @if not $rem {
    @error "Missing argument $rem";
  }

  @return ($rem/16) + rem;
}
