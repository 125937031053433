.o-mappanel {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  display: flex;
  flex-wrap: wrap;

  &_leftWrapper {
    position: relative;
  }

  &_locationLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $COLOR-UTILITY-BG-SLIDE-WHITE;
  }

  &_wrapmap {
    width: 70%;
    height: rem(40.625);

    @include spMenu {
      width: 100%;
    }
  }

  &_locationlist {
    width: 30%;
    height: rem(40.625);
    background-color: $COLOR-WHITE-SEVEN;

    @include spMenu {
      width: 100%;
      height: 100%;
    }

    #{$root}-notab & {
      height: 100%;
    }
  }

  &_wraplocation {
    height: 100vh;
    max-height: rem(27.3);
    overflow-y: auto;

    @include spMenu {
      height: auto;
      max-height: rem(25);
      margin-bottom: 10px;
    }

    #{$root}-notab & {
      max-height: rem(34.6);
    }
  }

  &_items {
    padding: rem(1) rem(2);
    cursor: pointer;
    transition: background-color 0.6s $animation;

    &:hover {
      background-color: $COLOR-UTILITY-BG-LOCATION-HOVER;
    }
  }

  &_title {
    font-size: rem(1);
    color: $COLOR-CERULEAN;
    text-transform: uppercase;
    @include u-fw-b;
  }

  &_description {
    margin: rem(0.25) 0;
    font-size: rem(1);
    color: $COLOR-BLACK;
  }

  &_phone {
    font-size: rem(1);
    color: $COLOR-CERULEAN;
    @include u-fw-b;
  }

  &_search {
    position: relative;

    .a-input {
      padding: 0 rem(2);
      margin: rem(1.5) 0;

      input {
        #{$root}:not(#{$root}-notab) & {
          padding-right: rem(3.125);
        }
      }
    }
  }

  &_wrapcities {
    width: 100%;
    padding: 0 rem(2);
    margin-bottom: rem(1);
  }

  &_locationicon {
    position: absolute;
    top: 0;
    right: rem(2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: rem(3.125);
    height: 100%;
    cursor: pointer;
    background-color: $COLOR-UTILITY-TRANSPARENT;
  }

  .o-tabs {
    .o-tabs_list {
      background-color: $COLOR-WHITE-SEVEN;
    }

    .o-tabs_tabpanel {
      margin-top: 0;
    }
  }
}
