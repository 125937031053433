/*
z-index manager

usage:
1. add z-index definition into $layers
2. call it as follows
   z-index: z("language"); /=> 11
   z-index: z("banner", "content"); /=> 1
--------------------------------------------------------- */

$layers: (
  language: 14,
  carousel: (slide: 1,
    pagination: 1,
    pauseplay: 1,
  ),
  banner: (content: 1,
    servicecontent: 11,
  ),
  carddetail: (inside: 1,
    wrapinfo: 1,
  ),
  header: (logo: 14,
    menubar: 13,
    searchbar: 15,
  ),
  heading: (boxshadowbefore: -1,
  ),
  leadbanner: (image: 1,
  ),
  section: (bghalf: -1,
    leadcardlink: 2,
  ),
  carddownload: (bodybefore: 1,
    zoom: 2,
  ),
  downloadselect: (select: 9,
  ),
  gallery: (thumbborder: 1,
    video: 1,
  ),
  clearable: (text: 1,
    textbefore: -1,
  ),
  cardproducttype: (box: 1,
    after: -1,
  ),
  cardtag: (solution: 1,
  ),
  cardsolution: (thumb: 1,
  ),
  productdetailpage: (anchorlinkpanel: 10,
  ),
  leadcardlink: (linkpanelbefore: -1,
  ),
  heatpumpline: (index: 1,
    indexbefore: -1,
  ),
  table: (fixed: 1,
  ),
  modal: (base: 9000,
    close: 8999,
    normal: 1,
  ),
  sidebar: (base: 12,
  ),
  backtotop: (fixed: 9,
  ),
  floatingContact: (fixed: 9,
  ),
  comparisonbar: (fixed: 8,
  ),
  autocomplete: (result: 1050,
  ),
  imagesolution: (option: 1,
    optionactive: 2,
  ),
  technologystreamer: (technologystreamericon: 1,
  ),
  video: (normal: 1,
    playbutton: 2,
  ),
  productlist: (floatingbutton: 2,
  ),
);

@function map-deep-get($map, $keys...) {
  $value: $map;

  @each $key in $keys {
    $value: map-get($value, $key);
  }

  @return $value;
}

@function z($keys...) {
  @if not variable-exists(layers) {
    @error "`$layers` is not defined.";
  }

  @if not function-exists(map-deep-get) {
    @error "`map-deep-get()` is not available.";
  }

  @return map-deep-get($layers, $keys...);
}