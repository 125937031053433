.m-gallery {
  &_thumbs {
    position: relative;
    padding: 0 calc(#{rem(2.5)} + 15px);
    margin: 0 auto;

    .a-image {
      background-color: $COLOR-WHITE;
    }

    .swiper-slide:hover {
      position: relative;

      &::before {
        position: absolute;
        bottom: 0;
        z-index: z('gallery', 'thumbborder');
        width: 100%;
        height: 2px;
        content: '';
        background-color: $COLOR-CERULEAN;
      }

      .a-image {
        transition: background-color 0.5s;
      }
    }
  }

  &_prev,
  &_next {
    position: absolute;
    top: 50%;
    outline: none;
    transform: translateY(-50%);
    appearance: none;

    &.swiper-button-disabled {
      pointer-events: none;
      cursor: unset;
      opacity: 0.5;
    }
  }

  &_prev {
    left: 0;
  }

  &_next {
    right: 0;
  }

  &_video {
    @include aspectRatio(4, 3);

    iframe,
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      z-index: z('gallery', 'video');
      content: '';
    }

    iframe {
      display: block;
      margin: auto;
    }
  }

  .swiper-wrapper {
    display: flex;
    justify-content: center;
  }

  .swiper-slide {
    cursor: pointer;
  }
}
