@charset "UTF-8";

/* ▼▼▼ vrv_h
======================================================*/
/* 打ち消し */
ul,
ol {
  list-style: none;
}

.g-breadcrumbs-p {
  margin-bottom: 10px;
}

.g-lnav_lv1 {
  display: flex;
  align-items: center;
}

.g-lnav_lv1_el {
  flex: 1;
  float: unset;
  border-right: 1px solid rgb(126, 196, 230);
}

.g-lnav_lv1_el.user_benefit label.pop-up_open,
.g-lnav_lv1_el p.g-lnav_lv1_hd > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0;
  font-size: 18px;
  line-height: 1.1;
  text-align: center;
}

.g-lnav_lv1_el.user_benefit label.pop-up_open {
  position: relative;
  height: 48px;
  color: rgb(0, 151, 224);
  background: rgb(255, 255, 255);
}

.g-lnav_lv1_el.user_benefit a {
  color: rgb(51, 51, 51);
  white-space: normal;
}

.no-touchevents .g-lnav_lv1_el:hover:before,
.g-lnav_lv1_el.g-lnav-active:before,
.g-lnav_lv1_el.g-lnav-cur:before {
  display: none;
}

/* ▼▼ 共通
------------------------------------------*/
.g-container p,
.g-container ul li {
  color: rgb(51, 51, 51);
}

.g-modal_el .t_bold {
  font-weight: bold;
}

.g-modal_el .t_light_blue {
  color: rgb(0, 151, 224);
}

.large {
  font-size: 20px;
}

// .t_bold {
//   font-weight: bold;
// }

.t_center {
  text-align: center;
}

.kome {
  font-size: 12px;
}

.italic {
  font-style: italic;
}

.pconly {
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.flex_box {
  display: flex;
  flex-wrap: wrap;
}

/* 動画 */
.video_wrapper video {
  width: 100%;
}

.content_box {
  padding: 85px 0 70px;
}

.content_box img {
  max-width: 100%;
  height: auto;
}

.content_box .g-container {
  position: relative;
  z-index: 1;
  overflow: visible;
}

.content_box .g-container::before {
  position: absolute;
  top: -85px;
  left: 0;
  z-index: -1;
  width: 281px;
  height: 281px;
  content: '';
  background: url('~assets/landingPageDetail/new_casing_bg_icon_png.png') no-repeat center/cover;
}

.content_box .content_box_title {
  margin-bottom: 40px;
}

.content_box .content_box_title h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 60px;
  line-height: 1.2;
  color: rgb(0, 151, 224);
  text-align: center;
}

.content_box .content_box_title h2 + p {
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

.content_box ul.title_tag_list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content_box ul.title_tag_list li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  padding: 0 20px;
  font-weight: bold;
  line-height: 1.3;
  color: rgb(0, 151, 224);
  background: rgb(237, 249, 254);
  border-radius: 15px;
}

.content_box ul.title_tag_list li {
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.content_box ul.title_tag_list li:not(:last-child) {
  margin-right: 2%;
}

.content_box ul.title_tag_list li img {
  margin-left: -10px;
}

.content_box .video_box {
  margin-bottom: 20px;
}

.content_box ul.col_inner li {
  box-sizing: border-box;
  width: 48%;
  padding: 40px 25px;
  margin: 0 4% 0 0;
  background: rgb(245, 245, 245);
}

.content_box ul#easier_identification_of_failure_factors.col_inner li {
  margin: 0 auto;
  margin-top: 20px;
}

.content_box ul.col_inner > li:not(:nth-child(odd)) {
  margin-right: 0;
  margin-bottom: 0;
}

.content_box ul.col_inner li p:last-child {
  margin-bottom: 0;
}

.content_box ul.col_inner li p.col_inner_title {
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.content_box ul.col_inner li p.col_inner_title span.small {
  display: block;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}

/* モーダルボタン */
.content_box .j-modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 36px;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background: rgb(240, 131, 0);
  border-radius: 50px;
}

.content_box ul.col_inner li .j-modal {
  margin: 0 auto;
}

.content_box .j-modal:hover {
  text-decoration: none;
  opacity: 0.6;
}

.content_box .j-modal::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  display: inline-block;
  width: 6px;
  height: 6px;
  padding: 3px;
  margin: auto;
  content: '';

  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* モーダル */
.g-modal_wrapper,
.g-modal-init .g-modal_box {
  z-index: 99999 !important;
}

.g-modal_prev,
.g-modal_next,
.g-modal_pagination {
  display: none !important;
}

/* 背景gray */
.bg_gray {
  background: rgb(240, 249, 253);
}

.content_box.bg_gray ul.col_inner li {
  background: rgb(255, 255, 255);
}

/* navモーダル */
.popup_on #HeaderShrinking {
  z-index: 0;
}

label.pop-up_open {
  cursor: pointer;
}

#pop-up {
  display: none;
}

.pop-up_overlay {
  display: none;
}

#pop-up:checked + .pop-up_overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup_on {
  overflow: hidden;
}

.pop-up_content {
  padding: 40px;
}

.pop-up_close {
  position: absolute;
  top: 0;
  right: 0;
  width: 39px;
  height: 39px;
  margin: auto;
  font-size: 0;
  cursor: pointer;
}

.pop-up_content_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

ul.pop-up_content_list > li {
  width: 50%;
  margin-bottom: 30px;
}

ul.pop-up_content_list > li .pop-up_title {
  display: flex;
  align-items: center;
}

ul.pop-up_content_list > li .pop-up_title p {
  color: rgb(51, 51, 51);
}

ul.pop-up_content_list > li .pop-up_title p:nth-child(1) {
  width: 96px;
  margin-right: 3%;
}

ul.pop-up_content_list > li .pop-up_title p:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% - 96px);
  font-size: 20px;
}

ul.pop-up_content_list > li .pop-up_title p:nth-child(2) span.large {
  display: inline-block;
  padding: 0;
  margin-left: 7px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  color: rgb(51, 51, 51);
}

ul.pop-up_content_list > li ul {
  margin-bottom: 10px;
}

ul.pop-up_content_list > li ul li {
  position: relative;
  margin-left: 20px;
}

ul.pop-up_content_list > li ul li a {
  padding: 0;
  font-size: 14px;
}

ul.pop-up_content_list > li ul li.pop-up_sub_title {
  margin-bottom: 3px;
  font-size: 16px;
  color: rgb(0, 151, 224);
  cursor: pointer;
}

// ul.pop-up_content_list > li ul li.pop-up_sub_title a {
//   font-size: 16px;
//   color: rgb(0, 151, 224);
// }
ul.pop-up_content_list > li ul li::after {
  position: absolute;
  top: 5px;
  left: -17px;
  width: 5.78px;
  height: 11.56px;
  margin: auto;
  content: '';
  background: url('~assets/landingPageDetail/arrow_blue svg.svg') no-repeat center/cover;
}

ul.pop-up_content_list > li ul li:not(.pop-up_sub_title) {
  padding-left: 10px;
}

ul.pop-up_content_list > li ul li:not(.pop-up_sub_title)::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '-';
}

ul.pop-up_content_list > li ul li:not(.pop-up_sub_title) a {
  color: rgb(51, 51, 51);
}

/* ▼▼ vrvナビ
------------------------------------------*/
#mainVisual {
  max-width: 1400px;
  margin: 0 auto;
  font-size: 0;

  @include spMenu {
    padding-top: 20px;
  }
}

/* ▼▼ メイン
------------------------------------------*/
.vrv_h_series {
  padding: 40px 0;
}

.vrv_h_series h1 {
  margin-bottom: 15px;
}

.vrv_h_series .read {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(13, 111, 184);
  text-align: center;
}

.vrv_h_series .user_benefit_box {
  display: block;
  padding: 40px;
  background: rgb(237, 249, 254);
}

.vrv_h_series .user_benefit_box h2 {
  margin-bottom: 10px;
  font-size: 40px;
  color: rgb(0, 151, 224);
  text-align: center;
}

.vrv_h_series p.vrv_h_sub_nav_title {
  margin-bottom: 25px;
  font-size: 22px;
  text-align: center;
}

.vrv_h_series p.vrv_h_sub_nav_title span {
  font-style: italic;
  font-weight: bold;
}

.vrv_h_series .user_benefit_box label.pop-up_open {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 36px;
  padding-left: 10px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  background: rgb(240, 131, 0);
  border-radius: 50px;
}

.vrv_h_series .user_benefit_box label.pop-up_open::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  width: 6px;
  height: 6px;
  padding: 3px;
  margin: auto;
  content: '';

  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* サブナビ */
ul.vrv_h_sub_nav {
  display: flex;
  margin-bottom: 20px;
}

ul.vrv_h_sub_nav > li {
  width: 25%;
}

ul.vrv_h_sub_nav > li:not(:last-child) {
  /* border-right: 1px solid #E8E8E8; */
  padding-right: 20px;
  margin-right: 20px;
}

ul.vrv_h_sub_nav > li p {
  margin-bottom: 20px;
  text-align: center;
}

ul.vrv_h_sub_nav > li p.for_txt {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}

ul.vrv_h_sub_nav > li .for_txt_top {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}

/* .vrv_h_sub_nav_list li{
		margin: 0 0 10px 20px;
		position: relative;
	}
	.vrv_h_sub_nav_list li a{
		display: inline-block;
		font-weight: bold;
		position: relative;
	}
	.vrv_h_sub_nav_list li::after{
		content: "";
		width: 5.78px;
		height: 11.56px;
		background: url("/-/media/Project/Daikin/daikin_com/products/ac/lineup/vrv/vrv_r/images/arrow_blue svg")no-repeat center/cover;
		margin: auto;
		position: absolute;
		top: 5px;
		left: -17px;
	}
	.vrv_h_sub_nav_list li.vrv_h_sub_nav_list_sub{
		padding-left: 10px;
		position: relative;
	}
	.vrv_h_sub_nav_list li.vrv_h_sub_nav_list_sub::before{
		content: "-";
		position: absolute;
		top: 0;
		left: 0;
	}
	.vrv_h_sub_nav_list li.vrv_h_sub_nav_list_sub a{
		color: #333;
		font-weight: normal;
		font-size: 95%;
	} */

/* ▼▼ New Casing
------------------------------------------*/

/* ▼▼ Energy Savings
------------------------------------------*/
#energy_savings .g-container::before {
  background: url('~assets/landingPageDetail/energy_savings_bg_icon_svg.svg') no-repeat center/cover;
}

/* ▼▼ Design Flexibility
------------------------------------------*/
#design_flexibility .g-container::before {
  background: url('~assets/landingPageDetail/design_flexibility_bg_icon_svg.svg') no-repeat center/cover;
}

#design_flexibility .long_refrigerant_piping {
  max-width: 783px;
  margin: 0 auto;
}

#design_flexibility .long_refrigerant_piping .txt {
  flex: 2;
}

#design_flexibility .long_refrigerant_piping .txt h3 {
  margin-bottom: 30px;
  font-size: 34px;
  line-height: normal;
  color: rgb(0, 151, 224);
}

#design_flexibility .long_refrigerant_piping .txt ul {
  margin-bottom: 20px;
}

#design_flexibility .long_refrigerant_piping .txt ul li {
  position: relative;
  padding-left: 13px;
}

#design_flexibility .long_refrigerant_piping .txt ul li::before {
  position: absolute;
  top: 6px;
  left: 0;
  width: 6px;
  height: 6px;
  content: '';
  background: rgb(0, 151, 224);
  border-radius: 50px;
}

#design_flexibility .long_refrigerant_piping .txt ul li img {
  margin: 5px 0 15px 0;
}

#design_flexibility .long_refrigerant_piping .photo {
  flex: 1;
}

.dots_list_title .max110 {
  padding: 5px;
  color: rgb(255, 255, 255);
  background: rgb(0, 160, 233);
  border-radius: 5px;
}

.dots_list_title .max190 {
  padding: 5px;
  color: rgb(255, 255, 255);
  background: rgb(88, 181, 48);
  border-radius: 5px;
}

/* ▼▼ Easy Installation
------------------------------------------*/
#easy_installation .g-container::before {
  background: url('~assets/landingPageDetail/easy_installation_bg_icon_svg.svg') no-repeat center/cover;
}

#easy_installation #electrical_component_service_window .video_wrapper {
  max-width: 263px;
  margin: 0 auto 17px;
}

/* ▼▼ Comfort
------------------------------------------*/
#comfort .g-container::before {
  background: url('~assets/landingPageDetail/comfort_bg_icon_svg.svg') no-repeat center/cover;
}

/* ▼▼ Heat Recovery
------------------------------------------*/
#heat_recovery .g-container::before {
  background: url('~assets/landingPageDetail/heat_recovery_bg_icon_svg.svg') no-repeat center/cover;
}

/* ▼▼ Reliability
------------------------------------------*/
#reliability .g-container::before {
  background: url('~assets/landingPageDetail/reliability_bg_icon_svg.svg') no-repeat center/cover;
}

#reliability .reliability_inner {
  display: flex;
}

#reliability .reliability_inner .txt {
  flex: 2.5;
  margin-right: 3%;
}

#reliability .reliability_inner .txt p.t_bold {
  font-size: 22px;
}

#reliability .reliability_inner .video_wrapper {
  flex: 1;
}

#heat_recovery .heat_recovery_video {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 30px;
  background: rgb(255, 255, 255);
}

#heat_recovery .heat_recovery_video {
  display: flex;
  align-items: center;
}

#heat_recovery .improves_energy_efficienct {
  max-width: 530px;
  margin: 0 auto 25px;
}

#heat_recovery .improves_energy_efficienct p.balloon {
  position: relative;
  padding: 5px;
  color: rgb(255, 255, 255);
  text-align: center;
  background: rgb(162, 162, 162);
  border-radius: 5px;
}

#heat_recovery .improves_energy_efficienct p.balloon::after {
  position: absolute;
  right: 0;
  bottom: -8px;
  left: 0;
  display: block;
  width: 0;
  height: 0;
  margin: auto;
  content: '';
  border-color: rgb(162, 162, 162) transparent transparent transparent;
  border-style: solid;
  border-width: 8px 8px 0 8px;
}

#heat_recovery ul.improves_energy_efficienct_list {
  box-sizing: border-box;
  max-width: 680px;
  padding: 4% 5% 3%;
  margin: 0 auto 30px;
  border: 1px solid rgb(209, 209, 209);
}

#heat_recovery ul.improves_energy_efficienct_list li {
  position: relative;
  padding-left: 50px;
  list-style-position: inside;
  counter-increment: cnt;
}

#heat_recovery ul.improves_energy_efficienct_list li:not(:last-child) {
  margin-bottom: 30px;
}

#heat_recovery ul.improves_energy_efficienct_list li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: auto;
  font-size: 26px;
  color: rgb(255, 255, 255);
  text-align: center;
  content: counter(cnt);
  border-radius: 50px;
}

#heat_recovery ul.improves_energy_efficienct_list li:nth-child(1)::before {
  background: rgb(0, 151, 224);
}

#heat_recovery ul.improves_energy_efficienct_list li:nth-child(2)::before {
  background: rgb(239, 130, 0);
}

#heat_recovery .col_inner li {
  margin: 0 auto !important;
}

/* ▼▼ For further information, consult your regional Daikin.
------------------------------------------*/
.further_information {
  display: none;
  padding: 40px 0;
}

.further_information h2 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  color: rgb(51, 51, 51);
  text-align: center;
}

ul.further_information_list {
  display: flex;
}

ul.further_information_list li {
  flex: 1;
  margin-right: 3%;
}

ul.further_information_list li:last-child {
  margin-right: 0;
}

ul.further_information_list li p {
  text-align: center;
}

ul.further_information_list li p:last-child {
  margin-bottom: 0;
}

ul.further_information_list li .area_title {
  font-size: 18px;
  font-weight: bold;
  color: rgb(110, 110, 110);
  text-align: center;
  background: rgb(242, 242, 242);
}

ul.further_information_list li p a {
  position: relative;
  font-weight: bold;
  color: rgb(0, 151, 224);
}

ul.further_information_list li p a::after {
  position: absolute;
  top: 0;
  right: -17px;
  bottom: 0;
  width: 5.78px;
  height: 11.56px;
  margin: auto;
  content: '';
}

.movie {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-top: 40px;
}

.movie iframe {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .g-lnav_lv1_el.user_benefit label.pop-up_open,
  .g-lnav_lv1_el p.g-lnav_lv1_hd > a {
    font-size: 16px;
  }

  .pconly {
    display: none !important;
  }

  .g-lnav_lv1_el.user_benefit label.pop-up_open::before {
    position: absolute;
    top: 17px;
    left: 51px;
    display: inline-block;
    width: 5px;
    height: 12px;
    content: '';
    background: url('~assets/landingPageDetail/arrow_blue svg.svg') no-repeat center/cover;
  }
}

/* ▼▼▼ 480px〜768px
======================================================*/
@media screen and (max-width: 768px) {
  /* 打ち消し */
  .g-page-dept .g-lnav + .g-main {
    padding-top: 41px;
  }

  .g-lnav_lv1 {
    display: none;
  }

  .g-lnav_lv1.g-lnav-on {
    display: block;
  }

  .g-lnav_lv1_el.user_benefit label.pop-up_open,
  .g-lnav_lv1_el p.g-lnav_lv1_hd > a {
    display: block;
    height: auto;
    padding: 15px 15px 15px 70px;
    font-size: 12px;
    text-align: left;
  }

  /* ▼▼ 共通
	------------------------------------------*/
  .g-container p {
    margin-bottom: 10px;
  }

  .sponly {
    display: block;
  }

  .mb30 {
    margin-bottom: 20px !important;
  }

  .mb40 {
    margin-bottom: 20px !important;
  }

  .mb45 {
    margin-bottom: 20px !important;
  }

  .mb50 {
    margin-bottom: 25px !important;
  }

  .mb60 {
    margin-bottom: 30px !important;
  }

  .flex_box {
    display: block;
  }

  /* 動画 */
  .video_wrapper.sp_small {
    max-width: 400px;
    margin: 0 auto;
  }

  .content_box {
    padding: 30px 15px;
  }

  .content_box .g-container::before {
    top: -30px;
    left: -40px;
    width: 158px;
    height: 158px;
  }

  .content_box .content_box_title {
    margin-bottom: 30px;
  }

  .content_box .content_box_title h2 {
    margin-bottom: 10px;
    font-size: 38px;
  }

  .content_box ul.col_inner li {
    box-sizing: border-box;
    width: 100%;
    padding: 20px 14px;
    margin: 0 auto 10px;
  }

  .content_box ul.col_inner li p.col_inner_title {
    font-size: 16px;
  }

  .content_box ul.col_inner li p.col_inner_title span.small {
    font-size: 14px;
  }

  .content_box .j-modal {
    width: 134px;
    height: 27px;
    margin: 0 auto;
    font-size: 14px;
  }

  /* モーダル */
  .pop-up_open.sponly {
    position: absolute;
    right: 0;
    z-index: 99999;
    max-height: 40px;
    padding: 11px;
    color: rgb(255, 255, 255);
    background: rgb(0, 151, 224);
  }

  .g-modal_close {
    top: 0;
    width: 35px;
    height: 35px;
    background: rgb(91, 175, 237);
  }

  /* navモーダル */
  .pop-up_content {
    bottom: inherit;
    padding: 50px 20px 20px;
  }

  .pop-up_close {
    top: 0;
    width: 35px;
    height: 35px;
  }

  .pop-up_content_list {
    display: block;
  }

  ul.pop-up_content_list > li {
    width: 100%;
    margin-bottom: 40px;
  }

  ul.pop-up_content_list > li .pop-up_title p:nth-child(1) {
    width: 75px;
  }

  ul.pop-up_content_list > li .pop-up_title p:nth-child(1) img {
    width: 100%;
  }

  ul.pop-up_content_list > li .pop-up_title p:nth-child(2) {
    width: calc(100% - 75px);
    font-size: 18px;
  }

  ul.pop-up_content_list > li .pop-up_title p:nth-child(2) span.large {
    font-size: 22px;
  }

  /* ▼▼ vrvナビ
	------------------------------------------*/

  /* ▼▼ メイン
	------------------------------------------*/
  .vrv_h_series {
    padding: 30px 0;
  }

  .vrv_h_series h1 {
    width: 80%;
    margin: 0 auto 20px;
  }

  .vrv_h_series h1 img {
    width: 100%;
    height: auto;
  }

  .vrv_h_series .user_benefit_box {
    padding: 25px 0;
  }

  .vrv_h_series .user_benefit_box h2 {
    margin-bottom: 5px;
    font-size: 30px;
  }

  .vrv_h_series .read {
    width: 80%;
    margin: 0 auto 40px;
    font-size: 16px;
  }

  .vrv_h_series p.vrv_h_sub_nav_title {
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
  }

  .vrv_h_series .user_benefit_box label.pop-up_open {
    width: 134px;
    height: 27px;
    margin: 0 auto;
    font-size: 14px;
  }

  /* サブナビ */
  ul.vrv_h_sub_nav {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  ul.vrv_h_sub_nav > li,
  ul.vrv_h_sub_nav > li:not(:last-child) {
    position: relative;
    width: 50%;
    /* border:none; */
    padding: 7% 5% 5%;
    margin: 0;
  }

  ul.vrv_h_sub_nav > li:nth-child(1)::before,
  ul.vrv_h_sub_nav > li:nth-child(3)::before {
    position: absolute;
    top: 7%;
    right: 0;
    width: 1px;
    height: 85%;
    content: '';
    background: rgb(232, 232, 232);
  }

  ul.vrv_h_sub_nav > li:nth-child(1)::after,
  ul.vrv_h_sub_nav > li:nth-child(2)::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: rgb(232, 232, 232);
  }

  ul.vrv_h_sub_nav > li p img {
    max-width: 120px;
    margin: 0 auto;
  }

  ul.vrv_h_sub_nav > li p.for_txt {
    margin-bottom: 10px;
    font-size: 18px;
  }

  ul.vrv_h_sub_nav > li p.for_txt::before {
    font-size: 14px;
  }

  .vrv_h_sub_nav_list li {
    margin-bottom: 10px;
  }

  /* ▼▼ Design Flexibility
	------------------------------------------*/
  #design_flexibility .long_refrigerant_piping {
    margin: 0 auto 20px;
  }

  #design_flexibility .long_refrigerant_piping .txt {
    flex: 2;
  }

  #design_flexibility .long_refrigerant_piping .txt h3 {
    margin-bottom: 15px;
    font-size: 26px;
    line-height: normal;
    text-align: center;
  }

  #design_flexibility .long_refrigerant_piping .txt ul {
    margin-bottom: 20px;
  }

  #design_flexibility .long_refrigerant_piping .txt ul li {
  }

  #design_flexibility .long_refrigerant_piping .txt ul li::before {
  }

  #design_flexibility .long_refrigerant_piping .txt ul li img {
    margin: 5px 0 15px 0;
  }

  #design_flexibility .long_refrigerant_piping .photo {
    max-width: 180px;
    margin: 0 auto;
  }

  /* ▼▼ Easy Installation
	------------------------------------------*/
  /* ▼▼ Reliability
	------------------------------------------*/
  #reliability .reliability_inner {
    display: block;
  }

  #reliability .reliability_inner .txt {
    margin: 0 auto 30px;
  }

  #reliability .reliability_inner .txt p.t_bold {
    font-size: 18px;
  }

  #reliability .reliability_inner .video_wrapper {
    max-width: 220px;
    margin: 0 auto 5px;
  }

  /* ▼▼ Comfort
	------------------------------------------*/
  /* ▼▼ Heat Recovery
	------------------------------------------*/
  #heat_recovery .heat_recovery_video {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 30px;
    background: rgb(255, 255, 255);
  }

  #heat_recovery .heat_recovery_video {
    display: flex;
    align-items: center;
  }

  #heat_recovery .improves_energy_efficienct {
    max-width: 530px;
    margin: 0 auto 25px;
  }

  #heat_recovery .improves_energy_efficienct p.balloon {
    font-size: 12px;
  }

  #heat_recovery .improves_energy_efficienct p.balloon::after {
  }

  #heat_recovery ul.improves_energy_efficienct_list {
    box-sizing: border-box;
    max-width: 680px;
    padding: 4% 5% 3%;
    margin: 0 auto 30px;
    border: 1px solid rgb(209, 209, 209);
  }

  #heat_recovery ul.improves_energy_efficienct_list li {
    padding-left: 35px;
  }

  #heat_recovery ul.improves_energy_efficienct_list li:not(:last-child) {
    margin-bottom: 20px;
  }

  #heat_recovery ul.improves_energy_efficienct_list li::before {
    top: 3px;
    bottom: inherit;
    width: 26px;
    height: 26px;
    font-size: 18px;
  }

  /* ▼▼ For further information, consult your regional Daikin.
	------------------------------------------*/
  .further_information {
    padding: 40px 15px 0;
  }

  .further_information h2 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  ul.further_information_list {
    flex-wrap: wrap;
  }

  ul.further_information_list li {
    flex: unset;
    width: 46%;
    margin: 0 6% 10% 0;
  }

  ul.further_information_list li:nth-child(even) {
    margin-right: 0;
  }

  ul.further_information_list li .area_title {
    font-size: 16px;
  }

  ul.further_information_list li p {
    margin-bottom: 10px;
  }

  ul.further_information_list li p a {
    position: relative;
    font-weight: bold;
    color: rgb(0, 151, 224);
  }

  ul.further_information_list li p a::after {
    position: absolute;
    top: 0;
    right: -17px;
    bottom: 0;
    width: 5.78px;
    height: 11.56px;
    margin: auto;
    content: '';
  }
}

.arrow_box li:not(:last-child)::after {
  position: absolute;
  top: 0;
  right: -38px;
  bottom: 0;
  display: block;
  width: 0;
  height: 0;
  margin: auto;
  content: '';
  border-color: transparent transparent transparent rgb(0, 151, 224);
  border-style: solid;
  border-width: 26px 0 26px 26px;
}

// css for Large Capacity Popup
.dots_list_title {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
  font-size: 105%;
  font-weight: bold;
  color: rgb(51, 51, 51);
  text-align: left;

  &::before {
    position: absolute;
    top: 7px;
    left: 0;
    width: 7px;
    height: 7px;
    content: '';
    background: rgb(0, 151, 224);
    border-radius: 50px;
  }
}

.t-newReinforcedPopup_HSeries,
.t-VRTSmartControl_HSeries,
.t-designFlexibilityFirst_HSeries,
.t-designFlexibilitySecond_HSeries,
.t-drainlessMultiBSUnit_HSeries,
.t-slimmerMainPiping_HSeries,
.t-improvedRefrigerantPiping_HSeries,
.t-standard_HSeries,
.t-comfort_HSeries,
.t-sealedElectrical_HSeries {
  &_text {
    position: relative;
    padding-top: 50px;
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.3;
    color: rgb(0, 151, 224);
    text-align: center;

    &::after {
      position: absolute;
      right: 0;
      bottom: -10px;
      left: 0;
      width: 41px;
      height: 4px;
      margin: auto;
      content: '';
      background: url('~assets/landingPageDetail/title_line.svg') no-repeat center/cover;
    }
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      width: 47%;
    }

    @media screen and (max-width: 768px) {
      img {
        width: 100%;
      }
    }
  }
}

.t-largeCapacityPopup_HSeries {
  &_text {
    position: relative;
    padding-top: 50px;
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.3;
    color: rgb(0, 151, 224);
    text-align: center;

    &::after {
      position: absolute;
      right: 0;
      bottom: -10px;
      left: 0;
      width: 41px;
      height: 4px;
      margin: auto;
      content: '';
      background: url('~assets/landingPageDetail/title_line.svg') no-repeat center/cover;
    }
  }

  &_wrap {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      display: block;
    }

    &_img {
      position: relative;
      width: 47%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:not(:last-child) {
        @media screen and (max-width: 768px) {
          margin-bottom: 60px;
        }

        &::after {
          position: absolute;
          top: 0;
          right: -38px;
          bottom: 0;
          display: block;
          width: 0;
          height: 0;
          margin: auto;
          content: '';
          border-color: transparent transparent transparent rgb(0, 151, 224);
          border-style: solid;
          border-width: 26px 0 26px 26px;

          @media screen and (max-width: 768px) {
            top: auto;
            right: 50%;
            bottom: -69px;
            transform: rotate(90deg) translateX(-50%);
          }
        }
      }
    }
  }
}

.t-newReinforcedPopup_HSeries,
.t-VRTSmartControl_HSeries {
  &_description {
    margin-bottom: 30px;
    font-size: 20px;
    // font-weight: 700;
    color: rgb(0, 151, 224);
    text-align: center;
  }
}

.t-VRTSmartControl_HSeries,
.t-designFlexibilityFirst_HSeries,
.t-designFlexibilitySecond_HSeries,
.t-slimmerMainPiping_HSeries,
.t-sealedElectrical_HSeries,
.t-drainlessMultiBSUnit_HSeries,
.t-improvedRefrigerantPiping_HSeries,
.t-standard_HSeries,
.t-comfort_HSeries,
.t-newReinforcedPopup_HSeries,
.t-largeCapacityPopup_HSeries {
  padding: 40px;
  padding-top: 0;

  @include spMenu {
    padding: 16px;
  }

  &_subDescription {
    margin-bottom: 30px;
    // font-weight: 700;
    color: rgb(51, 51, 51);
    text-align: center;
  }
}

.t-designFlexibilityFirst_HSeries {
  &_thumbnail {
    margin-bottom: 20px;
  }

  .dots_list_desc_custom {
    position: relative;
    padding-left: 15px;
    margin-bottom: 17px;
    color: rgb(51, 51, 51);
  }
}

.t-designFlexibilitySecond_HSeries {
  &_thumbnail {
    margin-bottom: 20px;
  }

  &_thumbnailCustom {
    overflow-x: auto;

    @media screen and (max-width: 768px) {
      img {
        max-width: 600px;
      }
    }
  }
}

.t-drainlessMultiBSUnit_HSeries {
  &_wrapFirst {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &_thumbnail {
      width: 29%;

      @media screen and (max-width: 768px) {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
      }
    }
  }

  &_wrapSecond {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    // &_img1,
    // &_img4 {
    //   width: 48%;

    //   @media screen and (max-width: 768px) {
    //     width: 100%;
    //   }
    // }

    &_img2 {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &_img3 {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        text-align: center;

        img {
          width: 60%;
        }
      }
    }
  }

  .dots_list {
    li {
      margin-bottom: 25px;
    }
  }

  .dots_list_desc_custom {
    position: relative;
    padding-left: 15px;
    color: rgb(51, 51, 51);
  }
}

.t-slimmerMainPiping_HSeries {
  &_thumbnail {
    padding-right: 30px;
    padding-left: 30px;

    img {
      margin-bottom: 20px;
    }

    &_img2 {
      overflow-x: auto;

      @media screen and (max-width: 768px) {
        img {
          max-width: 600px;
        }
      }
    }
  }
}

.t-improvedRefrigerantPiping_HSeries {
  // &_thumbnail {
  //   display: flex;
  //   justify-content: space-between;

  //   img {
  //     width: 48%;
  //   }
  // }

  &_thumbnail {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      display: block;
    }

    &_img {
      position: relative;
      width: 47%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:not(:last-child) {
        @media screen and (max-width: 768px) {
          margin-bottom: 60px;
        }

        &::after {
          position: absolute;
          top: 0;
          right: -38px;
          bottom: 0;
          display: block;
          width: 0;
          height: 0;
          margin: auto;
          content: '';
          border-color: transparent transparent transparent rgb(0, 151, 224);
          border-style: solid;
          border-width: 26px 0 26px 26px;

          @media screen and (max-width: 768px) {
            top: auto;
            right: 50%;
            bottom: -69px;
            transform: rotate(90deg) translateX(-50%);
          }
        }
      }
    }
  }
}

.t-standard_HSeries {
  &_thumbnail {
    margin-bottom: 30px;

    &_img1 {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &_img2 {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }
}

.t-comfort_HSeries {
  &_wrapThumbnail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      width: 100%;
    }

    &_img {
      width: 49%;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  &_subTitle {
    margin-top: 30px;
    margin-bottom: 17px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(51, 51, 51);
    text-align: center;
  }

  &_description {
    margin-bottom: 17px;
    color: rgb(51, 51, 51);
  }
}

.t-sealedElectrical_HSeries {
  &_thumbnail {
    text-align: center;
  }
}

// nav

.g-lnav {
  position: relative;
  z-index: 12;
  font-size: 14px;
  background-color: rgb(0, 151, 224);
  transition: all ease-in-out 0.3s;
}

.g-lnav a,
.g-lnav p > span,
.g-lnav_lv1_r span {
  display: block;
  padding: 12px 20px;
  color: rgb(255, 255, 255);
  white-space: nowrap;
}

.g-lnav .g-link {
  font-weight: normal;
}

.g-lnav .g-link:link,
.g-lnav .g-link:visited {
  color: rgb(0, 0, 0);
}

.g-lnav .g-link:before {
  top: 18px;
  left: 15px;
}

.g-lnav_sp {
  display: none;
}

.g-lnav_hd {
  display: none;
}

.g-lnav_lv1 {
  overflow: visible;
}

.g-lnav_lv1_el {
  position: relative;
  float: left;
  cursor: default;
}

.no-touchevents .g-lnav_lv1_el:hover:before,
.g-lnav_lv1_el.g-lnav-active:before,
.g-lnav_lv1_el.g-lnav-cur:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 0;
  height: 0;
  margin: auto;
  content: '';
  border-color: transparent transparent rgb(242, 242, 242) transparent;
  border-style: solid;
  border-width: 0 9px 10px 9px;
}

.g-lnav_lv1_hd {
  margin-bottom: 0;
}

.g-lnav_lv1_hd > a {
  cursor: pointer;
}

.g-lnav_lv1_r {
  float: right;
  font-size: 13px;
}

.no-touchevents .g-lnav_lv1_r:hover:before {
  display: none;
}

.g-lnav_lv1_r ul {
  margin-top: 8px;
  overflow: hidden;
}

.g-lnav_lv1_r li {
  float: left;
  border: 1px solid rgb(255, 255, 255);
}

.g-lnav_lv1_r li:first-child {
  border-right-width: 0;
}

.g-lnav_lv1_r a,
.g-lnav_lv1_r span {
  padding: 4px 20px;
}

.g-lnav_lv1_r a.g-lnav-cur,
.g-lnav_lv1_r span.g-lnav-cur {
  background-color: rgb(3, 121, 170);
}

.g-lnav_lv2 {
  position: absolute;
  display: none;
  background-color: rgb(242, 242, 242);
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
}

.g-lnav-active .g-lnav_lv2 {
  z-index: 1;
}

.g-lnav_lv2 a,
.g-lnav_lv2 p > span {
  padding: 15px 45px 15px 35px;
  color: rgb(0, 0, 0);
}

.g-lnav_lv2_hd {
  display: table;
  width: 100%;
  height: 83px;
  margin-bottom: 0;
  border-bottom: 1px solid rgb(204, 204, 204);
}

.no-touchevents .g-lnav_lv2_hd:hover {
  background-color: rgb(221, 221, 221);
}

.g-lnav-sticky.g-lnav {
  position: sticky;
  top: 49px;
  left: 0;
  z-index: 10;
  width: 100%;
}

.g-lnav-sticky .g-main {
  padding-top: 46px;
}

@media only screen and (max-width: 991px) {
  .g-lnav {
    position: relative;
    width: 100%;
    border-top: 2px solid rgb(186, 186, 186);
    transition: all ease-in-out 0.3s;
  }

  .g-lnav a,
  .g-lnav p > span,
  .g-lnav_lv1_r span {
    white-space: normal;
  }

  .g-lnav.g-lnav-on {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
    height: 100vh;
  }

  .g-lnav_accBtn {
    position: relative;
    font-size: 14px;
  }

  .g-lnav_accBtn:after {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    display: inline-block;
    height: 1em;
    margin: auto;
    overflow: hidden;
    font-family: 'icon';
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    pointer-events: none;
    content: '\EA0E';
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .g-lnav_accBtn-on:after {
    content: '\EA0C';
  }

  .g-lnav_accBtn .g-link:before {
    display: none;
  }

  .g-lnav .g-lnav_accBtn > span {
    padding-right: 40px;
  }

  .g-lnav_accBody {
    position: static;
    padding: 0 15px;
    box-shadow: none;
  }

  .g-lnav a {
    padding: 14px 15px 14px 70px;
  }

  .g-lnav p > span {
    padding: 14px 15px 14px 53px;
  }

  .g-lnav .g-link {
    font-size: 12px;
  }

  .g-lnav .g-link:before {
    top: 17px;
    left: 63px;
  }

  .g-lnav_sp {
    display: inline;
  }

  .g-lnav_hd {
    position: relative;
    display: block;
    font-size: 12px;
    cursor: pointer;
  }

  .no-touchevents .g-lnav_hd {
    cursor: default;
  }

  // custom humbemger
  .g-lnav_hd:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: inline-block;
    width: 28px;
    height: 1em;
    margin: auto;
    overflow: hidden;
    font-family: 'icon';
    font-size: 28px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    pointer-events: none;
    content: '';
    background: url('~assets/landingPageDetail/humbugger.png') no-repeat center/cover;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .g-lnav_hd div {
    padding: 11px 10px;
    margin-left: 45px;
    background-color: rgb(255, 255, 255);
  }

  .g-lnav_lv1 {
    display: none;
  }

  // Custom
  .g-clearfix-open {
    display: block;
  }

  .g-lnav .container {
    padding-right: 0;
    padding-left: 0;
  }

  .g-lnav_lv1_el {
    float: none;
    border-top: 1px solid rgb(204, 204, 204);
  }

  .no-touchevents .g-lnav_lv1_el:hover:before,
  .g-lnav_lv1_el.g-lnav-cur:before {
    display: none;
  }

  .g-lnav_lv1_el.g-lnav-cur .g-lnav_lv1_hd {
    background-color: rgb(1, 132, 196);
  }

  .g-lnav_lv1_hd {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }

  .g-lnav_lv1_hd a:before {
    position: absolute;
    top: 17px;
    left: 51px;
    display: inline-block;
    width: 5px;
    height: 12px;
    content: '';
    background: url('~assets/landingPageDetail/arrow_white svg.svg') no-repeat center/cover;
  }

  .g-lnav_lv1_r {
    padding: 15px;
    font-size: 12px;
  }

  .g-lnav_lv1_r ul {
    display: table;
    margin: 0 auto;
  }

  .g-lnav_lv1_r li {
    float: left;
    border: 1px solid rgb(255, 255, 255);
  }

  .g-lnav_lv1_r li:first-child {
    border-right-width: 0;
  }

  .g-lnav_lv1_r a,
  .g-lnav_lv1_r span {
    position: relative;
    padding: 6px 10px 6px 20px;
  }

  .g-lnav_lv1_r a:before,
  .g-lnav_lv1_r span:before {
    position: absolute;
    top: 9px;
    left: 4px;
    display: inline-block;
    overflow: hidden;
    font-family: 'icon';
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    pointer-events: none;
    content: '\EA07';
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .g-lnav_lv1_r a.g-lnav-cur,
  .g-lnav_lv1_r span.g-lnav-cur {
    padding-left: 10px;
  }

  .g-lnav_lv1_r a.g-lnav-cur:before,
  .g-lnav_lv1_r span.g-lnav-cur:before {
    display: none;
  }

  .g-lnav_lv2 {
    background-color: rgb(243, 243, 243);
  }

  .g-lnav_lv2_hd {
    position: relative;
    height: auto;
  }

  .no-touchevents .g-lnav_lv2_hd:hover {
    background-color: transparent;
  }

  .g-lnav_lv2_list {
    display: block;
    padding: 10px 0;
  }

  .g-lnav_lv2_el {
    position: relative;
  }

  .no-touchevents .g-lnav_lv2_el:hover {
    background-color: transparent;
  }

  .g-lnav_lv2 .g-lnav_accBtn:after {
    right: 0;
  }

  .header-open .g-lnav {
    display: none;
  }

  .g-lnav-sticky .g-lnav.g-lnav-on {
    position: absolute;
  }
}

@media only screen and (max-width: 370px) {
  .g-lnav_lv1_r ul {
    display: block;
  }

  .g-lnav_lv1_r li {
    float: none;
  }

  .g-lnav_lv1_r li:first-child {
    border-right-width: 1px;
    border-bottom-width: 0;
  }
}

.g-main {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 991px) {
  .g-page-dept .g-lnav + .g-main {
    padding-top: 80px;
  }

  .header-open .g-main {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .g-main {
    position: static;
  }

  .g-page-dept .g-main {
    padding-top: 80px;
  }

  .g-page-modal .g-main {
    position: static;
    padding-top: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 769px) {
  .g-page-sidebar .g-main {
    padding-top: 80px;
  }
}

.g-map {
  margin-right: auto;
  margin-left: auto;
}

.g-map iframe {
  border: 0;
}

.g-map-init {
  position: relative;
}

.g-map-init iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.g-media-h,
.g-media-v,
.g-media-v_bg_transmission {
  display: block;
  margin-bottom: 17px;
  overflow: hidden;
}

.g-media_left {
  display: block;
}

.g-media_left img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.g-media_body {
  overflow: hidden;
}

.g-media_body > :last-child {
  margin-bottom: 0;
}

.g-media_hd {
  margin-bottom: 7px;
}

.g-media-h > .g-media_left {
  float: left;
  margin-right: 30px;
  margin-bottom: 0;
}

.g-media-h .g-media_caption {
  margin-top: 5px;
  font-size: 12px;
}

.g-media-v > .g-media_left {
  float: none;
  margin-right: 0;
  margin-bottom: 13px;
}

.g-media-v .g-media_text {
  margin-bottom: 17px;
}

.g-media-v .g-media_caption {
  margin-top: 5px;
  font-size: 12px;
}

.g-media-h.g-media-lg > .g-media_left {
  margin-right: 30px;
}

.g-media-h.g-media-rv > .g-media_left {
  float: right;
  margin-right: 0;
  margin-left: 30px;
}

.g-media-h.g-media-rv.g-media-lg > .g-media_left {
  margin-left: 30px;
}

.g-media-timeline {
  display: table;
  table-layout: fixed;
}

.g-media-timeline > .g-media_item {
  display: table-cell;
  vertical-align: middle;
}

.g-media-timeline > .g-media_item > :last-child {
  margin-bottom: 0;
}

.g-media-timeline > .g-media_item img {
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
}

.g-media-timeline > .g-media_arrow {
  display: table-cell;
  padding: 20px;
  text-align: center;
  vertical-align: middle;
}

.g-media-timeline > .g-media_arrow img {
  margin: auto;
}

.g-media-v_bg_transmission {
  position: relative;
  margin-bottom: 13px !important;
}

.g-media-v_bg_transmission > .g-media_left {
  position: relative;
  z-index: 0;
  margin-bottom: 0;
}

.g-media-v_bg_transmission > .g-media_body {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  min-height: 50%;
  background: no-repeat 0 100% / cover;
}

.g-media-v_bg_transmission > .g-media_body::before {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  content: '';
  background: inherit;
  -webkit-filter: blur(5px) saturate(2);
  -moz-filter: blur(5px) saturate(2);
  -o-filter: blur(5px) saturate(2);
  -ms-filter: blur(5px) saturate(2);
  filter: blur(5px) saturate(2);
}

.g-media-v_bg_transmission > .g-media_body::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(255, 255, 255, 0.8);
}

.g-media-v_bg_transmission > .g-media_body .g-media_body_inner {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 15px 20px 0;
}

@media only screen and (max-width: 480px) {
  .g-media-h.g-media-lg > .g-media_left {
    margin-right: 15px;
  }

  .g-media-h.g-media-rv.g-media-lg > .g-media_left {
    margin-right: 0;
    margin-left: 15px;
  }

  .g-media-h.g-media-mq > .g-media_left {
    float: none;
    width: auto;
    margin: 0 0 20px !important;
  }

  .g-media-h.g-media-mq > .g-media_left img {
    max-width: none;
  }

  .g-media-h.g-media-mq.g-media-ud {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .g-media-h.g-media-mq.g-media-ud > .g-media_left {
    margin: 20px 0 0 !important;
  }

  .g-media-h.g-media-overflow > .g-media_left {
    margin-bottom: 20px;
  }

  .g-media-h.g-media-overflow > .g-media_body {
    overflow: visible;
  }

  .g-media-timeline {
    display: block;
  }

  .g-media-timeline > .g-media_item {
    display: block;
    width: 100%;
  }

  .g-media-timeline > .g-media_item img {
    width: 100%;
  }

  .g-media-timeline > .g-media_arrow {
    display: block;
    padding: 15px;
    text-align: center;
  }

  .g-media-v_bg_transmission > .g-media_left {
    float: left;
    width: 50%;
  }

  .g-media-v_bg_transmission > .g-media_body {
    right: 0;
    left: auto;
    width: 50%;
    height: 100%;
    min-height: 0;
    background-image: none !important;
  }

  .g-media-v_bg_transmission > .g-media_body-sp_bg_gray {
    background: rgb(237, 239, 241);
  }

  .g-media-v_bg_transmission > .g-media_body-sp_bg_darkBlue {
    background: rgb(228, 228, 240);
  }

  .g-media-v_bg_transmission > .g-media_body .g-media_body_inner {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .g-media-v_bg_transmission > .g-media_body::before,
  .g-media-v_bg_transmission > .g-media_body::after,
  .g-media-v_bg_transmission > .g-media_body .g-media_text,
  .g-media-v_bg_transmission:hover > .g-media_body .g-media_text {
    display: none;
  }
}

.wrap_content_box {
  padding-right: 15px;
  padding-left: 15px;
}

.refrigerant_piping_workability_inner,
.outdoor_unit_multi-defrost_function_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(50% - 20px);
  padding: 40px 25px;
  margin-bottom: 40px;
  background: rgb(245, 245, 245);

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.outdoor_unit_multi-defrost_function_inner {
  &:nth-child(2) {
    margin-bottom: 0;

    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}

#refrigerant_piping_workability,
#comfort.content_box ul.col_inner li:first-child {
  padding: 0;
  background: none;
}

.w-100-percent {
  width: 100%;
}
