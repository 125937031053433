.m-statistical {
  $root: &;
  position: relative;
  padding-left: calc(#{rem(1)} + 4px);

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 4px;
    height: 100%;
    content: '';
    background-color: $COLOR-CERULEAN;
  }

  &_count {
    font-size: rem(2.5);
    line-height: 1.33;
    color: $COLOR-CERULEAN;
    @include u-fw-b;

    @include pc {
      font-size: rem(3);
    }
  }

  &_title {
    font-size: rem(1);
    color: $COLOR-DARK-SLATE-BLUE;
    @include u-fw-m;
  }
}
