.m-buttoncontainer {
  display: flex;
  justify-content: center;
  margin-top: rem(3);

  &-nonmargin {
    margin-top: 0;
  }

  &-fullwidth {
    button {
      width: 100%;
    }
  }
}