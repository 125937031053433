.o-footer {
  $root: &;
  $rootInfo: #{$root}_info;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  font-size: rem(1);

  &_top {
    padding: 37px 0 0;
    background-color: $COLOR-GREYISH-BROWN;

    @include pc {
      padding: rem(4.5) 0;
    }

    @include spMenu {
      padding: rem(2.643) 0 rem(0.5);
    }

    @include sp {
      padding-bottom: 0;
    }
  }

  &_logo {
    display: inline-block;
    margin: 0 0 rem(2.286);

    @include pc {
      margin-bottom: rem(3.75);
    }

    @include sp {
      margin-bottom: rem(2.286);
    }

    .a-image {
      max-width: 173px;
    }
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include pcFixedContent {
      flex-wrap: inherit;
      justify-content: space-between;
    }
  }

  &_item {
    .a-textlink {
      @include spMenu {
        font-size: rem(1.143);
      }

      @include sp {
        font-size: rem(1);
      }
    }
  }

  &_bottom {
    padding-top: rem(1.25);
    padding-bottom: rem(1.1875);
    color: $COLOR-WHITE;
    background-color: $COLOR-BLACK;

    a {
      color: $COLOR-WHITE;
    }

    @include tab {
      padding: rem(1.786) 0;
    }
  }

  &_bottomwrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -15px;

    @include tab {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    @include spMenu {
      flex-wrap: wrap;
    }

    >div {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;

      @include tab {
        width: unset;
      }

      @include spMenu {
        width: 100%;
      }
    }

    .a-textlink {
      color: inherit;
      transition: opacity 0.3s $animation;

      @include spMenu {
        font-size: rem(1.143);
      }

      @include sp {
        font-size: rem(1);
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &_wraplang {
    @include pcFixedContent {
      flex: 0 0 70%;
      max-width: 70%;
    }

    @include spMenu {
      margin-bottom: rem(1.643);
    }

    @include sp {
      margin-bottom: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 650px;
      text-align: left;
      list-style-type: none;

      @include tab {
        flex-wrap: nowrap;
        max-width: unset;
      }

      @include spMenu {
        justify-content: space-between;
      }

      @include sp {
        flex-wrap: wrap;
      }

      li {
        position: relative;
        width: 100%;
        min-height: 1px;
        margin-bottom: rem(1.5);
        color: $COLOR-WHITE;

        @include pcFixedContent {
          flex: 0 0 calc(100% / 3);
          margin-bottom: 0;
        }

        @include tab {
          width: unset;
          margin-bottom: 0;
        }

        @include sp {
          width: 100%;
          margin-bottom: rem(1.5);
        }

        &:first-of-type {
          @include tab {
            margin-right: rem(4.571);
          }

          @include sp {
            margin-right: 0;
          }
        }

        &:last-of-type {
          @include tab {
            margin-left: rem(4.571);
          }

          @include sp {
            margin-left: 0;
          }
        }

        .a-language_item {
          @include spMenu {
            font-size: rem(1.143);
          }

          @include sp {
            font-size: rem(1);
          }
        }
      }
    }
  }

  &_wrapsocial {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include pcFixedContent {
      flex: 0 0 30%;
      justify-content: flex-end;
    }

    .a-icon-facebook-white {
      width: rem(2);
      height: rem(2);
    }

    .a-icon-youtube-white {
      width: rem(2.3125);
      height: rem(1.5625);
    }

    ul {
      li {
        display: inline-flex;
        margin-left: rem(1.25);
        list-style: none;

        @include tab {
          margin-left: rem(1.107);
        }
      }
    }

    div:first-of-type {
      @include spMenu {
        font-size: rem(1.143);
      }

      @include sp {
        font-size: rem(1);
      }
    }
  }

  &_info {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px;
    color: $COLOR-WHITE;

    @include pcFixedContent {
      flex: 0 0 14%;
      max-width: 14%;
    }

    @include tab {
      flex: 0 0 calc(100% / 4);
      margin-bottom: rem(1.286);
    }

    @include spMenu {
      flex: 0 0 100%;
      margin-bottom: 0;
    }

    ul {
      list-style-type: none;

      li {
        margin-top: 24px;

        &,
        a {
          color: $COLOR-GREYISH;
          transition: color 0.3s $animation;
        }

        a:hover {
          color: $COLOR-WHITE;
        }

        &:first-child {
          margin-top: 9px;
        }

        &:last-child {
          margin-bottom: rem(1.714);
        }
      }
    }

    #{$root}_item-highlight a {
      font-size: rem(1.125);
      color: $COLOR-WHITE;
      transition: opacity 0.3s $animation;
      @include u-fw-b;

      &:hover {
        opacity: 0.7;
      }
    }

    &title {
      padding: 0;
      margin: rem(1.071) 0;
      font-size: rem(1.125);
      color: $COLOR-WHITE;
      cursor: pointer;
      @include u-fw-b;

      &-disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      a {
        color: inherit;
        transition: opacity 0.3s $animation;

        &:hover {
          opacity: 0.7;
        }
      }

      @include pc {
        margin-top: 0;
      }

      @include spMenu {
        position: relative;
        padding-right: 15px;
        margin-top: rem(1.071);
        font-size: rem(1.143);
      }

      @include sp {
        font-size: rem(1.125);
      }

      #{$rootInfo}-withcontent:not(#{$rootInfo}-nocollapse) & {

        &::before,
        &::after {
          @include spMenu {
            position: absolute;
            content: '';
            background-color: $COLOR-WHITE;
            transition: transform 0.5s ease-out;
          }
        }

        &:before {
          @include spMenu {
            top: calc(50% - 6px);
            right: 6px;
            width: 2px;
            height: 14px;
          }
        }

        &:after {
          @include spMenu {
            top: 50%;
            right: 0;
            width: 14px;
            height: 2px;
          }
        }
      }

      #{$rootInfo}-withcontent#{$rootInfo}-active & {
        &::before {
          @include spMenu {
            transform: rotate(90deg);
          }
        }

        &::after {
          @include spMenu {
            transform: rotate(180deg);
          }
        }
      }

      #{$rootInfo}:last-child & {
        @include spMenu {
          margin-top: rem(1.929);
          margin-bottom: rem(1.643);
          font-size: rem(1.286);
        }

        @include sp {
          margin: rem(1.071) 0;
          font-size: rem(1.125);
        }
      }
    }

    &content {
      position: relative;
      overflow: hidden;

      @include spMenu {
        max-height: 0;
        padding: 0 rem(1.071) 0 rem(0.643);
        transition: max-height 0.5s ease-in-out;
      }

      @include sp {
        padding-left: rem(1.071);
      }

      #{$rootInfo}-active & {
        @include spMenu {
          max-height: 500px;
          transition: max-height 0.5s ease-in-out;
        }
      }

      #{$rootInfo}:last-child & {
        font-size: rem(1.125);

        li {
          &:first-child {
            margin-top: 0;
          }

          .a-textlink {
            @include spMenu {
              font-size: rem(1.286);
            }

            @include sp {
              font-size: rem(1.125);
            }
          }
        }

        @include spMenu {
          height: auto;
          max-height: 100%;
          padding: 0;
        }
      }
    }

    &::after {
      @include spMenu {
        position: absolute;
        bottom: 0;
        width: calc(100% - 30px);
        height: 1px;
        content: '';
        background-color: $COLOR-WARM-GREY-TWO;
      }
    }

    &:last-child::after {
      @include spMenu {
        content: none;
      }
    }

    &:last-child {
      @include u-fw-b;

      @include pcFixedContent {
        flex: 0 0 16%;
        max-width: 16%;
      }

      @include tab {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
      }

      @include spMenu {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: rem(1.571);
        margin-top: rem(1.071);
      }

      @include sp {
        padding-left: rem(1.071);
      }

      ul li {
        @include spMenu {
          margin-top: rem(1.643);
        }

        @include sp {
          margin-top: rem(1.714);
        }
      }
    }
  }

  .a-language_item-active {
    color: $COLOR-WHITE;
  }

  .a-language_item:not(.a-language_item-active):hover {
    color: $COLOR-WHITE-TWO;
  }

  &_dotwrapper {
    position: relative;
    padding: 10px 15px 0 0;
  }

  &_dot {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 0;
    width: 8px;
    height: 8px;
    background-color: $COLOR-VIVID-RED;
    border-radius: 50%;
  }

  .lazyload-wrapper {
    padding: 0;
  }
}