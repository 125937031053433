.m-leadcardlink {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: $WIDTH-CONTAINER-PX;
  padding: 32px 0;
  margin: 0 auto;
  background-color: $COLOR-WHITE;
  border-radius: 3px;

  @include pc {
    padding: rem(2.5);
  }

  @include tab {
    flex-wrap: nowrap;
    padding-right: 0;
    padding-left: 0;
  }

  &_cardlink {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;

    @include tab {
      margin-right: rem(1.5);
    }

    @include tabFixedContent {
      width: calc(100% / 3 - #{rem(1)});
    }

    @include spMenu {
      width: calc(100% / 2 - #{rem(0.75)});
      margin-right: rem(0.857);
    }

    @include sp {
      width: 100%;
      margin-right: 0;
    }

    &:not(:last-child) {
      @include tabFixedContent {
        margin-right: rem(1.5);
      }
    }

    &:nth-child(odd) {
      @include spMenu {
        margin-right: rem(0.857);
      }

      @include sp {
        margin-right: 0;
      }
    }

    &:nth-child(2n + 3) {
      @include spMenu {
        margin-top: rem(1.5);
      }
    }

    &:not(:first-child) {
      @include sp {
        margin-top: rem(1);
      }
    }

    &:last-of-type {
      @include tab {
        margin-top: 0;
        margin-right: 0;
      }
    }

    .a-image {
      position: relative;

      img {
        border-radius: 3px;
        transition: transform 2.4s $animation;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: $COLOR-UTILITY-BG-IMG-LEADCARDLINK;
        border-radius: 3px;
        opacity: 0;
        transition: opacity 2.4s $animation;
      }
    }

    &:hover {
      .a-image {
        img {
          transform: scale(1.05);
        }

        &::after {
          opacity: 0.25;
        }
      }

      #{$root}_wraplink {
        color: $COLOR-WHITE;

        &::before {
          transform: scaleX(1);
          transform-origin: center left;
        }

        >.a-icon {
          background-image: url('~assets/images/icons/circle-arrow-right-white.svg');
        }
      }
    }
  }

  &_wraplink {
    position: absolute;
    bottom: rem(0.5);
    left: rem(0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - #{rem(1)});
    padding: 8px 24px;
    overflow: hidden;
    color: $COLOR-CERULEAN;
    background-color: $COLOR-WHITE;
    border-radius: 6px;
    transition: color 1s $animation, transform 1s $animation;
    transform: translateZ(0);

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: z('leadcardlink', 'linkpanelbefore');
      content: '';
      background-color: $COLOR-CERULEAN;
      border-radius: 3px;
      transition: transform 1s $animation;
      transform: scaleX(0);
      transform-origin: center right;
    }

    @include pc {
      padding: rem(1) rem(1) rem(1) rem(1.5);
    }

    @include tab {
      padding: rem(0.571) rem(1.143);
    }

    @include spMenu {
      bottom: 4px;
      left: 4px;
      width: calc(100% - 8px);
      padding: rem(0.429) rem(0.929);
    }

    @include sp {
      bottom: rem(0.5);
      left: rem(0.5);
      width: calc(100% - #{rem(1)});
      padding: rem(0.571) rem(1.714);
    }

    >.a-icon {
      flex: 0 0 auto;
      margin-left: rem(1);
      transition: background-image 1s $animation;

      @include tab {
        width: 31px;
        height: 31px;
        margin-left: rem(0.714);
      }

      @include spMenu {
        width: 24px;
        height: 24px;
      }

      @include sp {
        width: 35px;
        height: 35px;
      }
    }
  }

  &_label {
    overflow: hidden;
    font-size: 20px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include u-fw-m;

    @include pc {
      font-size: rem(1.5);
      line-height: rem(1.8125);
    }

    @include tab {
      font-size: rem(1.25);
    }

    @include spMenu {
      font-size: rem(0.857);
    }

    @include sp {
      font-size: rem(1.25);
    }

    #{$root}_cardlink-smalllabel & {
      font-size: rem(1.25);

      @include spMenu {
        font-size: rem(0.857);
      }

      @include sp {
        font-size: rem(1.25);
      }
    }
  }

  &-nonpadding {
    padding: 0;
  }
}