.m-video {
  position: relative;
  z-index: z('video', 'normal');
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  @include aspectRatio(851, 490);

  &:not(&-played)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z('video', 'normal');
    width: 100%;
    height: 100%;
    content: ' ';
    background-color: $COLOR-UTILITY-BADGE-SHADOW;
    box-shadow: 0 3px 20px 0 $COLOR-UTILITY-BD-NEW;
    backdrop-filter: blur(2.9px);
  }

  &_reactplayer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &_playbutton {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: z('video', 'playbutton');
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, -50%);

    &:hover {
      transform: translate(-50%, -50%) scale(1.05);
    }

    .a-icon {
      width: rem(9.14);
      height: rem(5.64);

      @include sp {
        width: rem(6.43);
      }
    }
  }

  &-16x9 {
    @include aspectRatio(16, 9);
  }
}
