.m-carousel {
  $root: &;
  position: relative;

  &_slide {
    & img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_pagination {

    #{$root}-outside &,
    #{$root}-inside & {
      display: flex;
      justify-content: center;
    }

    #{$root}-inside & {
      position: absolute;
      bottom: rem(0.6875);

      left: 50%;
      z-index: z('carousel', 'pagination');
      transform: translateX(-50%);

      @include pc {
        bottom: rem(0.75);
      }
    }

    #{$root}-outside & {
      margin-top: rem(1);

      @include pc {
        margin-top: rem(1.5);
      }
    }

    #{$root}-verticalpagination & {
      position: absolute;
      top: 50%;
      right: rem(2);
      z-index: z('carousel', 'pagination');
      transform: translateY(-50%);

      @include sp {
        display: none;
      }
    }

    &-hidden {
      display: none !important;
    }
  }

  &_bullet {
    outline: none;

    #{$root}-outside &,
    #{$root}-inside & {
      display: block;
      width: rem(4);
      height: rem(0.375);
      margin-right: 10px;
      background-color: $COLOR-BLACK;
      border-radius: 3px;
      opacity: 0.3;

      @include sp {
        width: rem(2);
      }
    }

    #{$root}-smallpagination & {
      width: rem(2);
    }

    #{$root}-white & {
      background-color: $COLOR-WHITE;
      opacity: 0.6;
    }

    #{$root}-verticalpagination#{$root}-white & {
      color: $COLOR-WHITE;
      background-color: $COLOR-UTILITY-TRANSPARENT;
    }

    #{$root}-verticalpagination & {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      opacity: 0.4;

      @include u-fw-b;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &_step {
      #{$root}-verticalpagination & {
        font-size: rem(1.25);
      }
    }

    &_halfclip {
      position: absolute;
      right: 0px;
      width: 50%;
      height: 100%;
      overflow: hidden;
      transform-origin: left center;
    }

    &_halfcircle {
      position: absolute;
      right: 0px;
      box-sizing: border-box;
      height: 100%;
      border: solid 2px $COLOR-UTILITY-TRANSPARENT;
      border-top-color: $COLOR-BLACK;
      border-left-color: $COLOR-BLACK;
      border-radius: 50%;
      opacity: 0;
    }

    &_clipped {
      width: 200%;
      opacity: 0;
    }

    &_fixed {
      width: 100%;
      opacity: 0;
      transform: rotate(135deg);
    }

    &-active {

      #{$root}-outside &,
      #{$root}-inside & {
        opacity: 1;
      }

      #{$root}-verticalpagination & {
        opacity: 1;

        #{$root}_bullet_halfclip {
          opacity: 1;
          animation: cliprotate 5s steps(2) infinite;
        }

        #{$root}_bullet_clipped {
          opacity: 1;
          animation: rotate 2.5s linear infinite;
        }

        #{$root}_bullet_fixed {
          opacity: 1;
          animation: showfixed 5s steps(2) infinite;
        }
      }

      #{$root}-verticalpagination#{$root}-stop & {

        #{$root}_bullet_halfclip,
        #{$root}_bullet_clipped,
        #{$root}_bullet_fixed {
          animation: none;
        }
      }

      #{$root}-verticalpagination#{$root}-white & {
        #{$root}_bullet_halfcircle {
          border-top-color: $COLOR-WHITE;
          border-left-color: $COLOR-WHITE;
        }
      }
    }
  }

  &_prev,
  &_next {
    position: absolute;
    top: 50%;
    z-index: z('carousel', 'slide');
    display: none;
    outline: none;
    transform: translateY(-50%);

    @include pcFixedContentCarousel {
      display: block;
    }

    .a-icon {
      #{$root}-bgwhiteslide & {
        background-color: $COLOR-UTILITY-BG-SLIDE-WHITE;
      }

      #{$root}-inside & {
        @include sp {
          width: rem(2);
          height: rem(2);
        }
      }
    }

    #{$root}-inside:not(#{$root}-arrowinside) & {
      display: block;
    }

    #{$root}-inside#{$root}-arrowinside & {
      @include pcFixedContentCarousel {
        display: block;
      }
    }

    &.swiper-button-disabled {
      pointer-events: none;
      cursor: unset;
      opacity: 0.5;
    }
  }

  &_prev {
    left: 0;
    margin-left: rem(2);

    #{$root}-outside & {
      margin-left: rem(-4.5); //NOTE: Width icon + padding design
    }

    #{$root}-inside & {
      @include sp {
        margin-left: rem(0.875);
      }
    }
  }

  &_next {
    right: 0;
    margin-right: rem(2);

    #{$root}-outside & {
      margin-right: rem(-4.5); //NOTE: Width icon + padding design
    }

    #{$root}-inside & {
      @include sp {
        margin-right: rem(0.875);
      }
    }
  }

  &-outside,
  &-inside {
    position: relative;
  }

  &_seemore {
    display: block;
    padding-bottom: rem(1);
    text-align: right;

    @include pc {
      display: none;
      padding-bottom: rem(1.5);
    }
  }

  &_pauseplayarea {
    position: absolute;
    right: rem(0.3125);
    bottom: calc(#{rem(-1.5)} + 15px);
    z-index: z('carousel', 'pauseplay');
    width: rem(3);
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.25s cubic-bezier(0.59, -0.26, 0.33, 1.42);
    transform: translateY(-50%) translateX(-50%);

    &:hover {
      opacity: 1;
    }
  }

  &_play,
  &_pause {
    opacity: 1;

    &-hidden {
      opacity: 0;
    }
  }

  &_strokebg {
    stroke: $COLOR-UTILITY-TRANSPARENT;

    &-hidden {
      stroke: $COLOR-WHITE;
    }
  }

  &_stroke {
    stroke-dasharray: 14.5;
    stroke-dashoffset: 145;
    animation: pauseplay 5s linear infinite paused;
    animation-play-state: running;

    &-hidden {
      animation: none;
      animation-play-state: paused;
    }
  }

  // NOTE: This is for the equal height of each item
  &-withslide {
    .swiper-slide {
      height: auto;
    }
  }

  // NOTES: This is for the draw circle of the verticalpagition modifier
  // @see https://stackoverflow.com/questions/26807610/css-only-animate-draw-circle-with-border-radius-and-transparent-background/28866340
  @keyframes cliprotate {

    // NOTE: Step 1
    0% {
      transform: rotate(0deg);
    }

    // NOTE: Step 2
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(-45deg);
    }

    100% {
      transform: rotate(135deg);
    }
  }

  @keyframes showfixed {
    0% {
      opacity: 0;
    }

    49.9% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  // NOTE: This is a keyframes for Pause/Play
  @keyframes pauseplay {
    from {
      stroke-dashoffset: 0;
    }

    to {
      stroke-dashoffset: 145;
    }
  }
}