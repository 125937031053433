.a-textModifier {
  &-subordered {
    ul {
      margin-top: rem(0.75);
      font-size: rem(1.143);
      list-style: none;

      li {
        position: relative;
        padding-left: rem(10/7);
        margin-top: rem(0.75);

        &::before {
          position: absolute;
          top: 5px;
          left: 0;
          content: '';
          border: 6px solid $COLOR-CERULEAN;
          border-right-color: $COLOR-UTILITY-TRANSPARENT;
          border-bottom-color: $COLOR-UTILITY-TRANSPARENT;
        }
      }
    }
  }
}
