.m-carddetail {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
  color: inherit;
  background-color: $COLOR-WHITE;
  border: 1px solid $COLOR-WHITE-TWO;
  border-radius: 4px;

  &:not(#{$root}-noeffect):hover {
    .a-image>img {
      transform: scale(1.05);
    }
  }

  &:hover {
    .a-image::after {
      opacity: 0.25;
    }
  }

  .a-image {
    position: relative;
    overflow: hidden;

    &>img {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      transition: transform 2.4s $animation;
    }

    &-3x2 {
      height: 100%;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background-color: $COLOR-UTILITY-BG-IMG-LEADCARDLINK;
      opacity: 0;
      transition: opacity 2.4s $animation;
    }
  }

  .a-caption {
    position: absolute;
    bottom: rem(1);
  }

  &_wrapinfo {
    z-index: z('carddetail', 'wrapinfo');
    padding: rem(1) rem(1) 0;
    margin-bottom: rem(3);
    border-top: 0;

    #{$root}-inside & {
      position: absolute;
      bottom: 0;
      padding: rem(1.5);
      margin-bottom: 0;
      background-color: $COLOR-UTILITY-TRANSPARENT;
      border: none;
    }

    #{$root}-news &,
    #{$root}-casestudy & {
      padding: rem(1) 0;
      margin-bottom: 0;
      background-color: $COLOR-UTILITY-TRANSPARENT;
    }
  }

  &_tag {
    .a-tag+.a-tag {
      margin-left: 5px;
    }
  }

  &_title {
    margin: rem(1) 0;

    .a-text {
      #{$root}-shadowtitle & {
        text-shadow: 0px 0px 5px $COLOR-BLACK;
      }

      @include text-overflow(2);
    }

    &-medium .a-text {
      font-size: rem(1.5);
      line-height: rem(1.8125);
    }

    &-large .a-text {
      font-size: rem(2);
      line-height: rem(2.375);
    }

    #{$root}-inside & {
      margin: rem(0.75) 0;
      color: $COLOR-WHITE;
    }

    #{$root}-casestudy & {
      margin: rem(0.5) 0;
    }
  }

  &_description {
    >div {
      font-size: rem(1);
      @include text-overflow(3);
    }

    #{$root}-inside & {
      min-height: 73px;
      padding-bottom: rem(0.75);
      color: $COLOR-WHITE;
    }

    #{$root}-news & {
      padding-bottom: rem(1);
    }

    #{$root}-casestudy & {
      padding-bottom: rem(0.5);
    }
  }

  &-news,
  &-casestudy {
    border: none;
    border-radius: 0;

    .a-caption {
      bottom: 0;
    }
  }

  &-news {
    .a-image {
      border-radius: 3px;
    }

    .a-caption {
      color: $COLOR-BLACK;
    }
  }

  &-casestudy {

    &,
    &>.a-image>img {
      border-radius: 0;
    }
  }

  &-inside {
    border: none;

    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: z('carddetail', 'inside');
      width: 100%;
      height: 45%;
      content: '';
      background-image: $COLOR-UTILITY-GRD-BTT-ZTDN;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .a-image>img {
      border-radius: 3px;
    }

    .a-caption {
      position: relative;
      bottom: 0;
      z-index: z('carddetail', 'inside');
      color: $COLOR-WHITE;
    }
  }

  &-discovery {
    &:before {
      height: 50%;
      background-image: $COLOR-UTILITY-GRD-BTT-ZTN;
    }
  }

  &-equalonspmenu {
    .a-image {
      @include spMenu {
        @include aspectRatio(1, 1);
      }

      &>img {
        @include spMenu {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}