.o-tagpanel {
  display: flex;
  flex-wrap: wrap;

  .a-tag {
    margin-top: rem(1);

    &:not(:last-child) {
      margin-right: rem(0.5);
    }
  }
}
