.o-tabs {
  $root: &;
  $rootTabList: #{$root}_list;
  $animation: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &_list {
    background-color: $COLOR-WHITE-FOUR;
    box-shadow: 0 5px 6px 0 $COLOR-UTILITY-TAB-SHADOW;

    &-button {
      background-color: $COLOR-UTILITY-TRANSPARENT;
      box-shadow: none;

      @include break-max(1023px) {
        margin-top: rem(2);
      }

      .container-tabs {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .swiper-slide {
        margin-right: 16px !important;
      }
    }

    &-centered {
      .swiper-wrapper {
        display: flex;
        justify-content: center;
      }
    }

    &-autowidth {
      .swiper-slide {
        width: max-content;
      }
    }

    #{$root}-typenews & {
      background-color: $COLOR-WHITE;
      border-bottom: 1px solid $COLOR-VERY-LIGHT-PINK;
      box-shadow: none;
    }
  }

  &_listcontainer {
    position: relative;
    padding: 0 calc(#{rem(1.5)} + 8px);

    .swiper-button-next,
    .swiper-button-prev {
      width: rem(1.5);
      height: rem(1.5);
      color: $COLOR-BROWNISH-GREY;
      outline: none;
      transform: translateY(50%);

      &::after {
        font-size: rem(1.5);
      }
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
    }

    #{$rootTabList}-nodirection & {
      padding: 0;
    }
  }

  &_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(1.5) 0;
    cursor: pointer;

    &::after {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      content: '';
      background-color: $COLOR-CERULEAN;
      transition: transform $animation;
      transform: scaleX(0);
    }

    .a-text {
      transition: color $animation;
    }

    .a-toggleicon {
      margin-right: rem(0.75);

      &_icon {
        transition: all $animation;
      }
    }

    &:hover,
    &-actived {
      .a-text {
        color: $COLOR-CERULEAN;
      }

      .a-toggleicon_icon:nth-of-type(1) {
        visibility: hidden;
        opacity: 0;
      }

      .a-toggleicon_icon:nth-of-type(2) {
        visibility: inherit;
        opacity: 1;
      }
    }

    &-actived {
      position: relative;

      &::after {
        transform: scaleX(1);
      }
    }

    #{$root}-typenews &::after {
      height: 2px;
    }

    #{$rootTabList}-autowidth & {
      padding-right: rem(1.5);
      padding-left: rem(1.5);
    }

    #{$root}-typenews & {
      padding-top: rem(0.75);
      padding-bottom: rem(0.75);
    }

    &-button {
      background-color: #F6F6F6;
      border-radius: 6px;
      padding: 20px 28px;
      border: 2px solid transparent;

      @include sp {
        padding: 12px 20px;
      }

      &::after {
        height: 0;
        content: '';
        background-color: unset;
        transition: none;
      }

      &:hover,
      &-actived {
        border: 2px solid #00a1e4;
      }
    }
  }

  &_tabpanel {
    margin-top: 40px;

    @include pc {
      margin-top: rem(3.75);
    }

    #{$root}-typenews & {
      margin-top: rem(2.75);
    }

    &-spacing-40 {
      margin-top: 40px;
    }

    &-spacing-10 {
      margin-top: 10px;
    }
  }
}