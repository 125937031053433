.m-featuredetail {
  $root: &;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &_image {
    width: 75px;

    @include pc {
      width: rem(6.25);
    }
  }

  &_content {
    width: calc(100% - 75px);
    padding-left: 16px;

    @include pc {
      width: calc(100% - #{rem(6.25)});
      padding-left: rem(1.5);
    }
  }

  &_title {
    font-size: rem(1.25);
    @include u-fw-m;
  }

  &_description {
    padding-top: 8px;
    font-size: rem(1);

    @include pc {
      padding-top: rem(1);
    }
  }
}
