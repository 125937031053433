@import './mixins';
@import '../functions';

.industry-solution {
  background: rgb(229, 242, 255);

  p {
    line-height: 1.8;
  }

  .mv-common {
    background-image: url('~assets/marutto/img/industry-solutions/mv.jpg');

    &[data-mv='mv01'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv01.jpg');
    }

    &[data-mv='mv02'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv02.jpg');
    }

    &[data-mv='mv03'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv03.jpg');
    }

    &[data-mv='mv04'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv04.jpg');
    }

    &[data-mv='mv05'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv05.jpg');
    }

    &[data-mv='mv06'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv06.jpg');
    }

    &[data-mv='mv07'] {
      background-image: url('~assets/marutto/img/industry-solutions/mv07.jpg');
    }
  }

  // トップページ 兼 下層ページリンクエリア
  .top-menu {
    flex-grow: 1;
    background: linear-gradient(#fff 0%, var(--color-light-blue) 100%);
    &__title {
      > span {
        @include sp {
          display: inline-block;

          &::before {
            left: 0;
            width: 100%;
          }
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 40px 30px;

      @include sp {
        gap: 30px;
      }
    }

    &__list-item {
      position: relative;
      width: calc(calc(100% - 90px) / 4);
      overflow: hidden;
      border-radius: 5px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

      @include tablet {
        width: calc(calc(100% - 60px) / 3);
      }

      @include tablet-min {
        width: calc(calc(100% - 30px) / 2);
        height: 180px;
      }

      @include sp {
        width: 100%;
        height: 140px;
      }

      > a {
        position: relative;
        display: block;

        @include tablet-min {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        @include hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      figure,
      picture {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        object-fit: cover;
        object-position: center center;
      }
    }

    &__list-name {
      padding: {
        right: 10px;
        left: 10px;
      }

      position: absolute;
      bottom: 0;
      display: grid;
      width: 100%;
      min-height: 60px;

      line-height: 1.3;
      color: rgb(255, 255, 255);
      text-align: center;
      text-shadow: 1px 1px 2px var(--color-blue);
      background: rgba(0, 151, 224, 0.65);
      transition: 0.5s;
      place-items: center;

      font: {
        size: 18px;
        weight: 500;
      }

      @include sp {
        min-height: 48px;
        font-size: 16px;
      }
    }
  }

  // 下層ページ共通
  .about {
    &__wrap {
      position: relative;

      background: {
        position: center center;
        color: rgb(255, 255, 255);
        repeat: no-repeat;
        size: 100% auto;
      }

      .inner {
        display: flex;
        align-items: center;
        gap: 20px 50px;

        @include sp {
          flex-direction: column;
        }
      }

      &[data-type='about01'] {
        background: {
          image: url('/marutto/assets/img/industry-solutions/bg_about01.png');
          position: top center;
          image: url('~assets/marutto/img/industry-solutions/bg_about01.png');
        }
      }

      &[data-type='about02']::before,
      &[data-type='about03']::before,
      &[data-type='about04']::before,
      &[data-type='about05']::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';

        background: {
          position: center center;
          repeat: no-repeat;
          size: cover;
        }

        @include tablet {
          top: 0;
          height: 100%;
        }
      }

      &[data-type='about02']::before {
        background: {
          color: rgb(233, 245, 251);
          image: url('~assets/marutto/img/industry-solutions/bg_about02.png');
        }
      }

      &[data-type='about03']::before {
        background: {
          color: rgb(233, 251, 249);
          image: url('~assets/marutto/img/industry-solutions/bg_about03.png');
        }
      }

      &[data-type='about04']::before {
        background: {
          color: rgb(251, 239, 233);
          image: url('~assets/marutto/img/industry-solutions/bg_about04.png');
        }
      }

      &[data-type='about05']::before {
        background: {
          color: rgb(233, 238, 251);
          image: url('~assets/marutto/img/industry-solutions/bg_about05.png');
        }
      }

      &[data-type='about01'] .inner {
        padding: {
          top: 80px;
          bottom: 40px;
        }

        @include sp {
          padding-top: 40px;
        }
      }

      &[data-type='about02'] .inner,
      &[data-type='about03'] .inner,
      &[data-type='about04'] .inner,
      &[data-type='about05'] .inner {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    &__text {
      z-index: 1;
      flex-grow: 1;
      width: 60%;

      @include sp {
        order: 2;
        width: 100%;
      }

      h2 {
        margin-bottom: 10px;
        font-size: 32px;
        font-weight: 500;

        @include sp {
          font-size: 24px;
        }

        &[data-color='about02'] {
          color: var(--color-blue);
        }

        &[data-color='about03'] {
          color: rgb(7, 223, 207);
        }

        &[data-color='about04'] {
          color: rgb(224, 74, 0);
        }

        &[data-color='about05'] {
          color: rgb(0, 61, 224);
        }
      }

      h3 {
        margin-bottom: 16px;
        font-size: 22px;
        font-weight: 400;
        color: var(--color-darkblue);

        @include sp {
          font-size: 18px;
        }
      }

      &[data-text='about01'] p {
        font-size: 18px;

        @include sp {
          font-size: 16px;
        }
      }
    }

    &__img {
      width: 40%;

      @include sp {
        order: 1;
        width: 100%;
      }

      img {
        max-width: 480px;
        margin: 0 auto;

        @include tablet {
          max-width: 100%;
        }
      }

      &[data-type='octagon'] {
        z-index: 3;
        width: 25%;

        @include sp {
          width: 100%;
        }

        img {
          max-width: 100%;
          filter: drop-shadow(10px 10px 15px rgba(30, 73, 107, 0.35));

          @include tablet {
            max-width: 90%;
          }

          @include sp {
            max-width: 65%;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  //.about

  .faq {
    background: rgb(255, 255, 255);

    .inner {
      padding-bottom: 60px;

      @include tablet {
        padding-bottom: 40px;
      }

      @include sp {
        padding-bottom: 20px;
      }
    }

    &__list {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &.is-open {
        .faq__question {
          background-image: url('~assets/marutto/img/industry-solutions/arrow_up.svg');

          @include sp {
            background-image: url('~assets/marutto/img/industry-solutions/arrow_up_sp.svg');
          }
        }

        .faq__answer {
          height: 100%;
          opacity: 1;
        }
      }
    }

    &__question {
      display: block;
      padding: 10px 30px;
      list-style: none;

      cursor: pointer;

      border: 1px solid var(--color-gray2);
      transition: 0.5s;

      font: {
        size: 18px;
        weight: 500;
      }

      background: {
        position: center right 30px;
        color: var(--color-light-blue);
        image: url('~assets/marutto/img/industry-solutions/arrow_down.svg');
        repeat: no-repeat;
      }

      @include sp {
        padding: 10px 45px 10px 20px;
        font-size: 16px;

        background: {
          position: center right 15px;
          image: url('~assets/marutto/img/industry-solutions/arrow_down_sp.svg');
        }
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &__answer {
      height: 0;
      overflow: hidden;
      border: 1px solid var(--color-gray2);
      border-top: none;
      opacity: 0;
      transition: 0.25s;
      transform: translateY(0);

      > p {
        padding: 10px 30px;
        font-size: 16px;
        line-height: 1.6;

        @include sp {
          padding: 10px;
          font-size: 14px;
        }
      }
    }
  }

  //.faq
}
