.m-searchpanel {
  &_title > .a-text {
    margin-bottom: rem(1.5);
    font-size: rem(1.75);
    color: $COLOR-WHITE;
  }

  .a-autocomplete {
    margin-bottom: rem(0.75);
  }

  &_keyword {
    .a-text {
      margin-bottom: rem(0.75);
      color: $COLOR-WHITE;
    }

    .a-tag {
      margin-bottom: rem(0.375);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: rem(0.75);
      }
    }
  }
}
