.a-checkbox {
  $root: &;

  position: relative;

  &_label {
    display: inline-block;
    cursor: pointer;
  }

  &_input {
    position: absolute;
    pointer-events: none;
    opacity: 0;

    &:checked+#{$root}_checkmark {
      background-color: $COLOR-CERULEAN;
      border-color: $COLOR-CERULEAN;

      &:after {
        display: block;
      }
    }
  }

  &_checkmark {
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    width: rem(1.25);
    height: rem(1.25);
    background-color: $COLOR-WHITE;
    border: solid 1px $COLOR-WARM-GREY-THREE;
    border-radius: 5px;
    transform: translateY(-50%);

    #{$root}-withoutlabel:hover &,
    #{$root}_label:hover & {
      border-color: $COLOR-CERULEAN;
    }

    &:after {
      position: absolute;
      top: 2px;
      left: calc(50% - 1px);
      display: none;
      width: 50%;
      height: 80%;
      content: '';
      border: 2px solid $COLOR-UTILITY-TRANSPARENT;
      border-right-color: $COLOR-WHITE;
      border-bottom-color: $COLOR-WHITE;
      transform: rotate(45deg) translateX(calc(-50%));
    }
  }

  .a-text {
    padding-left: rem(2.1875);
  }
}