.m-timeline {
  $root: &;
  display: table;
  width: 100%;

  &_field {
    position: relative;

    @include tabFixedContent {
      display: flex;
    }

    &:before {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 39px;
      height: 100%;
      content: '';
      border-right: solid 4px $COLOR-WHITE-THREE;

      @include tabFixedContent {
        width: 274px;
      }
    }

    &:not(:last-child) {
      padding-bottom: rem(2);
    }
  }

  &_wraptitle {
    position: relative;
    display: flex;

    @include tabFixedContent {
      justify-content: flex-end;
      width: 336px;
      padding-right: rem(1.5);
    }
  }

  &_title {
    order: 2;
    padding-left: rem(1.5);
    margin-top: 19px;
    font-size: rem(1.25);
    @include u-fw-b;

    @include tabFixedContent {
      order: inherit;
      padding: 0 rem(1.5) 0 0;
      margin-top: rem(2.2);
    }
  }

  &_icon {
    flex: 0 0 auto;
    width: 75px;
    height: 75px;

    @include tabFixedContent {
      width: rem(6.25);
      height: rem(6.25);
    }

    .a-icon {
      width: 100%;
      height: 100%;
    }
  }

  &_histories {
    padding-left: rem(6.25);

    @include tabFixedContent {
      width: calc(100% - 336px);
      padding-left: 0;
      margin-top: rem(1.75);
    }
  }

  &_year {
    font-size: rem(2);
    color: $COLOR-CERULEAN;
    @include u-fw-b;
  }

  &_description {
    font-size: rem(1);

    &:not(:last-child) {
      padding-bottom: rem(2);
    }
  }
}
