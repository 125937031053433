.a-progressbar {
  position: relative;

  input[type='range'] {
    display: block;
    width: 100%;
    height: 2px;
    padding: 0;
    cursor: pointer;
    background-color: $COLOR-BROWNISH-GREY;
    border-radius: 2px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='range']::-webkit-slider-thumb {
    position: relative;
    z-index: 1;
    width: rem(0.875);
    height: rem(0.875);
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-CERULEAN;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='range']::-moz-range-thumb {
    position: relative;
    z-index: 1;
    width: rem(0.875);
    height: rem(0.875);
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-CERULEAN;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &_percent {
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    background: $COLOR-CERULEAN;
    transform: translateY(-50%);
  }
}
