.a-steps {
  $root: &;

  position: relative;
  display: block;
  width: 100%;
  max-width: rem(53);
  padding: 0;
  margin: auto;

  &_container {
    display: block;
    width: 100%;
    height: 100%;
    list-style-type: none;

    @include tabFixedContent {
      display: flex;
    }
  }

  &_element {
    position: relative;
    width: 30px;
    height: 70px;
    text-align: center;

    &:last-child {
      height: 30px;

      &::after {
        display: none;
      }
    }

    @include tabFixedContent {
      display: flex;
      flex-flow: row wrap;
      align-content: space-between;
      align-items: center;
      justify-content: center;
      width: calc(100% / 7);

      &,
      &:last-child {
        height: auto;
      }
    }

    &::after {
      position: absolute;
      top: 34px;
      width: 1px;
      height: calc(100% - 38px);
      content: '';
      background-color: $COLOR-WHITE-TWO;

      @include tabFixedContent {
        top: 30px;
        right: -30px;
        width: calc(100% - 46px);
        height: 1px;
        transform: translateY(-15px);
      }
    }

    &-passed:after {
      background-color: $COLOR-CERULEAN;
    }
  }

  &_index {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: rem(1);
    color: $COLOR-WHITE;
    text-align: center;
    cursor: pointer;
    background-color: $COLOR-WHITE-TWO;
    border: solid 3px $COLOR-WHITE;
    border-radius: 50%;
    transition: opacity 0.4s ease-in-out;
    @include u-fw-m;

    &:hover {
      opacity: 0.6;
    }

    #{$root}_element-active & {
      background-color: $COLOR-CERULEAN;
    }

    #{$root}_element-passed & {
      text-indent: -9999px;
      background: url('~assets/images/icons/check.svg') center/contain no-repeat;
    }
  }

  &_title {
    display: none;

    #{$root}_element-active & {
      display: block;
      display: none;
      margin-top: rem(0.25);
      font-size: rem(0.75);
      line-height: rem(1);
      color: $COLOR-CERULEAN;
      @include u-fw-m;

      @include tabFixedContent {
        display: block;
      }
    }
  }
}
