.a-termlink {
  .a-textlink {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;

    .a-icon {
      flex: 0 0 auto;
      margin: 0 rem(0.75) 0 0;
    }
  }
}
