.m-textlist {
  $root: &;

  li {
    font-size: rem(1);
  }

  &:not(#{$root}-nonpadding) li {
    &:not(:last-child) {
      margin-bottom: rem(1);
    }
  }

  &-ordered {
    padding-left: 16px;
  }

  &-unordered,
  &-subordered,
  &-addcircle,
  &-note {
    list-style-type: none;
    li {
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        content: '';
      }
    }
  }

  &-unordered {
    li {
      padding-left: calc(#{rem(0.5)} + 4px);

      &::before {
        top: 8px;
        width: 4px;
        height: 4px;
        background-color: $COLOR-BLACK;
        border-radius: 50%;
      }
    }
  }

  &-subordered {
    li {
      padding-left: calc(#{rem(1.25)} + 12px);

      &::before {
        top: 5px;
        border: 6px solid $COLOR-CERULEAN;
        border-right-color: $COLOR-UTILITY-TRANSPARENT;
        border-bottom-color: $COLOR-UTILITY-TRANSPARENT;
      }
    }
  }

  &-addcircle {
    li {
      padding-left: calc(#{rem(1.25)} + 12px);

      &::before {
        top: 2px;
        width: 16px;
        height: 16px;
        background-image: url('~assets/images/icons/order-plus-circle.svg');
      }
    }
  }

  &-note {
    li {
      padding-left: calc(#{rem(1.25)} + 12px);

      &::before {
        top: 2px;
        width: 16px;
        height: 16px;
        background-image: url('~assets/images/icons/note.svg');
      }
    }
  }
}
