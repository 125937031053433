.a-autocomplete {
  $root: &;

  position: relative;

  >input {
    width: 100%;
    height: rem(3);
    padding: rem(0.5) rem(4) rem(0.5) rem(1);
    font-size: rem(1);
    background-color: $COLOR-WHITE;
    border: 1px solid $COLOR-GREYISH;
    border-radius: 6px;
    outline: none;
    appearance: none;

    &:focus+#{$root}_result {
      visibility: visible;
      opacity: 1;
    }
  }

  >.a-icon {
    position: absolute;
    top: 50%;
    right: rem(1);
    cursor: pointer;
    transform: translateY(-50%);
  }

  &_result {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: z('autocomplete', 'result');
    width: 100%;
    padding: rem(0.5) 0;
    margin: 0;
    overflow: hidden;
    color: $COLOR-BLACK;
    visibility: hidden;
    background-color: $COLOR-WHITE;
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 $COLOR-UTILITY-BADGE-SHADOW;
    opacity: 0;
    transition: visibility 0.5s ease;

    #{$root}-round & {
      border-radius: 8px;
    }
  }

  &_resultgroup {
    display: inline-block;
    padding: rem(0.5) rem(1);
    font-size: rem(1.07);
    line-height: rem(2);
    color: $COLOR-WARM-GREY;
    @include u-fw-b;
  }

  &_resultitem {
    position: relative;
    display: block;
    min-height: rem(2);
    padding: rem(0.5) rem(2.14);
    font-size: rem(1);
    line-height: rem(2);
    cursor: pointer;
    background-color: $COLOR-UTILITY-TRANSPARENT;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      color: $COLOR-BLACK;
      background-color: $COLOR-LIGHT-BLUE-TWO;
    }
  }

  &-round {
    >input {
      border-radius: 40px;
    }
  }
}