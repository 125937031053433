.a-icon {
  $root: &;
  display: inline-block;
  width: rem(1.5);
  height: rem(1.5);
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  $icons: 'arrow-right-black',
  'arrow-left-black',
  'arrow-left-blue',
  'arrow-right-white',
  'arrow-left-white',
  'arrow-right-blue',
  'arrow-down-white',
  'search-plus-white',
  'search-black',
  'chevron-right-blue',
  'chevron-right-white',
  'sort-right-solid-gray',
  'plus',
  'minus',
  'wind-solid',
  'download-solid',
  'download-solid-blue',
  'download-solid-white',
  'question-circle-solid',
  'question-circle-blue',
  'exclamation-triangle-solid',
  'award-solid',
  'tools-solid',
  'facebook-white',
  'youtube-white',
  'accessories-policy',
  'conduct-policy',
  'guide-policy',
  'other-service-policy',
  'setting-policy',
  'tick-policy',
  'search-grey',
  'asset-download',
  'circle-arrow-right-blue',
  'circle-arrow-right-white',
  'location-map',
  'close',
  'close-square',
  'close-grey',
  'cricle-location',
  'continuous-growth',
  'speed-up',
  'start-up',
  'chevron-down-blue',
  'chevron-round-down-white',
  'call-made',
  'plus-black',
  'play-white',
  'check-circle-solid',
  'back-to-top',
  'cricle-location-grey',
  'checked-white',
  'back-to-bottom',
  'arrow-back-black',
  'download-white',
  'sort-right-solid-white',
  'gradient-close',
  'bag',
  'gps',
  'icon-360',
  'mail',
  'mail-white',
  'phone-white',
  'collapse-arrow';

  @each $icon in $icons {
    &-#{$icon} {
      background-image: url('~assets/images/icons/#{$icon}.svg');
    }
  }

  &-medium {
    width: rem(2);
    height: rem(2);
  }

  &-large {
    width: rem(2.5);
    height: rem(2.5);
  }

  &-small {
    width: rem(1.145);
    height: rem(1.145);
  }

  &-large-lv2 {
    width: rem(3);
    height: rem(3);
  }
}