.m-construct {
  $root: &;
  display: flex;
  flex-wrap: wrap;

  &_image {
    width: 100%;

    @include pc {
      order: 2;
      width: 50%;
    }
  }

  &_content {
    width: 100%;

    @include pc {
      order: 1;
      width: 50%;
    }

    @include sp {
      margin-top: rem(1);
    }
  }

  &_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include pc {
      padding-right: rem(1.5);
    }
  }

  &_title {
    padding-bottom: rem(0.75);
    font-size: rem(3.25);
    line-height: 1.19;

    @include tab {
      font-size: rem(2.5);
    }

    @include sp {
      font-size: rem(2.13);
    }
  }

  &_description {
    font-size: rem(1.125);
  }

  &_link {
    @include sp {
      margin-top: rem(1);
    }
    .a-icon {
      width: rem(2);
      height: rem(2);
    }
  }
}

.m-constructcarousel {
  &_wrapcontent {
    top: 0;
    display: flex;
    align-items: center;
    margin-bottom: rem(1);

    @include sp {
      flex-wrap: wrap;
    }
  }

  &_navigation {
    position: relative;
    padding: 0 rem(3);
  }

  &_prev,
  &_next {
    position: absolute;
    top: 0;
    left: 0;
    outline: none;

    &.swiper-button-disabled {
      pointer-events: none;
      cursor: unset;
      opacity: 0.5;
    }
  }

  &_next {
    right: 0;
    left: auto;
  }

  &_title {
    margin-right: rem(4.5625);
    font-size: rem(2);
    @include u-fw-m;

    @include sp {
      width: 100%;
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet {
    display: none;
    background-color: $COLOR-UTILITY-TRANSPARENT;
    outline: none;

    &-active {
      display: block;
      width: auto;
      height: auto;
      font-size: rem(1.5);
      border-radius: 0;
    }
  }
}
