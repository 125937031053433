.m-cardcontact {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  height: 100%;
  overflow: hidden;

  &_image {
    .a-image {
      position: relative;
      overflow: hidden;

      img {
        transition: transform 2.4s $animation;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background-color: $COLOR-UTILITY-BG-IMG-LEADCARDLINK;
        opacity: 0;
        transition: opacity 2.4s $animation;
      }
    }
  }

  &:hover &_image {
    .a-image {
      img {
        transform: scale(1.05);
      }

      &::after {
        opacity: 0.25;
      }
    }
  }

  &_body {
    padding: rem(1);
    font-size: rem(1);
    color: $COLOR-WARM-GREY-TWO;
    background-color: $COLOR-WHITE-FOUR;
  }

  &_title {
    @include text-overflow(1);

    padding-bottom: rem(0.5);
  }

  &_location {
    @include text-overflow(2);
  }

  &_phone {
    padding-top: rem(1.5);
  }

  &_button {
    display: flex;
    justify-content: center;
    padding-top: rem(1);

    .a-button {
      width: 100%;

      &:first-child {
        margin-right: rem(0.75);
      }

      &:last-child {
        margin-left: rem(0.75);
      }
    }
  }
}
