.m-cardcompare {
  $root: &;
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  height: 100%;
  background-color: $COLOR-WHITE;
  border: solid 1px $COLOR-WHITE-THREE;

  &-withhover:hover {
    .a-image {
      &:nth-of-type(1) {
        visibility: hidden;
        opacity: 0;
      }

      &:nth-of-type(2) {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &_title {
    display: block;

    .m-heading_body {
      margin-bottom: rem(0.75);
    }

    .m-heading_text {
      @include text-overflow;
    }

    #{$root}-horizontal & .m-heading_body {
      margin-top: 0;
    }

    &-SKU {
      .a-text {
        display: inline-block;
        margin-bottom: rem(5/14);
      }
    }
  }

  &_description {
    @include text-overflow;
    font-size: rem(1);
  }

  &_img {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;

    .a-image {
      background-color: $COLOR-WHITE;
      transition: all 0.5s;

      &:nth-of-type(2) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
      }

      img {
        transition: transform 2.4s $animation;
        object-fit: cover;
        width: 100%;
      }
    }

    #{$root}-horizontal & {
      @include pc {
        flex: 0 0 50%;
      }
    }
  }

  &_content {
    #{$root}-horizontal & {
      padding: rem(1);

      @include pc {
        box-sizing: border-box;
        flex: 0 0 50%;
        align-self: flex-start;
        padding: rem(2);
      }
    }

    #{$root}-vertical & {
      padding: rem(1);
    }

    #{$root}-withcompare#{$root}-vertical & {
      margin-bottom: rem(3);
    }
  }

  &_rate {
    display: flex;
    align-items: center;
    margin-top: rem(1.5);

    .a-rate {
      margin-right: rem(0.5);
    }
  }

  &_specifications {
    margin-top: rem(1.5);

    .m-textlist-subordered li {
      padding-left: calc(#{rem(1.25)} + 2px);

      &::before {
        border-width: 4px;
      }
    }
  }

  &_comparison {
    margin-top: rem(1.5);

    #{$root}-vertical#{$root}-withcompare & {
      position: absolute;
      bottom: rem(1);
      left: 50%;
      margin-top: 0;
      transform: translateX(-50%);
    }

    #{$root}-horizontal#{$root}-withcompare & {
      @include sp {
        display: flex;
        justify-content: center;
      }
    }
  }

  &-horizontal {
    @include pc {
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        position: absolute;
        left: 50%;
        width: 1px;
        height: 100%;
        content: '';
        background-color: $COLOR-WHITE-THREE;
      }
    }
  }

  &-vertical {
    border-radius: 5px;

    .a-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &-withcompare {
    &#{$root}-vertical {
      position: relative;
    }
  }

  &-isnew {
    .a-tag {
      position: absolute;
      top: rem(1);
      right: rem(1);
      justify-content: center;
      min-width: rem(3.375);
      min-height: rem(1.5);
      background-color: $COLOR-VIVID-RED;
      border-radius: 5px;
      box-shadow: 0 3px 6px $COLOR-UTILITY-BD-NEW;
    }
  }
}