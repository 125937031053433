.m-pichonkundetail {
  display: flex;
  flex-wrap: wrap;

  &_wrapimage {
    width: 100%;
    text-align: center;

    @include pcFixedContent {
      width: calc(514 / #{$WIDTH-CONTAINER-UNIT} * 100%);
      text-align: inherit;
    }
  }

  &_wrapinfo {
    width: 100%;
    margin-top: rem(1);
    color: $COLOR-WARM-GREY;

    @include pcFixedContent {
      width: calc(100% - (514 / #{$WIDTH-CONTAINER-UNIT} * 100%));
      padding-left: rem(6.25);
      margin-top: 0;
    }
  }

  &_info {
    display: flex;
    font-size: rem(1.5);

    @include sp {
      flex-wrap: wrap;
    }

    span {
      display: inline-block;
      width: calc(100% - (243 / #{$WIDTH-CONTAINER-UNIT} * 100%));

      @include sp {
        width: 100%;
        font-size: rem(1.25);
      }

      &:first-child {
        @include u-fw-b;
        width: 243px;
        padding-right: rem(1);

        @include sp {
          width: 100%;
          padding-right: 0;
        }
      }
    }

    & + & {
      margin-top: rem(1);
    }
  }
}
