.m-featurebox {
  height: 100%;
  padding: rem(1.3125);
  background-color: $COLOR-WHITE-THREE;

  @include spMedium {
    padding: 12px;
  }

  .a-image {
    margin-right: 12px;
    flex-shrink: 0;

    .a-image_img {
      width: 100%;
      height: 100%;
      max-height: 50px;
    }
  }

  .a-text {
    font-size: 20px;

    @include spMedium {
      font-size: 14px;
      margin-top: 12px;
    }
  }

  &_heading {
    display: flex;
    align-items: center;

    @include spMedium {
      flex-direction: column;
    }
  }

  &_description {
    margin-top: 16px;
    border-top: 1px solid white;
    padding-top: 12px;
    font-size: 14px;
    line-height: 24px;

    @include spMedium {
      margin-top: 12px;
      padding-top: 12px;
      font-size: 12px;
      line-height: 18px;
    }
  }
}