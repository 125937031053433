/* @media
--------------------------------------------------------- */
@mixin pc-large() {
  @media (min-width: $WIDTH-PC-LARGE) {
    @content;
  }
}

@mixin pc() {
  @media (min-width: $WIDTH-XS + 1px) {
    @content;
  }
}

@mixin tab() {
  @media (min-width: $WIDTH-XS + 1) and (max-width: $WIDTH-PC - 1px) {
    @content;
  }
}

@mixin sp() {
  @media (max-width: $WIDTH-XS) {
    @content;
  }
}

@mixin spMedium() {
  @media (max-width: $WIDTH-MD) {
    @content;
  }
}

// This use only for Menu component
@mixin spMenu() {
  @media (max-width: $WIDTH-TAB - 1) {
    @content;
  }
}

@mixin pcFixedContent() {
  @media (min-width: $WIDTH-PC) {
    @content;
  }
}

@mixin tabFixedContent() {
  @media (min-width: $WIDTH-TAB) {
    @content;
  }
}

@mixin pcFixedContentCarousel() {
  @media (min-width: $WIDTH-PC-CONTENTS-CAROUSEL) {
    @content;
  }
}

/* There are three mixins apply for font-weight
--------------------------------------------------------- */
@mixin u-fw-r {
  // only for font-family: ff-reguler;
  font-weight: 400;
}

@mixin u-fw-m {
  // only for font-family: ff-medium;
  font-weight: 500;
}

@mixin u-fw-smb {
  // only for font-family: ff-semibold;
  font-weight: 600;
}

@mixin u-fw-b {
  // only for font-family: ff-bold;
  font-weight: 700;
}

/* make area that keep the aspect-ratio of area. This should be used with background-image
--------------------------------------------------------- */
@mixin aspectRatio($width: 1, $height: 1) {
  position: relative;

  &:before {
    display: block;
    padding-bottom: ($height / $width) * 100%;
    content: '';
  }
}

@mixin text-overflow($number: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ($number);
}

@mixin break-max($pixel) {
  @media (max-width: $pixel) {
    @content;
  }
}

@mixin break-min($pixel) {
  @media (min-width: $pixel) {
    @content;
  }
}

@mixin break($pixel1, $pixel2) {
  @media (min-width: $pixel1) and (max-width: $pixel2) {
    @content;
  }
}
