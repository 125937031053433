.m-menu {
  $root: &;
  $rootMenuItem: #{$root}_menuitem;
  $animation: cubic-bezier(0.4, 0, 0.2, 1);

  @include tabFixedContent {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &_arrowbadge {
    position: absolute;
    left: 0;
    content: '';
    border: rem(0.5) solid $COLOR-UTILITY-TRANSPARENT;
    border-top-color: $COLOR-CERULEAN;
    border-left-color: $COLOR-CERULEAN;
  }

  &_wrapsubmenu {
    #{$rootMenuItem}-hasmenucard & {
      @include tabFixedContent {
        flex: 1 1 auto;
      }
    }
  }

  &_wrapcardmenu {
    position: relative;
    display: none;
    padding-left: rem(3);
    list-style-type: none;

    &::after {
      @include tabFixedContent {
        position: absolute;
        top: -#{rem(1.5)};
        left: rem(1.5);
        width: 1px;
        height: calc(100% + #{rem(3)});
        content: '';
        background-color: $COLOR-UTILITY-BD-SUBMENU;
      }
    }

    @include tabFixedContent {
      display: block;
    }
  }

  &_menuitem {
    @include tabFixedContent {
      height: 100%;
    }

    &-bold {

      &,
      &>span {
        @include u-fw-b;
      }
    }

    &-uppercase>span {
      text-transform: uppercase;
    }

    &-button {
      @include tabFixedContent {
        margin: 0 16px;
      }
    }

    img {
      min-width: rem2(288);
      max-width: rem2(288);
    }

    &-button-contactus {
      display: none;

      @include spMenu {
        display: block;
      }
    }
  }

  &_title {
    @include tabFixedContent {
      height: 100%;
    }

    &-disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    display: flex;
    align-items: center;
    position: relative;
    padding: 30px 0 0 0;
    font-size: rem(1);
    color: $COLOR-BLACK;
    cursor: pointer;

    .a-image {
      margin-right: 8px;

      img {
        min-width: auto;
        max-width: rem(1.5);
        display: flex;
      }
    }

    @include tabFixedContent {
      padding: 10px 16px;
      text-align: center;
    }

    @include pcFixedContent {
      padding: 20px 16px;
    }

    @include spMenu {
      width: 100%;
      padding-right: 20px;
    }

    #{$root}_menuitem:hover & {
      color: $COLOR-CERULEAN;
    }

    #{$rootMenuItem}-haschildren:not(#{$rootMenuItem}-nonarrow) & {
      @include tabFixedContent {
        padding-right: 36px;
      }

      &::after {
        position: absolute;
        top: 50%;
        content: '';
        border: 5px solid $COLOR-UTILITY-TRANSPARENT;
        border-top-color: $COLOR-BLACK;
        border-radius: 3px;
        transform: translate(10px, -3px);
        right: 26px;

        @include spMenu {
          top: calc(50% + 15px);
          right: 10px;
        }
      }
    }

    #{$rootMenuItem}-haschildren:not(#{$rootMenuItem}-nonarrow):hover &::after,
    #{$rootMenuItem}-haschildren:not(#{$rootMenuItem}-nonarrow)#{$rootMenuItem}-active &::after {
      border-top-color: $COLOR-CERULEAN;
    }

    #{$rootMenuItem}-haschildren#{$rootMenuItem}-nonarrow & {

      &:before,
      &:after {
        @include spMenu {
          position: absolute;
          content: '';
          background-color: $COLOR-BLACK;
          transition: transform 0.5s ease-out;
        }
      }

      &:before {
        @include spMenu {
          top: calc(50% + 6px);
          right: 6px;
          width: 2px;
          height: 14px;
        }
      }

      &:after {
        @include spMenu {
          top: calc(50% + 12px);
          right: 0;
          width: 14px;
          height: 2px;
        }
      }
    }

    #{$rootMenuItem}-haschildren#{$rootMenuItem}-nonarrow#{$rootMenuItem}-collapsed & {
      &:before {
        @include spMenu {
          transform: rotate(90deg);
        }
      }

      &:after {
        @include spMenu {
          transform: rotate(180deg);
        }
      }
    }

    #{$rootMenuItem}-haschildren#{$rootMenuItem}-nonarrow:hover &,
    #{$rootMenuItem}-haschildren#{$rootMenuItem}-nonarrow#{$rootMenuItem}-active & {

      &:before,
      &:after {
        @include spMenu {
          background-color: $COLOR-CERULEAN;
        }
      }
    }

    #{$rootMenuItem}-active:not(#{$rootMenuItem}-button) & {
      color: $COLOR-CERULEAN;

      &::before {
        @include tabFixedContent {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          content: '';
          background-color: $COLOR-CERULEAN;
        }
      }
    }

    #{$rootMenuItem}-button & {
      @include tabFixedContent {
        padding: 8px 24px;
        color: $COLOR-WHITE;
        background-image: linear-gradient(to right, #00A1E4, #4bc3f6, #26b6f3, #4bc3f6);
        background-size: 300% 100%;
        transition: all .4s ease-in-out;
        border-radius: 6px;

        &:hover {
          background-position: 90% 0;
          transition: all .4s ease-in-out;
        }
      }
    }
  }

  &_content {
    font-size: rem(1.25);
    @include u-fw-m;

    @include tabFixedContent {
      position: absolute;
      top: 62px;
      left: 0;
      z-index: z('header', 'menubar');
      display: none;
      width: 100%;
      max-width: $WIDTH-CONTAINER-PX;
      padding: rem(1.5);
      color: $COLOR-CERULEAN;
      background-color: $COLOR-WHITE;
      border: 1px solid $COLOR-UTILITY-BD-SUBMENU;
      border-radius: 2px;
      // TODO: replace with color variable
      box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
      opacity: 0;
      animation: animationMenu 0.5s ease-in-out both;

      #{$rootMenuItem}-bottommenu & {
        animation-name: animationBottomMenu;
      }
    }

    @include spMenu {
      max-height: 0;
      padding: 0 15px;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
    }

    // NOTE: This is only used for hover behavior
    &::before {
      @include tabFixedContent {
        position: absolute;
        top: -3px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: $WIDTH-CONTAINER-PX;
        content: '';
      }
    }

    #{$rootMenuItem}-haschildren:not(#{$rootMenuItem}-hasmenucard):hover & {
      display: block;
    }

    #{$rootMenuItem}-haschildren#{$rootMenuItem}-hasmenucard:hover & {
      @include tabFixedContent {
        display: flex;
      }
    }

    #{$rootMenuItem}-collapsed & {
      @include spMenu {
        height: auto;
        // NOTE: Set proper max-height
        max-height: 1000px;
        transition: max-height 0.5s ease-out;
      }
    }
  }

  &_categorysubmenu {
    position: relative;
    display: block;
    padding: 0 0 rem(1.5) rem(1.5);
    border-bottom: 1px solid $COLOR-UTILITY-BD-SUBMENU;

    &::before {
      @extend .m-menu_arrowbadge;
      top: 5px;
    }

    @include spMenu {
      display: none;
    }
  }

  &_submenu {
    list-style-type: none;

    #{$root}_wrapsubmenu>& {
      @include tabFixedContent {
        display: flex;
        flex-wrap: wrap;
      }
    }

    #{$root}_submenuitem & {
      padding-top: rem(1);
    }
  }

  &_submenuitem {
    position: relative;
    font-size: rem(1);

    &_wrapper {
      position: relative;
      display: inline-block;
      padding: 10px 15px 0 0;
    }

    &:not(&-haschildren)>a:not(#{$root}_linkimage) {
      position: relative;
      display: block;
      padding-left: rem(2.75);
      color: $COLOR-BLACK;
      @include u-fw-r;

      &::before {
        position: absolute;
        top: 50%;
        left: rem(1);
        width: rem(1.25);
        height: rem(1.25);
        content: '';
        background: url('~assets/images/icons/chevron-right-blue.svg') center right/cover no-repeat;
        transform: translateY(-50%);
        margin-top: 4px;
      }

      &:hover {
        color: $COLOR-CERULEAN;
      }

      @include spMenu {
        color: $COLOR-WHITE;
      }
    }

    &-haschildren {
      &>a:not(#{$root}_linkimage)>span {
        position: relative;
        padding-left: rem(1.5);
        color: $COLOR-CERULEAN;
        @include u-fw-b;

        &::before {
          @extend .m-menu_arrowbadge;
          top: 2px;
        }
      }

      #{$root}_submenu & #{$root}_submenu & {
        padding-left: rem(1.5);
      }
    }

    &-haschildren &:not(&-haschildren):not(:first-child) {
      margin-top: rem(0.625);
    }

    &-active>a:not(#{$root}_linkimage)>span {
      color: $COLOR-CERULEAN;
    }

    #{$root}_wrapsubmenu>#{$root}_submenu>& {
      width: 100%;
      padding-top: rem(1.5);

      @include tabFixedContent {
        width: calc(100% / 3);
      }
    }

    #{$root}_wrapsubmenu>#{$root}_submenu>&:not(&-haschildren)>a:not(#{$root}_linkimage) {
      padding-left: rem(1.5);

      &::before {
        left: rem(-0.25);
      }
    }

    #{$root}_wrapcardmenu & {
      &:not(:first-child) {
        margin-top: rem(2.5);
      }

      >a:not(#{$root}_linkimage) {
        padding-left: rem(1.5);
        margin-top: rem(1);

        &::before {
          left: 0;
        }
      }
    }
  }

  &_dot {
    position: absolute;
    top: 16px;
    right: 5px;
    z-index: z('header', 'menubar');
    width: 8px;
    height: 8px;
    background-color: $COLOR-VIVID-RED;
    border-radius: 50%;
  }
}

@keyframes animationMenu {
  from {
    top: 50px;
    opacity: 0;
  }

  to {
    top: 62px;
    opacity: 1;
  }
}

@keyframes animationBottomMenu {
  from {
    top: rem(17/7);
    opacity: 0;
  }

  to {
    top: rem(23/6.2);
    opacity: 1;
  }
}