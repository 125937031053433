::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(241, 241, 241);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(247, 247, 247);
}

.kanki .lead p:last-child span.u-fw-semibold {
  font-weight: 500;
}

.u-fw-semibold {
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

h3 a {
  font-weight: bold;
}

.kanki {
  font-family: 'Raleway', sans-serif !important;
}

.kanki .mainvisual,
.kanki .tabs,
.kanki .pure__products,
.kanki .pure__title,
.kanki .fresh__subtitle,
.kanki .pure__subtitle,
.kanki .fresh__comfort>div,
.kanki .fresh__products,
.kanki .fresh__logo,
.kanki .fresh__title,
.kanki .pure__logo,
.footer small {
  font-family: 'Raleway', sans-serif !important;
}

.kanki .pure__products__01 img,
.kanki .pure__products__02 img,
.kanki .pure__products__03 img {
  width: auto;
  max-width: 100%;
}

.shops {
  padding: 100px 20px;
}

.shops-wrap {
  max-width: 960px;
  margin: 0 auto 60px;
}

.shops-content {
  font-size: 12px;
  color: rgb(102, 102, 102);
}

.shops .pure__logo,
.shops .pure__title {
  text-align: center;
}

.form-control {
  display: block;
  width: 100%;
  height: 44px;
  padding: 8.5px 42px 8.5px 24px;
  font-family: 'Raleway', sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.42857143;
  color: rgb(102, 102, 102);
  text-overflow: ellipsis;
  background: rgb(255, 255, 255) url('~assets/images/airPurifier/dropdown_arw.svg') no-repeat right center;
  background-position-x: calc(100% - 12px);
  border: 1px solid rgb(231, 231, 231);
  border-radius: 4px;
  appearance: none;
}

.form-control:focus {
  outline: none;
}

.shops-content_items .shops-content_title {
  font-weight: bold;
  color: rgb(0, 151, 224);
}

.shops-content_items .shops-content_description {
  padding: 6px 0;
}

.shops-content_items .shops-content_website {
  padding-top: 6px;
  text-decoration: underline;
}

.shops-content_items .shops-content_phone a,
.shops-content_items .shops-content_website a {
  font-weight: bold;
  color: rgb(0, 151, 224);
  text-decoration: none;
}

.shops-content_left {
  position: relative;
  max-width: 308px;
  margin-right: auto;
  margin-bottom: 24px;
  margin-left: auto;
}

.shops-content_left::before {
  display: block;
  padding-bottom: 150.3%;
  content: '';
}

.shops-content_background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  object-fit: cover;
  object-position: top;
}

.shops-content_right {
  padding: 12px 24px 39px 24px;
  background: rgb(247, 247, 247);
}

.shops-content_wrap {
  max-height: 393px;
  padding-right: 24px;
  overflow: hidden auto;
}

.shops-content_wraptop {
  padding-bottom: 12px;
  text-align: center;
  border-bottom: solid 1px rgb(231, 231, 231);
}

.shops-content_heading {
  margin-bottom: 12px;
}

.shops-content_heading h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  color: rgb(0, 151, 224);
}

.shops-content_dropdown {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.shops-content_location .shops-content_items {
  position: relative;
  height: 100%;
  margin-top: 24px;
}

.button-buy {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-blue {
  padding: 8px 24px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
  background-color: rgb(0, 161, 228);
  border-radius: 20px;
}

@media (min-width: 540px) {
  .shops-content_wraptop {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
  }

  .shops-content_heading {
    width: calc(250 / 608 * 100%);
    margin-bottom: 0;
  }

  .shops-content_dropdown {
    display: flex;
    justify-content: flex-end;
    width: calc((100%) - (250 / 608 * 100%));
    min-width: 175px;
    padding-left: 25px;
    margin-right: 0;
    margin-left: 0;
  }

  .form-control {
    width: auto;
    min-width: 175px;
    font-size: 18px;
  }
}

@media (min-width: 640px) {
  .shops-content_wrap {
    padding-right: 72px;
  }

  .shops-content_location {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .shops-content_location .shops-content_items {
    width: calc(50% - 30px);
    max-width: 268px;
    margin-top: 28px;
  }
}

@media (min-width: 768px) {
  .shops-content_box {
    display: flex;
    height: 100%;
    min-height: 500px;
  }

  .shops-content_left {
    width: calc(308 / 960 * 100%);
    margin-bottom: 0;
  }

  .shops-content_background {
    background-position: center center;
  }

  .shops-content_right {
    width: calc((100%) - (308 / 960 * 100%));
    margin-left: 16px;
  }
}

@media (min-width: 1200px) {
  .shops-content_heading h2 {
    font-size: 30px;
    line-height: 24px;
  }
}

@media (max-width: 1023px) and (min-width: 767px) {
  .shops-content_heading h2 {
    font-size: 22px;
    line-height: 24px;
  }
}

@media (max-width: 767px) {
  .shops {
    padding: 60px 15px;
  }

  .kanki .shops .fresh__subtitle {
    text-align: center;
  }

  .shops-content_location .shops-content_items:last-child {
    margin-bottom: 0;
  }
}