.m-carddownload {
  $root: &;
  height: 100%;
  padding: 15px;
  background-color: $COLOR-WHITE;
  border: solid 1px $COLOR-WHITE-THREE;
  border-radius: 3px;

  @include tabFixedContent {
    padding: rem(1.25) rem(1.71875) rem(0.75);
  }

  @include pcFixedContent {
    padding: rem(2.5) rem(3.4375) rem(1.5);
  }

  &_wrapper {
    cursor: pointer;
  }

  &_zoom {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: z('carddownload', 'zoom');
    display: flex;
    overflow: hidden;
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  &_body {
    position: relative;
    cursor: pointer;

    &:before {
      position: absolute;
      z-index: z('carddownload', 'bodybefore');
      width: 100%;
      height: 100%;
      overflow: hidden;
      content: '';
      background: $COLOR-UTILITY-BG-CATALOGUE-BLACK;
      opacity: 0;
      transition-duration: 0.3s;
    }

    #{$root}_wrapper:hover & {
      #{$root}_zoom,
      &:before {
        overflow: visible;
        opacity: 1;
      }
    }
  }

  &_iconround {
    position: relative;
    display: inline-block;
    width: rem(3.25);
    height: rem(3.25);
    cursor: pointer;
    border: solid 3px $COLOR-WHITE;
    border-radius: 50%;

    @include sp {
      width: 32px;
      height: 32px;
    }

    .a-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include sp {
        width: 16px;
        height: 16px;
      }
    }
  }

  &_footer {
    padding-top: rem(2);
    text-align: center;
  }

  &_tag .a-tag {
    border-radius: 3px;
  }

  &_title {
    padding-top: rem(0.75);
    transition: 0.3s ease-in-out;

    #{$root}_wrapper:hover & {
      color: $COLOR-CERULEAN;
    }
  }
}
