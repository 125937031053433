.m-supportlink {
  $animation: cubic-bezier(0.075, 0.82, 0.165, 1);

  display: block;
  text-align: center;

  .a-image {
    margin-bottom: rem(1.625);

    @include sp {
      max-width: 55px;
      margin-right: auto;
      margin-left: auto;
    }

    img {
      transition: transform 2.4s $animation;
    }
  }

  &:hover {
    .a-image img {
      transform: scale(1.1);
    }
  }
}
