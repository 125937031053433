.o-section {
  $root: &;

  &~& {
    margin-top: 20px;

    @include pc {
      margin-top: rem(3.75);
    }
  }

  &:not(&-withbgimage) &~& {
    margin-top: 24px;

    @include pc {
      margin-top: rem(2);
    }
  }

  @mixin withBackground {
    padding: 32px 0;

    @include pc {
      padding: rem(4) 0;
    }
  }

  &_container {
    #{$root}-withbgimage>& {
      position: relative;
    }

    & & {
      //NOTE: Disable Container's default gutter (Nested Parts only)
      padding-right: 0;
      padding-left: 0;
    }
  }

  &-bgpalegrey {
    background-color: $COLOR-PALE-GREY;
    @include withBackground;

    &#{$root}-bghalf::before {
      @include pcFixedContent {
        background-color: $COLOR-PALE-GREY;
      }
    }

    &#{$root}-withbgimage::before {
      background-image: $COLOR-UTILITY-GRD-PALE-GREY-SECTION;
    }
  }

  &-bgcerulean {
    background-color: $COLOR-CERULEAN;
    @include withBackground;

    &#{$root}-bghalf::before {
      @include pcFixedContent {
        background-color: $COLOR-CERULEAN;
      }
    }

    &#{$root}-withbgimage::before {
      background-image: $COLOR-UTILITY-GRD-CERULEAN-SECTION;
    }
  }

  &-bgwhitefour {
    background-color: $COLOR-WHITE-FOUR;
    @include withBackground;

    &#{$root}-bghalf::before {
      @include pcFixedContent {
        background-color: $COLOR-WHITE-FOUR;
      }
    }

    &#{$root}-withbgimage::before {
      background-image: $COLOR-UTILITY-GRD-WHITE-FOUR-SECTION;
    }
  }

  &-bgwhitefive {
    background-color: $COLOR-WHITE-FIVE;
    @include withBackground;

    &#{$root}-bghalf::before {
      @include pcFixedContent {
        background-color: $COLOR-WHITE-FIVE;
      }
    }

    &#{$root}-withbgimage::before {
      background-image: $COLOR-UTILITY-GRD-WHITE-FIVE-SECTION;
    }
  }

  &-bgwhitesix {
    background-color: $COLOR-WHITE-SIX;
    @include withBackground;

    &#{$root}-bghalf::before {
      @include pcFixedContent {
        background-color: $COLOR-WHITE-SIX;
      }
    }

    &#{$root}-withbgimage::before {
      background-image: $COLOR-UTILITY-GRD-WHITE-SIX-SECTION;
    }
  }

  &-bgwhite {
    background-color: $COLOR-WHITE;
    @include withBackground;
  }

  &-bghalf {
    @include withBackground;

    @include pcFixedContent {
      position: relative;
      background-color: $COLOR-UTILITY-TRANSPARENT;

      &::before {
        position: absolute;
        top: 0;
        z-index: z('section', 'bghalf');
        width: 60%;
        height: 100%;
        content: '';
      }
    }

    &#{$root}-bghalfrtl {
      @include pcFixedContent {
        &::before {
          right: 0;
        }
      }
    }
  }

  &-withbgimage {
    background-color: $COLOR-UTILITY-TRANSPARENT;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include withBackground;

    &#{$root}-bgpalegrey,
    &#{$root}-bgcerulean,
    &#{$root}-bgwhitefour,
    &#{$root}-bgwhitefive,
    &#{$root}-bgwhitesix {
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
      }
    }
  }

  &-cardlinkoverlap {
    @include pcFixedContent {
      margin-bottom: -50px;
      background-color: $COLOR-WHITE-FIVE;
    }

    .m-leadcardlink {
      @include pcFixedContent {
        position: relative;
        top: -50px;
        z-index: z('section', 'leadcardlink');
      }
    }

    &+#{$root} {
      margin-top: 0;
    }
  }

  &-nomargin {
    margin: 0;

    #{$root}~& {
      margin-top: 0;
    }
  }

  &-nopadding {
    padding: 0;
  }

  &-nopaddingtop {
    padding-top: 0;
  }

  &-nopaddingbottomsp {
    @include sp {
      padding-bottom: 0;
    }
  }

  &-withparallaxbg {
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-size: cover;

    &:before {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      padding-bottom: (855 / 1920) * 100%;
      content: '';
      background-image: url('~assets/images/common/parallax-bg.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &-containgallery {
    padding: rem(1) 0;
  }

  &-hiddenonsp {
    @include sp {
      display: none;
    }
  }

  &-onaboutdaikin {
    @include spMenu {
      margin-top: 50px;
    }
  }
}