.m-formfield {
  $root: &;

  &_label {
    font-size: rem(1);
    color: $COLOR-GREYISH;

    #{$root}-error & {
      color: $COLOR-CHERRY;
    }
  }

  &_content {
    margin-top: rem(0.5);
  }
}
