.m-cardlocation {
  position: relative;

  &_wrapinfo {
    position: absolute;
    width: 100%;
    padding: 0 15px;
    color: $COLOR-WHITE;
    text-align: center;

    @include pc {
      top: rem(1.5);
      padding: 0 rem(5.3);
    }

    @include tab {
      padding: 0 10px;
    }

    @include spMenu {
      top: 10px;
    }

    @include sp {
      top: rem(0.875);
    }
  }

  &_title {
    margin-bottom: rem(0.5);

    .a-text {
      font-size: 16px;
      line-height: 19px;

      @include pc {
        font-size: rem(1.9375);
        line-height: rem(2.3125);
      }

      @include tab {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &_button {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    @include pc {
      margin-top: rem(1.5);
    }
  }
}
