.m-leadbanner {
  $root: &;
  display: flex;
  align-items: center;

  @include sp {
    flex-wrap: wrap;
    justify-content: center;
  }

  &_wrapcontent {
    position: relative;
    width: 100%;

    @include pc {
      width: 60%;
      padding-right: 60px;
    }

    @include sp {
      order: 2;
      text-align: center;
    }

    .a-button {
      display: inline-flex;

      @include sp {
        margin: 0 auto;
      }
    }
  }

  &_description {
    margin-bottom: rem(1.5);

    .a-text {
      font-size: rem(1.3125);
    }
  }

  &_wrapimage {
    @include pc {
      width: 40%;
    }

    @include sp {
      order: 1;
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &_image {
    position: relative;

    @include sp {
      width: 100%;
      max-width: 200px;
      height: 100%;
      max-height: 200px;
      margin: auto;
    }

    .a-image {
      z-index: z('leadbanner', 'image');
    }
  }
}
